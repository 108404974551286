/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const FirstRoomBadge = () => (
    <svg id="Badge-Room" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <path
            id="Bg"
            d="M54,64H10A10,10,0,0,1,0,54V10A10,10,0,0,1,10,0H54A10,10,0,0,1,64,10V54A10,10,0,0,1,54,64"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="Star"
            d="M32.746,7.241l2.026,2.738a.945.945,0,0,0,1.171.288L39.01,8.785a.945.945,0,0,1,1.345.706l.522,3.365a.944.944,0,0,0,.9.8l3.4.113a.944.944,0,0,1,.863,1.25l-1.1,3.222a.946.946,0,0,0,.428,1.128l2.962,1.681a.945.945,0,0,1,.183,1.508L46.043,24.9a.946.946,0,0,0-.145,1.2l1.841,2.865a.946.946,0,0,1-.538,1.421l-3.279.924a.945.945,0,0,0-.685.992l.3,3.393A.945.945,0,0,1,42.4,36.7l-3.332-.705a.945.945,0,0,0-1.068.56L36.687,39.7a.945.945,0,0,1-1.475.364l-2.623-2.174a.946.946,0,0,0-1.2,0l-2.623,2.174a.945.945,0,0,1-1.475-.364l-1.312-3.143a.945.945,0,0,0-1.068-.56l-3.332.705a.945.945,0,0,1-1.137-1.007l.3-3.393a.946.946,0,0,0-.685-.992l-3.279-.924a.946.946,0,0,1-.538-1.421L18.075,26.1a.946.946,0,0,0-.145-1.2l-2.474-2.342a.945.945,0,0,1,.183-1.508L18.6,19.369a.945.945,0,0,0,.428-1.128l-1.1-3.222a.944.944,0,0,1,.863-1.25l3.4-.113a.944.944,0,0,0,.9-.8l.522-3.365a.945.945,0,0,1,1.345-.706l3.067,1.482A.945.945,0,0,0,29.2,9.979l2.026-2.738a.945.945,0,0,1,1.519,0"
            fill="#fff"
        />
        <path
            id="R"
            d="M15.167,51.3,14.4,54.939H11.722l2.112-9.994H16.48a4.364,4.364,0,0,1,2.639.683,2.407,2.407,0,0,1,.916,2.059,3.489,3.489,0,0,1-.486,1.858,3.062,3.062,0,0,1-1.442,1.21l1.531,3.529.273.655H17L15.666,51.3Zm.431-2.029h.349a1.419,1.419,0,0,0,1.032-.372,1.393,1.393,0,0,0,.383-1.043.844.844,0,0,0-.237-.647.961.961,0,0,0-.686-.221h-.362Z"
            fill="#fff"
        />
        <path
            id="O"
            d="M26.727,44.788A3.682,3.682,0,0,1,29.492,45.8a3.892,3.892,0,0,1,.988,2.834,8.005,8.005,0,0,1-.679,3.326,5.366,5.366,0,0,1-1.877,2.31,4.859,4.859,0,0,1-2.776.8,3.807,3.807,0,0,1-2.84-1.018,3.864,3.864,0,0,1-1-2.831,7.818,7.818,0,0,1,.7-3.329,5.412,5.412,0,0,1,1.917-2.314,5,5,0,0,1,2.8-.8m1.053,3.876a2.008,2.008,0,0,0-.328-1.221A1.113,1.113,0,0,0,26.5,47a1.719,1.719,0,0,0-1.244.574,4.067,4.067,0,0,0-.907,1.579,6.451,6.451,0,0,0-.338,2.058q0,1.653,1.368,1.654a1.676,1.676,0,0,0,1.207-.567,3.9,3.9,0,0,0,.875-1.532,6.709,6.709,0,0,0,.317-2.1"
            fill="#fff"
        />
        <path
            id="O02"
            d="M37.042,44.788A3.682,3.682,0,0,1,39.807,45.8a3.892,3.892,0,0,1,.988,2.834,8.005,8.005,0,0,1-.679,3.326,5.366,5.366,0,0,1-1.877,2.31,4.859,4.859,0,0,1-2.776.8,3.807,3.807,0,0,1-2.84-1.018,3.864,3.864,0,0,1-1-2.831,7.818,7.818,0,0,1,.7-3.329,5.412,5.412,0,0,1,1.917-2.314,5,5,0,0,1,2.8-.8M38.1,48.664a2.008,2.008,0,0,0-.328-1.221,1.113,1.113,0,0,0-.95-.44,1.719,1.719,0,0,0-1.244.574,4.067,4.067,0,0,0-.907,1.579,6.451,6.451,0,0,0-.338,2.058q0,1.653,1.368,1.654A1.676,1.676,0,0,0,36.9,52.3a3.9,3.9,0,0,0,.875-1.532,6.709,6.709,0,0,0,.317-2.1"
            fill="#fff"
        />
        <path
            id="M"
            d="M45.246,54.939V47.768h-.055q-.458,2.85-.588,3.5l-.772,3.671H41.452l2.112-9.994H47.1v7.1h.055l3.268-7.1h3.595l-2.139,9.994h-2.4l.8-3.753q.342-1.557.738-2.979l.1-.439H51.07l-3.295,7.171Z"
            fill="#fff"
        />
        <path id="Dust" d="M15.147,9.375a1,1,0,1,1-1-1,1,1,0,0,1,1,1" fill="#fff" />
        <path id="Dust_02" data-name="Dust 02" d="M49,36.6a1,1,0,1,1-1-1,1,1,0,0,1,1,1" fill="#fff" />
        <path
            id="One"
            d="M15321,17405.906a1,1,0,1,1,0-2h2.04v-10.068l-1.5.969a1,1,0,0,1-1.083-1.682l3.036-1.965a1.009,1.009,0,0,1,1.022-.035.993.993,0,0,1,.52.879v11.9h2.153a1,1,0,0,1,0,2Z"
            transform="translate(-15292.043 -17375.438)"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="Glimmer"
            d="M50.247,14.215a11.162,11.162,0,0,0-3.061-3.679,11.149,11.149,0,0,0,3.061-3.678,11.149,11.149,0,0,0,3.061,3.678,11.162,11.162,0,0,0-3.061,3.679"
            fill="#fff"
            opacity="0.3"
        />
        <path
            id="Glimmer-2"
            data-name="Glimmer"
            d="M14.055,39.479a8.742,8.742,0,0,0-2.4-2.88,8.732,8.732,0,0,0,2.4-2.88,8.732,8.732,0,0,0,2.4,2.88,8.742,8.742,0,0,0-2.4,2.88"
            fill="#fff"
            opacity="0.5"
        />
        <rect id="Bound" width="64" height="64" fill="none" />
    </svg>
);
