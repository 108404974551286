/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { QuestionFeedBack } from '../../Question/Feedback/QuestionFeedBack';
import { TeacherMessage } from '../../../components/Room/Teacher/Message/Message';
import { questionDifficulty, questionTypes } from '../../../constants';
import { FillInTheBlanks, MultipleChoice, TrueAndFalse } from '../../Question/QuestionTypes';
import Draggable from '../../Question/QuestionTypes/Draggable';
import { questionService } from '../../../services/question';
import { common } from '../../../helpers';
import { Button } from '../../../components/Form/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { attemptService } from '../../../services/attempt';
import Caret from '../../../components/Icons/Caret';
import Fade from 'react-reveal/Fade';
import Content from '../../Question/LearningContent/Content';
import { Loader } from '../../../components/Loader';
import { startLoading, stopLoading } from '../../../actions';

const QuestionPreview = () => {
    const [feedback, setFeedback] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [learningSections, setLearningSections] = useState({});
    const [learningSection, setLearningSection] = useState({});
    const [answer, setAnswer] = useState(null);
    const [totalTimeSpentInSeconds, setTotalTimeSpentInSeconds] = useState(0);
    const [hardLearningCount, setHardLearningCount] = useState(0);
    const [attempt, setAttempt] = useState({});
    const [learningContentOpen, setLearningContentOpen] = useState(false);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [isAnswered, setIsAnswered] = useState(false);
    const [breadcrumbPath, setBreadcrumbPath] = useState('');

    const { levelId, questionId, questionType, roomId } = useSelector((state) => state.preview.metadata);
    const timer = useSelector((state) => state.timer);
    const dispatch = useDispatch();
    const { t } = useTranslation();

  useEffect(() => {
    dispatch(startLoading());
    attemptService.getAttempt(levelId, 1)
      .then((attempt) => {
        setAttempt(attempt[0]);
        setBreadcrumbPath(`${attempt[0].roomAndLevelInfo.roomTitle} / ${t(attempt[0].roomAndLevelInfo.levelPrefix)}`)

            questionService.getQuestions(levelId).then((levelQuestions) => {
                dispatch(stopLoading());
                const selectedQuestion = levelQuestions[questionType.toLowerCase()].find(
                    (question) => question.questionNo === Number(questionId),
                );
                if (selectedQuestion !== undefined) {
                    setCurrentQuestion(selectedQuestion);
                    setLearningSections(levelQuestions.learningSections);
                    setHardLearningCount(levelQuestions[questionDifficulty.HARD].length);
                    setTotalQuestions(
                        levelQuestions[questionDifficulty.SOFT].length + levelQuestions[questionDifficulty.HARD].length,
                    );
                } else {
                    alert('Question not found. Something went wrong!');
                }
            });
        });
    }, []);

    const handleFeedback = () => {
        alert('Preview Mode !');
    };

    const handleAnswer = (event, selectedAnswer = null) => {
        if (currentQuestion.questionTypeId === questionTypes.TRUE_FALSE) {
            setAnswer({ [selectedAnswer.answerId]: selectedAnswer.answer.toLowerCase() });
        } else if (currentQuestion.questionTypeId === questionTypes.MULTIPLE_CHOICES) {
            if (answer && Object.keys(answer).indexOf(String(selectedAnswer.answerId)) > -1) {
                const ansKey = Object.keys(answer).find((key) => key === String(selectedAnswer.answerId));
                const newAnswer = { ...answer };
                delete newAnswer[ansKey];
                setAnswer(newAnswer);
            } else {
                setAnswer({ ...answer, [selectedAnswer.answerId]: selectedAnswer.answer });
            }
        } else if (currentQuestion.questionTypeId === questionTypes.FILL_IN_THE_BLANKS) {
            const userAnswer = event.target.value;
            setAnswer({ ...answer, [selectedAnswer]: userAnswer });
        } else if (currentQuestion.questionTypeId === questionTypes.SEQUENTIAL) {
            setAnswer(selectedAnswer);
        }
    };

    const postAnswer = async () => {
        if (!isAnswered) {
            await questionService.postAnswer(levelId, currentQuestion.questionId, answerPayload()).then((feedBack) => {
                if (feedBack.isFastestAnswer) {
                    setFeedback(feedBack);
                    setCurrentQuestion();
                } else if (
                    feedBack.hideFeedbackScreen ||
                    currentQuestion.difficulty.toLowerCase() === questionDifficulty.HARD
                ) {
                    alert('Feedback screen is hidden');
                } else {
                    setFeedback(feedBack);
                    setCurrentQuestion(null);
                }
                setIsAnswered(true);
            });
        } else {
            handleFeedback();
        }
    };

    const answerPayload = () => {
        const userAnswerIds = [];
        const userAnswerValues = [];
        const timeSpentForAnswerInSeconds = Math.round(Date.now() / 1000);
        const totalTime = totalTimeSpentInSeconds + timeSpentForAnswerInSeconds;

        setTotalTimeSpentInSeconds(totalTime);

        if (typeof answer !== 'undefined' && answer) {
            Object.keys(answer).forEach((key) => {
                if (key !== undefined && answer[key] !== undefined) {
                    userAnswerIds.push(key);
                    userAnswerValues.push(answer[key]);
                }
            });
        }

        const answerIds =
            currentQuestion.questionTypeId === questionTypes.FILL_IN_THE_BLANKS
                ? JSON.stringify(userAnswerValues)
                : JSON.stringify(userAnswerIds);

        const isQuestionsOver = true;
        const { attemptId } = attempt.game;

        return {
            questionTypeId: `${currentQuestion.questionTypeId}`,
            difficulty: questionType.toLowerCase(),
            attemptId,
            userAnswerIds: answerIds,
            userAnswerValues: JSON.stringify(userAnswerValues),
            isQuizOver: isQuestionsOver,
            isTimeOut: timer.timePassed,
            totalEffectiveTimeSpend: totalTime,
            questionEffectiveTimeSpend: timeSpentForAnswerInSeconds,
            totalHardQuestionCount: hardLearningCount,
            totalQuestionCount: totalQuestions,
            roomId,
        };
    };

    const showLearningContent = () => {
        learningSections.map((section) => {
            if (section.id === currentQuestion.sectionId) {
                setLearningSection(section);
            }
        });

        setLearningContentOpen(true);
    };

    const dismissLearningContent = () => {
        setLearningContentOpen(false);
    };

    if (questionType.toLowerCase() === questionDifficulty.SOFT && learningContentOpen) {
        return (
            <Content
                content={learningSection}
                dismissHandler={dismissLearningContent}
                roomAndLevelInfo={attempt.roomAndLevelInfo}
            />
        );
    }

    return (
        <>
            <Loader />

            {feedback && <QuestionFeedBack handleFeedback={handleFeedback} feedback={feedback} />}

            {currentQuestion && !feedback && (
                <>
                    {questionType.toLowerCase() === questionDifficulty.SOFT && (
                        <Fade bottom duration={1000}>
                            <div className="show-learning-content" onClick={showLearningContent}>
                                <span>
                                    <Caret />
                                </span>
                                <Trans i18nKey="back_to_text" />
                            </div>
                        </Fade>
                    )}

                    <section className="body body--with-footer">
                        <div className="container h-100" id="start">
                            <Breadcrumb path={breadcrumbPath} />
                            <section className="question question-wrap d-flex flex-column justify-content-center">
                                <div className="teacher mx-auto teacher--alt">
                                    <TeacherMessage
                                        message={currentQuestion?.questionTitle}
                                        isHardLearningQuestion={questionType.toLowerCase() === questionDifficulty.HARD}
                                        duration={currentQuestion?.duration}
                                        questionId={currentQuestion?.questionId}
                                        delay={0}
                                    />
                                </div>

                                {(() => {
                                    switch (currentQuestion?.questionTypeId) {
                                        case questionTypes.TRUE_FALSE:
                                            return (
                                                <TrueAndFalse
                                                    selectedAnswers={answer}
                                                    changeEvent={handleAnswer}
                                                    question={currentQuestion}
                                                />
                                            );
                                        case questionTypes.MULTIPLE_CHOICES:
                                            return (
                                                <MultipleChoice
                                                    selectedAnswers={answer}
                                                    changeEvent={handleAnswer}
                                                    question={currentQuestion}
                                                />
                                            );
                                        case questionTypes.FILL_IN_THE_BLANKS:
                                            return (
                                                <FillInTheBlanks
                                                    selectedAnswers={answer}
                                                    changeEvent={handleAnswer}
                                                    question={currentQuestion}
                                                />
                                            );
                                        case questionTypes.SEQUENTIAL:
                                            return (
                                                <Draggable
                                                    selectedAnswers={answer}
                                                    changeEvent={handleAnswer}
                                                    question={currentQuestion}
                                                />
                                            );
                                        default:
                                    }
                                })()}
                            </section>

                            <footer
                                className={`footer ${
                                    answer &&
                                    typeof answer === 'object' &&
                                    Object.keys(common.cleanObject(answer)).length > 0 &&
                                    'footer__show-answer-button'
                                }`}
                            >
                                <div className="container d-flex align-items-center h-100">
                                    <div className="footer__button-wrap">
                                        {answer &&
                                            typeof answer === 'object' &&
                                            Object.keys(common.cleanObject(answer)).length > 0 && (
                                                <Button
                                                    size="md"
                                                    onClick={
                                                        questionType.toLowerCase() === questionDifficulty.HARD
                                                            ? handleFeedback
                                                            : postAnswer
                                                    }
                                                >
                                                    <Trans i18nKey="soft_feedback_next" />
                                                </Button>
                                            )}
                                    </div>
                                    <span className="footer__question-step">
                                        <Trans i18nKey="question" /> {questionId}
                                    </span>
                                </div>
                            </footer>
                        </div>
                    </section>
                </>
            )}
        </>
    );
};

export default QuestionPreview;
