/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { LineBar } from '../../components/Stats/ProgressElements/LineBar/LineBar';
import { TeacherMessage } from '../../components/Room/Teacher/Message/Message';
import { LevelCard } from '../../components/Overview/Levels/LevelCard';
import { levelService } from '../../services/level';
import { TextBadge } from '../../components/Badges/TextBadge/TextBadge';
import { LockIcon, DoneIcon } from '../../components/Icons';
import { BaseRoutes } from '../../constants';
import { setOngoingLevel, startLoading, stopLoading } from '../../actions';
import { Loader } from '../../components/Loader';
import { LinkWrapper } from '../../wrappers/LinkWrapper';
import { Button } from '../../components/Form/Button/Button';
import { BackToDashboard } from '../../components/Header';
import { setRoomDetails } from '../../actions/roomDetails.action';
import { common } from '../../helpers';
import SideRoomImage from '../../components/Levels/SideRoomImage/SideRoomImage';

function LevelSelect({ history, match, isPreview }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { roomId } = match.params;
    const [levels, setLevels] = useState([]);
    const [room, setRoom] = useState({});

    useEffect(() => {
        dispatch(startLoading());
        dispatch(setOngoingLevel(0, 0, 0));
        levelService
            .getLevelsByRoom(roomId)
            .then((response) => {
                setLevels(response.levels);
                setRoom(response.roomInfo);
                dispatch(setRoomDetails(response.roomInfo));
            })
            .catch(() => {
                setLevels([]);
                setRoom([]);
            })
            .then(() => {
                dispatch(stopLoading());
            });
    }, []);

    const icons = {
        Done: <DoneIcon />,
        Locked: <LockIcon />,
        Upcoming: <LockIcon />,
    };

    return (
        <>
            <Loader />
            <section className="body">
                <div className="container h-100">
                    <BackToDashboard />

                    <div className="row">
                        <Fade top when={levels.length > 0} duration={300} distance="30px">
                            <div className="col-md-auto">
                                <div className="level-selection-img-wrapper">
                                    {!common.isEmptyObject(room) && (
                                        <SideRoomImage image={room.icon} alt={t('room_image')} />
                                    )}
                                </div>
                            </div>
                        </Fade>

                        <div className="col-md-8 level-select__wrapper">
                            <Fade top when={levels.length > 0} duration={300} distance="30px">
                                <div className="level-selection-top">
                                    <div className="level-selection-top__title">{room && room.title}</div>
                                    <LineBar value={room && Math.round(room.completionRate)} />
                                </div>
                            </Fade>

                            {levels.length > 0 && (
                                <TeacherMessage
                                    delay={0}
                                    bottom={false}
                                    top
                                    message={room.description}
                                    animationDuration={500}
                                    distance="20px"
                                />
                            )}

                            {levels.length > 0 &&
                                levels.map((level, idx) => {
                                    let levelVariant = '';
                                    let icon = '';
                                    let textBadgeText = '';

                                    if (isPreview && level.isPlayable) {
                                        levelVariant = 'level-select';
                                    } else if (level.levelStatus === 0) {
                                        levelVariant = 'level-select--locked';
                                        icon = 'Upcoming';
                                        textBadgeText = <Trans i18nKey="upcoming" />;
                                    } else if (level.isPassed) {
                                        levelVariant = 'level-select--done';
                                        icon = 'Done';
                                        textBadgeText = <Trans i18nKey="done" />;
                                    } else if (level.isUnlocked === 1 || level.isProgress === 1) {
                                        levelVariant = 'level-select';
                                    } else {
                                        levelVariant = 'level-select--locked';
                                        icon = 'Locked';
                                        textBadgeText = <Trans i18nKey="locked" />;
                                    }

                                    return (
                                        <Fade bottom key={idx} duration={300} distance="30px">
                                            <LinkWrapper
                                                condition={
                                                    ((isPreview && level.isPlayable) || level.levelStatus === 1) &&
                                                    (isPreview || level.isUnlocked || level.isProgress)
                                                }
                                                wrapper={(children) => (
                                                    <Link
                                                        className="card__link"
                                                        key={`link-${idx}`}
                                                        to={`${history.location.pathname}/${level.levelId}${BaseRoutes.QUESTION}`}
                                                    >
                                                        {children}
                                                    </Link>
                                                )}
                                            >
                                                <LevelCard key={idx} type={level.levelType} variant={levelVariant}>
                                                    <span className="level-select__level-name">
                                                        <Trans i18nKey={level.levelPrefix} />
                                                    </span>
                                                    {icon && <TextBadge text={textBadgeText} />}
                                                    {icon && icons[icon]}
                                                    {(isPreview || level.isPassed !== 'PASSED') &&
                                                        (isPreview || level.isUnlocked === 1) &&
                                                        ((isPreview && level.isPlayable) ||
                                                            level.levelStatus !== 0) && (
                                                            <Button
                                                                className="btn btn-primary btn-md"
                                                                to={`${history.location.pathname}/${level.levelId}${BaseRoutes.QUESTION}`}
                                                            >
                                                                <Trans i18nKey="start" />
                                                            </Button>
                                                        )}
                                                </LevelCard>
                                            </LinkWrapper>
                                        </Fade>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export { LevelSelect };
