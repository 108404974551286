import { baseJsonClient } from '../helpers/httpClient';

export default {};

/**
 * @deprecated
 */
export function getTenantProperties() {
  return baseJsonClient.get('/properties');
}

export function getPrivateTenantProperties() {
  return baseJsonClient.get('/properties');
}

export function getPublicTenantProperties() {
  return baseJsonClient.get('/public_properties');
}
