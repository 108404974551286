/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React from 'react';
import { BaseRoutes, roomStatus } from '../../../constants';
import { LinkWrapper } from '../../../wrappers/LinkWrapper';
import RoomStatus from './Status';
import DynamicImage from '../../DynamicMedia/DynamicImage';

const ImageCard = ({
  room, idx, completedRate, alt, link,
}) => (
  <div className="col-md-6 col-lg-4 col-xl-4 col--card-col" key={idx}>
    <LinkWrapper
      condition={room.status !== roomStatus.ROOM_AWAITING_APPROVAL}
      wrapper={(children) => (
        <Link
          className="card__link"
          key={`link-${idx}`}
          to={link}
                >
                    {children}
                </Link>
            )}
        >
            <div
                className={`card card--learning ${room.status === roomStatus.ROOM_AWAITING_APPROVAL && 'card--upcoming'}`}
            >
                <div className="card__img-wrp">
                    <DynamicImage
                        path={room.room_icon ? room.room_icon : '/frontend/images/room_image.png'}
                        alt={alt}
                        classNames="card__img"
                    />
                </div>
                <div className="card__body">
                    <div className="card__name">{room.title}</div>
                    <RoomStatus room={room} completedRate={completedRate} />
                </div>
            </div>
        </LinkWrapper>
    </div>
);

export default ImageCard;

ImageCard.propTypes = {
    room: PropTypes.object.isRequired,
    idx: PropTypes.number.isRequired,
    completedRate: PropTypes.number.isRequired,
    alt: PropTypes.string.isRequired,
};
