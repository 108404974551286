/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { timer } from '../constants';

const initialState = {
    timePassed: false,
    canAnswer: true,
};

export function timerReducer(state = initialState, action) {
    switch (action.type) {
        case timer.HARD_LEARNING_TIMER:
            return {
                ...initialState,
                ...action.payload,
            };

        default: {
            return state;
        }
    }
}
