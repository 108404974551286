import { EXIT_URL, LOGOUT_URL } from './appUrls';

export const BaseRoutes = {
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot_password',
  COMPLETE_USER_SETUP: '/complete_user_setup',
  PROFILE: '/profile',
  OVERVIEW: '/overview',
  LEVELS: '/levels',
  ROOMS: '/rooms',
  QUESTION: '/questions',
  STATISTICS: '/statistics',
  STATS_OVERVIEW: '/stats-overview',
  QUESTION_WITH_INPUT: '/question-with-input',
  QUESTION_WITH_IMAGE: '/question-with-image',
  QUESTION_WITH_MULTIPLE_ANSWERS: '/question-with-multiple-answers',
  QUESTION_WITH_DRAG_AND_DROP_ANSWERS: '/question-with-drag-and-drop-answers',
  QUESTION_FEEDBACK: '/question-feedback',
  PREVIEW: '/preview',
  LEARNING_CONTENT: '/learning_content',
  SHOP: '/shop',
};

export const errorRoutes = {
  NOT_FOUND: '/404',
};

export const StaticRoutes = {
  ADMIN_PANEL_URL: '/admin/game',
  EXIT_URL: `${EXIT_URL}`,
  LOGOUT_URL: `${LOGOUT_URL}`,
};
