/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PreLoginWrapper from '../../components/PreLoginWrapper/PreLoginWrapper';
import { useQuery } from '../../helpers/query';
import { BaseRoutes } from '../../constants';
import { completeTenantUserSetup } from '../../services/users/complete.service';
import { getPublicTenantProperties } from '../../services/tenant-properties.service';
import { tenantSetCoacherAvatar, tenantSetName } from '../../actions/tenant.action';

function CompleteUserSetup({ history }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [error, setError] = useState('');
    const [breakingError, setBreakingError] = useState('');
    const [acceptToken, setAcceptToken] = useState(null);
    const [emailVerificationToken, setEmailVerificationToken] = useState(null);

    const dispatch = useDispatch();
    const tenantName = useSelector((state) => state.tenant.tenantName);
    const coacherAvatar = useSelector((state) => state.tenant.tenantCoacherAvatar);

    const { t } = useTranslation();
    const query = useQuery();

    useEffect(() => {
        const _acceptToken = query.get('accept_token');
        if (!_acceptToken) {
            setBreakingError('Accept token is missing.');
        }

        const _emailVerificationToken = query.get('email_verification_token');
        if (!_emailVerificationToken) {
            setBreakingError('Email verification token is missing.');
        }

        setAcceptToken(_acceptToken);
        setEmailVerificationToken(_emailVerificationToken);

        if (!tenantName) {
            getPublicTenantProperties().then((response) => {
                const { data } = response.data;
                const { name } = data;
                dispatch(tenantSetName(name));
                dispatch(tenantSetCoacherAvatar(data.coacherAvatar));
            });
        }
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();

        if (password !== passwordConfirmation) {
            setError('The passwords does not match');
            return;
        }

        completeTenantUserSetup(acceptToken, emailVerificationToken, firstName, lastName, password)
            .then((response) => {
                if (response.status !== 204) {
                    setError(response.data.message);
                }

                history.push(BaseRoutes.LOGIN);
            })
            .catch((apiError) => {
                setError(apiError.response.data.message);
            });
    };

    if (breakingError !== '') {
        return (
            <PreLoginWrapper tenantName={tenantName} coacherAvatar={coacherAvatar}>
                <div style={{ textAlign: 'center' }}>
                    <span>{breakingError}</span>
                    <br />
                    <br />
                    <button
                        type="button"
                        className="btn btn-primary btn-lg"
                        onClick={() => history.push(BaseRoutes.LOGIN)}
                    >
                        <Trans i18nKey="back_to_login" />
                    </button>
                </div>
            </PreLoginWrapper>
        );
    }

    return (
        <PreLoginWrapper tenantName={tenantName} coacherAvatar={coacherAvatar}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div style={{ maxWidth: 500 }}>
                    <form onSubmit={onSubmit}>
                        <div className="login__form-wrapper">
                            <div className="login__input-container">
                                <input
                                    type="text"
                                    className="login__text-field"
                                    id="firstName"
                                    required
                                    value={firstName}
                                    autoComplete={false}
                                    onChange={(event) => setFirstName(event.target.value)}
                                />
                                <label className="place-holder" htmlFor="firstName">
                                    <Trans i18nKey="firstName" />
                                </label>
                            </div>
                            <div className="login__input-container">
                                <input
                                    type="text"
                                    className="login__text-field"
                                    id="lastName"
                                    required
                                    value={lastName}
                                    autoComplete={false}
                                    onChange={(event) => setLastName(event.target.value)}
                                />
                                <label className="place-holder" htmlFor="lastName">
                                    <Trans i18nKey="lastName" />
                                </label>
                            </div>
                            <div className="login__input-container">
                                <input
                                    type="password"
                                    className="login__text-field"
                                    id="password"
                                    required
                                    value={password}
                                    autoComplete={false}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                <label className="place-holder" htmlFor="password">
                                    <Trans i18nKey="password" />
                                </label>
                            </div>
                            <div className="login__input-container">
                                <input
                                    type="password"
                                    className="login__text-field"
                                    id="password-confirmation"
                                    required
                                    value={passwordConfirmation}
                                    autoComplete={false}
                                    onChange={(event) => setPasswordConfirmation(event.target.value)}
                                />
                                <label className="place-holder" htmlFor="password-confirmation">
                                    <Trans i18nKey="password_confirmation" />
                                </label>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 16,
                                }}
                            >
                                <input
                                    type="checkbox"
                                    id="privacy-policy-consent"
                                    style={{
                                        height: 20,
                                        width: 20,
                                        position: 'relative',
                                    }}
                                    value=""
                                    required
                                />
                                <label
                                    className="place-holder"
                                    htmlFor="privacy-policy-consent"
                                    style={{
                                        position: 'relative',
                                        fontSize: 14,
                                        paddingLeft: 10,
                                        margin: 0,
                                    }}
                                >
                                    <Trans i18nKey="privacy_policy_accept" />{' '}
                                    <a
                                        rel="noreferrer noopener"
                                        target="_blank"
                                        href="https://debroome.com/product-privacy-policy/"
                                    >
                                        <Trans i18nKey="privacy_policy" />
                                    </a>
                                </label>
                            </div>
                            {error !== '' && <div style={{ marginBottom: 12 }}>{error}</div>}
                            <button type="submit" className="btn btn-primary btn-lg login__button">
                                <Trans i18nKey="complete_account_setup" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </PreLoginWrapper>
    );
}

export default CompleteUserSetup;
