/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { deleteUserModal } from '../constants';

const initialState = {
    show: false,
    firstName: null,
    lastName: null,
    email: null,
};

export function deleteUserContent(state = initialState, action) {
    switch (action.type) {
        case deleteUserModal.SHOW_MODAL:
            return action.payload;

        case deleteUserModal.HIDE_MODAL:
            return action.payload;

        default: {
            return state;
        }
    }
}
