/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { userInfo } from '../constants';

const initialState = {};

export function loggedUser(state = initialState, action) {
    switch (action.type) {
        case userInfo.LOGGED_USER_INFO:
            return action.payload;

        default: {
            return state;
        }
    }
}
