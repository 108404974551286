/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Novice } from '../../../components/Icons/Novice';
import { Coins } from '../../../components/Icons/Coins';
import { common } from '../../../helpers';
import { Ambassador } from '../../../components/Icons/Ambassador';
import { Profesional } from '../../../components/Icons/Profesional';
import { Advocate } from '../../../components/Icons/Advocate';
import { Explorer } from '../../../components/Icons/Explorer';
import { Apprentice } from '../../../components/Icons/Apprentice';
import { ProfilePicturePlaceHolder } from '../../../components/Icons';
import { Glimmer } from '../../../components/Icons/Glimmer';

function getLevelTitleIcon(levelTitle) {
    const { t } = useTranslation();

    switch (levelTitle) {
        case 'apprentice':
            return <Apprentice />;
        case 'explorer':
            return <Explorer />;
        case 'advocate':
            return <Advocate />;
        case 'professional':
            return <Profesional />;
        case 'ambassador':
            return <Ambassador />;
        default:
            return <Novice />;
    }
}

export function PlayerStatus({
    firstName,
    lastName,
    achievedCoins = 10,
    levelReadTitleTranslationKey = 'novice',
    avatar = '',
}) {
    return (
        <div className="card player-status-card stat-card">
            <div className="stat-card__title">
                <Trans i18nKey="you" />
            </div>
            <div className="stat-card__content">
                {avatar ? (
                    <img
                        className="player-status-card__avatar avatars-stack__avatar--size-50"
                        alt="profile"
                        src={avatar}
                    />
                ) : (
                    <span className="player-status-card__avatar--border">
                        <ProfilePicturePlaceHolder height={50} width={50} />
                    </span>
                )}
                <div className="player-status-card__player-details-wrapper player-status-card__player-details-wrapper--margin-left-10">
                    <div className="player-status-card__player-name">
                        {firstName} {lastName}
                    </div>
                    <div className="player-status-card__player-stat-wrapper">
                        <div className="player-status-card__player-achievement">
                            <div className="player-status-card__player-achievement__icon">
                                {getLevelTitleIcon(levelReadTitleTranslationKey)}
                            </div>
                            <div className="player-status-card__player-achievement__name">
                                <Trans i18nKey={levelReadTitleTranslationKey} />
                            </div>
                        </div>
                        <div className="player-status-card__coins">
                            <div className="player-status-card__coins__icon">
                                <Glimmer height="20" />
                            </div>
                            <div className="player-status-card__coins__count">
                                <span className="player-status-card__coins__count--word-spacing">
                                    {common.separateNumberByThousands(achievedCoins)}
                                </span>{' '}
                                <Trans i18nKey="learning_coins" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
