/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';

import { Fade } from 'react-bootstrap';
import PropTypes from 'prop-types';
import QuestionContent from '../QuestionContent/QuestionContent';
import SortableElement from '../../../components/Room/Cards/SortableCard/SortableElement';
import { common } from '../../../helpers';

const Draggable = ({ changeEvent, question, selectedAnswers }) => {
    const [answers, setAnswers] = useState([]);
    const [height, setHeight] = useState(72);
    const [readyToShow, setReadyToShow] = useState(false);
    const answerHeights = [];

    useEffect(() => {
        setAnswers([]);

        if (selectedAnswers && selectedAnswers.length) {
            const selectedAnswersWithValues = selectedAnswers.map((id) => ({
                id,
                value: question.answers.find((answerObj) => answerObj.id === id).answer,
            }));
            setAnswers(selectedAnswersWithValues);
        } else {
            question.answers.map((answerObject) => {
                setAnswers((prevAnswers) => [
                    ...prevAnswers,
                    { id: answerObject.answerId, value: answerObject.answer },
                ]);
            });
        }
    }, [question]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    function handleDragEnd(event) {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            const oldIndex = answers.findIndex((answer) => answer.id === active.id);
            const newIndex = answers.findIndex((answer) => answer.id === over.id);
            const organizedAnswers = arrayMove(answers, oldIndex, newIndex);

            changeEvent(event, organizedAnswers);
            setAnswers(organizedAnswers);
        }
    }

    function heightCheck(divHeight) {
        answerHeights.push(divHeight);
        if (answerHeights.length === answers.length) {
            const optionTimer = setTimeout(async () => {
                await setHeight(Math.max(...answerHeights));

                // To disable the transition
                const loadingTimer = await setTimeout(() => {
                    setReadyToShow(true);
                }, 500);
                return () => clearTimeout(loadingTimer);
            }, 100);

            return () => {
                clearTimeout(optionTimer);
            };
        }
    }

    return (
        <Fade bottom duration={500}>
            <>
                <QuestionContent {...question} />
                <div className="drag-drop__wrapper">
                    <div className={`answer-loader ${readyToShow && 'disable-loader'}`}>
                        <div className="img-wrap" />
                        <div className="bgc-wrap" />
                    </div>

                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={answers} strategy={rectSortingStrategy}>
                            <div className="row drag-drop__row">
                                {(() => {
                                    if (answers.length > 0) {
                                        const chunks = common.sliceIntoChunks(answers, Math.round(answers.length / 2));
                                        let index = 0;
                                        return chunks.map((chunk, chkIdx) => (
                                            <div className="col-sm-6 col-xs-12 drag-drop__column" key={chkIdx}>
                                                {chunk.map((answer) => {
                                                    index += 1;
                                                    return (
                                                        <SortableElement
                                                            key={answer.id}
                                                            id={answer.id}
                                                            index={index}
                                                            height={height}
                                                            label={answer.value}
                                                            cf={heightCheck}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        ));
                                    }
                                })()}
                            </div>
                        </SortableContext>
                    </DndContext>
                </div>
            </>
        </Fade>
    );
};

Draggable.propTypes = {
    question: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Draggable;
