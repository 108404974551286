/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const GlitteringBox = ({ width = 180.942, color = 'rgb(var(--primaryRGB))' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width * 0.88766} viewBox="0 0 180.942 160.615">
        <g id="Illustration" transform="translate(-81.017 -30.843)">
            <g id="Glitter" transform="translate(-20 -20)">
                <g id="Perk_-_Glitter" data-name="Perk - Glitter" transform="translate(101.017 152.873)">
                    <path
                        id="Path_110"
                        data-name="Path 110"
                        d="M380.172,220.926a29.136,29.136,0,0,0-7.992-9.607,29.154,29.154,0,0,0,7.992-9.6,29.119,29.119,0,0,0,7.992,9.6A29.1,29.1,0,0,0,380.172,220.926Z"
                        transform="translate(-372.18 -201.714)"
                        fill={color}
                    />
                </g>
                <g id="Perk_-_Glitter-2" data-name="Perk - Glitter" transform="translate(177 50.843)">
                    <path
                        id="Path_110-2"
                        data-name="Path 110"
                        d="M381.18,223.349a32.81,32.81,0,0,0-9-10.819,32.831,32.831,0,0,0,9-10.816,32.793,32.793,0,0,0,9,10.816A32.772,32.772,0,0,0,381.18,223.349Z"
                        transform="translate(-372.18 -201.714)"
                        fill={color}
                    />
                </g>
                <g id="Perk_-_Glitter-3" data-name="Perk - Glitter" transform="translate(222.041 198.479)">
                    <path
                        id="Path_110-3"
                        data-name="Path 110"
                        d="M377.579,214.694a19.685,19.685,0,0,0-5.4-6.491,19.7,19.7,0,0,0,5.4-6.489,19.674,19.674,0,0,0,5.4,6.489A19.662,19.662,0,0,0,377.579,214.694Z"
                        transform="translate(-372.18 -201.714)"
                        fill={color}
                    />
                </g>
                <g id="Perk_-_Glitter-4" data-name="Perk - Glitter" transform="translate(267 77.479)">
                    <path
                        id="Path_110-4"
                        data-name="Path 110"
                        d="M377.579,214.694a19.685,19.685,0,0,0-5.4-6.491,19.7,19.7,0,0,0,5.4-6.489,19.674,19.674,0,0,0,5.4,6.489A19.662,19.662,0,0,0,377.579,214.694Z"
                        transform="translate(-372.18 -201.714)"
                        fill={color}
                    />
                </g>
                <g id="Perk_-_Dot" data-name="Perk - Dot" transform="translate(109.008 104.479)">
                    <circle id="Ellipse_48" data-name="Ellipse 48" cx="2" cy="2" r="2" fill={color} />
                </g>
                <g id="Perk_-_Dot-2" data-name="Perk - Dot" transform="translate(277.959 145.479)">
                    <circle id="Ellipse_48-2" data-name="Ellipse 48" cx="2" cy="2" r="2" fill={color} />
                </g>
                <g id="Perk_-_Dot-3" data-name="Perk - Dot" transform="translate(148 196.479)">
                    <circle id="Ellipse_48-3" data-name="Ellipse 48" cx="2" cy="2" r="2" fill={color} />
                </g>
            </g>
            <g id="Box" transform="translate(-1713.922 -498.705) rotate(-1)">
                <g id="Group_1031" data-name="Group 1031" transform="translate(0 13)">
                    <path
                        id="Path_655"
                        data-name="Path 655"
                        d="M1814.964,602.528v58.89a1.954,1.954,0,0,0,1.015,1.716l42.493,23.313a1.963,1.963,0,0,0,1.206.223l78.474-10.7a1.956,1.956,0,0,0,1.691-1.939V613.383a1.952,1.952,0,0,0-1.034-1.726l-43.7-21.851a1.961,1.961,0,0,0-1.2-.211l-77.268,11A1.957,1.957,0,0,0,1814.964,602.528Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                    />
                    <line
                        id="Line_120"
                        data-name="Line 120"
                        y1="11.687"
                        x2="76.642"
                        transform="translate(1863.202 613.383)"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                    />
                    <line
                        id="Line_121"
                        data-name="Line 121"
                        x2="40.398"
                        y2="21.739"
                        transform="translate(1814.964 602.529)"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                    />
                    <path
                        id="Path_657"
                        data-name="Path 657"
                        d="M0,48.606V0"
                        transform="translate(1859.065 631.857)"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeWidth="3"
                    />
                    <path
                        id="Path_656"
                        data-name="Path 656"
                        d="M1849.3,635.447l6.973,14.794a2.348,2.348,0,0,0,3.48.916l28.137-19.9a2.348,2.348,0,0,0,.773-2.911l-6.665-14.283a2.349,2.349,0,0,0-3.451-.947L1850.1,632.505A2.349,2.349,0,0,0,1849.3,635.447Z"
                        transform="translate(28.331 16.719)"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        opacity="0"
                    />
                </g>
                <path
                    id="Path_660"
                    data-name="Path 660"
                    d="M40,8"
                    transform="translate(1817.879 646.445) rotate(18)"
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeWidth="3"
                />
                <line
                    id="Line_122"
                    data-name="Line 122"
                    y1="12.94"
                    x2="79.91"
                    transform="translate(1835.115 612.819)"
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeWidth="3"
                />
            </g>
            <g id="Tag_-_Done_-_Key-Bg" data-name="Tag - Done - Key-Bg" transform="translate(210 73.978)">
                <circle id="Oval_Copy_4" data-name="Oval Copy 4" cx="24" cy="24" r="24" fill="#fff" />
                <g id="Icon_-_Feedback-Pos" data-name="Icon - Feedback-Pos" transform="translate(8 8.5)">
                    <rect id="Boundingbox" width="32" height="32" fill="none" />
                    <path
                        id="Check"
                        d="M-1.758,10.163,5.53,17.548,22.242,0"
                        transform="translate(5.758 7.027)"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                    />
                </g>
            </g>
        </g>
    </svg>
);
