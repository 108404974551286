/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { API_BASE_URL } from '../constants/appUrls';

i18n.use(Backend)
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        backend: {
            loadPath: `${API_BASE_URL}/language/translations`,
            crossDomain: false,
            withCredentials: true,
        },
        fallbackLng: 'en', // Use en if detected lng is not available
        debug: false,
        keySeparator: false, // We do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // React already safes from xss
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
