/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import PropTypes from 'prop-types';
import React from 'react';
import { questionTypes } from '../../constants';

const MQOptionWithoutImage = ({ changeEvent, answer, isChecked }) => (
    <div className="multiple-answer__item">
        <input
            type="checkbox"
            className="multiple-answer__choose"
            value="active"
            onChange={(evt) => changeEvent(evt, answer)}
            name={questionTypes.MULTIPLE_CHOICES}
            defaultChecked={isChecked}
        />
        <label className="card multiple-answer__item-inner" htmlFor="multiple-ans-1">
            <span className="multiple-answer__text">{answer.answer}</span>
        </label>
    </div>
);

export default MQOptionWithoutImage;

MQOptionWithoutImage.propTypes = {
    answer: PropTypes.object.isRequired,
    changeEvent: PropTypes.func.isRequired,
};
