/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { IconBadge } from '../Badges/IconBadge/IconBadge';
import { Button } from '../Form/Button/Button';
import { history } from '../../helpers';
import { BaseRoutes, rewards } from '../../constants';
import '../../helpers/i18n';
import { showFewerBadgesClick } from '../../actions';

function AllBadges(props) {
    const { className = 'profile-card', actionButtons = true, enableOnlyThreeColumns = false } = props;

    const dispatch = useDispatch();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const authentication = useSelector((state) => state.authentication);
    const [badges, setBadges] = useState([]);
    const [pendingBadges, setPendingBadges] = useState([]);
    const [currentAvailableBadgesCount, setCurrentAvailableBadgesCount] = useState(0);
    const [emptyBadges, setEmptyBadges] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (Object.keys(authentication).length > 0 && typeof authentication.user !== 'undefined') {
            const authUser = authentication.user;
            let totalNoOfBadgesPerRow;

            // eslint-disable-next-line no-restricted-globals
            if (screen.width < 1920) {
                totalNoOfBadgesPerRow = rewards.TOTAL_NO_OF_BADGES_PER_ROW_FOR_SMALL_SCREEN;
            } else {
                totalNoOfBadgesPerRow = rewards.TOTAL_NO_OF_BADGES_PER_ROW;
            }

            setBadges(authUser.badges);
            setPendingBadges(authUser.pending_badges);
            setCurrentAvailableBadgesCount(authUser.badges.length + authUser.pending_badges.length);

            if (currentAvailableBadgesCount % totalNoOfBadgesPerRow > 0) {
                const emptyBadgesContents = [];
                const total = totalNoOfBadgesPerRow - (currentAvailableBadgesCount % totalNoOfBadgesPerRow);

                for (let i = 0; i < total; i++) {
                    emptyBadgesContents.push(
                        <IconBadge
                            variant="learning-badge"
                            additionalClass="learning-badge--empty"
                            key={`'empty-${i}'`}
                        />,
                    );
                }

                setEmptyBadges(emptyBadgesContents);
            } else {
                setEmptyBadges([]);
            }
        }
    }, [authentication, currentAvailableBadgesCount, screenWidth]);

    const levelSelect = () => {
        history.push(BaseRoutes.ROOMS);
    };

    const viewLessBadges = () => {
        dispatch(showFewerBadgesClick());
    };

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return (
        <div className={className}>
            <div className="profile-card__title">
                <Trans i18nKey="badges" />
            </div>

            <Fade top duration={1000}>
                <div className="profile-card__learning-badge-wrapper">
                    <div className="d-flex justify-content-between">
                        <span className="profile-card__badge-count-lbl">
                            {badges.length} <Trans i18nKey="out_of" /> {currentAvailableBadgesCount}{' '}
                            <Trans i18nKey="badges" />
                        </span>
                        <a href="#" className="profile-card__badge-show-link" onClick={viewLessBadges}>
                            <Trans i18nKey="show_less" />
                        </a>
                    </div>
                    <div
                        className={
                            enableOnlyThreeColumns
                                ? 'profile-card__learning-badge-inner--3-columns'
                                : 'profile-card__learning-badge-inner'
                        }
                    >
                        {badges.map((d) => (
                            <IconBadge variant={d.css_class_name} key={d.id} title={t(d.translation_key)} />
                        ))}
                        {pendingBadges.length > 0 &&
                            pendingBadges.map((d) => (
                                <IconBadge
                                    variant={d.css_class_name}
                                    additionalClass="learning-badge--pending"
                                    key={`'pending-${d.id}'`}
                                    title={t(d.translation_key)}
                                />
                            ))}

                        {emptyBadges}
                    </div>
                </div>
            </Fade>

            {actionButtons && (
                <div className="profile-card__action-btn-wrapper">
                    <Button size="md" onClick={levelSelect} variant="btn-primary btn-block">
                        <Trans i18nKey="continue" />
                    </Button>
                    <Button size="md" variant="btn-outline-primary btn-block">
                        <Trans i18nKey="overview" />
                    </Button>
                </div>
            )}
        </div>
    );
}

export { AllBadges };
