/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const ProfilePicturePlaceHolder = ({ height = 84, width = 84 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 92 92">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_882" data-name="Rectangle 882" width="43" height="47" fill="none" />
            </clipPath>
        </defs>
        <g id="Group_760" data-name="Group 760" transform="translate(-5 -5)">
            <circle id="Bg" cx="46" cy="46" r="46" transform="translate(5 5)" fill="#fff" />
            <g id="Icon_Avatar-No-Photo" data-name="Icon / Avatar-No-Photo" transform="translate(29.395 24.002)">
                <g id="Group_727" className="profile-placeholder-img" data-name="Group 727" clipPath="url(#clip-path)">
                    <path
                        id="Path_417"
                        data-name="Path 417"
                        d="M32.913,12.916A11.412,11.412,0,1,1,21.5,1.5,11.415,11.415,0,0,1,32.913,12.916Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#fd6119"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_418"
                        data-name="Path 418"
                        d="M1.5,45.49V43.116a11.893,11.893,0,0,1,11.892-11.9H29.608A11.893,11.893,0,0,1,41.5,43.116V45.49"
                        transform="translate(0 0.009)"
                        fill="none"
                        stroke="#fd6119"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </g>
    </svg>
);
