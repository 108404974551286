/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { showDeleteUserModal, showError } from '../../../../actions';

function TableRow({ user }) {
    const acceptedChip = (accepted) => {
        if (accepted) {
            return <span className="badge __badge--success">Accepted</span>;
        }
        return <span className="badge __badge--pending">Pending</span>;
    };

    const { firstName, lastName, email, lastLogin, createdAt, userHasAccepted } = user;
    const dispatch = useDispatch();
    const loggedInUser = useSelector((state) => state.loggedUser.user);

    const showDeleteModal = (userFirstName, userLastName, userEmail) => {
        dispatch(showDeleteUserModal(userFirstName, userLastName, userEmail));
    };

    const showCannotDeleteUserAlert = () => {
        dispatch(showError('You cannot delete your own account!'));
    };

    return (
        <tr>
            <td>{`${firstName || 'N/A'} ${lastName || 'N/A'}`}</td>
            <td>{email}</td>
            <td>{lastLogin || 'N/A'}</td>
            <td>{createdAt || 'N/A'}</td>
            <td>{acceptedChip(userHasAccepted)}</td>
        </tr>
    );
}

TableRow.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TableRow;
