/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Menu from './Menu/Menu';
import { BaseRoutes, MEDIA_FILE_IS_MISSING } from '../../constants';
import DynamicImage from '../DynamicMedia/DynamicImage';
import { setLanguageLoaded, themeSetAction } from '../../actions';
import { getPublicTenantProperties } from '../../services/tenant-properties.service';
import i18n from '../../helpers/i18n';

function Header(props) {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme);
    const { logoLink, showMenu } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if (!theme.themeLogo) {
            getPublicTenantProperties()
                .then((response) => {
                    const { data } = response.data;
                    const { logo } = data;
                    dispatch(themeSetAction(logo));
                })
                .catch(() => {});
        }

        i18n.on('loaded', () => {
            dispatch(setLanguageLoaded());
        });
    }, []);

    return (
        <header className="header">
            <div className="container d-flex align-items-center justify-content-between">
                <Fade>
                    <Link to={logoLink} className="header__logo">
                        <DynamicImage path={theme.themeLogo} alt={t('logo')} classNames="header__logo-img" />
                    </Link>
                </Fade>
                {showMenu && <Menu />}
            </div>
        </header>
    );
}

Header.defaultProps = {
    logoLink: BaseRoutes.ROOMS,
    showMenu: true,
};

export { Header };
