/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { Trans, useTranslation } from 'react-i18next';
import { profileService } from '../../../../services/profile';
import { showError } from '../../../../actions/error.action';
import ImageCropper from '../../../ImageCropper/ImageCropper';
import { ProfilePicturePlaceHolder } from '../../../Icons';
import { userService } from '../../../../services/user';
import { loggedUserInfo } from '../../../../actions';
import 'react-tippy/dist/tippy.css';
import DynamicImage from '../../../DynamicMedia/DynamicImage';

export const ProfilePercentage = (props) => {
    const { profileImage, completionRate = 10 } = props;
    const [avatar, setAvatar] = useState();
    const dispatch = useDispatch();
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [croppedImage, setCroppedImage] = useState(undefined);
    const [imageName, setImageName] = useState('');
    const [originalImageName, setOriginalImageName] = useState('');

    const circumference = 2 * Math.PI * 47;
    const progressIndicatorOffset = ((100 - completionRate) / 100) * circumference;
    const { t } = useTranslation();

    useEffect(() => {
        setAvatar(profileImage);
    }, [profileImage]);

    const profileImageChangeHandler = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedImage = event.target.files[0];
            event.target.value = null;

            setOriginalImageName(selectedImage.name);

            if (!selectedImage.name.match(/\.(jpg|jpeg|png)$/)) {
                dispatch(showError({ message: <Trans i18nKey="invalid_file_type" /> }, 'text'));
                return false;
            }

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const image = reader.result;
                setImageToCrop(image);
                setImageName(`fn${Date.now()}`);
            });
            reader.readAsDataURL(selectedImage);
        }
    };

    const uploadImage = () => {
        if (croppedImage !== undefined) {
            setAvatar(null);
            profileService
                .updateProfileImage(croppedImage, originalImageName)
                .then((response) => {
                    if (response.url !== undefined) {
                        userService
                            .getUser()
                            .then((user) => {
                                dispatch(loggedUserInfo(user));
                                setAvatar(user.avatar);
                            })
                            .catch(() => {});
                    }
                })
                .catch(() => setAvatar(profileImage));
        } else {
            dispatch(showError({ message: <Trans i18nKey="invalid_image_size" /> }, 'text'));
        }
    };

    return (
        <div className="profile-card__profile">
            <div className="profile-card__profile-img-wrapper">
                {avatar ? (
                    <DynamicImage path={avatar} alt={t('profile_image')} classNames="profile-card__profile-img" />
                ) : (
                    <ProfilePicturePlaceHolder />
                )}
            </div>
            <div className="profile-card__profile-progress">
                <svg
                    id="profile-progress"
                    xmlns="http://www.w3.org/2000/svg"
                    width="102"
                    height="102"
                    viewBox="0 0 102 102"
                    transform="rotate(270)"
                >
                    <circle
                        id="profile-progress-bg"
                        cx="47"
                        cy="47"
                        r="47"
                        fill="none"
                        stroke="rgba(var(--primaryRGB),0.3)"
                        strokeLinecap="round"
                        strokeWidth="4"
                        transform="translate(4 4)"
                    />
                    <circle
                        id="profile-progress-indicator"
                        cx="47"
                        cy="47"
                        r="47"
                        fill="none"
                        stroke="rgb(var(--primaryRGB))"
                        strokeLinecap="round"
                        strokeWidth="4"
                        strokeDasharray="295 295"
                        strokeDashoffset={progressIndicatorOffset.toString()}
                        transform="translate(4 4)"
                        style={{ transition: 'all 1s' }}
                    />
                </svg>

                <label className="profile-card__profile-btn" htmlFor="profile-img">
                    <Tooltip key={avatar} title={t('change_profile_image')} position="top" arrow>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={profileImageChangeHandler}
                            className="profile-card__profile-input"
                            title=""
                        />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21.183"
                            height="21.183"
                            viewBox="0 0 21.183 21.183"
                        >
                            <path d="M0,0H21.183V21.183H0Z" fill="none" />
                            <path
                                d="M12.7,3.765,14.312,5.53h3.575V16.122H3.765V5.53H7.34L8.955,3.765H12.7M13.474,2h-5.3L6.563,3.765h-2.8A1.77,1.77,0,0,0,2,5.53V16.122a1.77,1.77,0,0,0,1.765,1.765H17.887a1.77,1.77,0,0,0,1.765-1.765V5.53a1.77,1.77,0,0,0-1.765-1.765h-2.8ZM10.826,8.178a2.648,2.648,0,1,1-2.648,2.648,2.656,2.656,0,0,1,2.648-2.648m0-1.765a4.413,4.413,0,1,0,4.413,4.413A4.415,4.415,0,0,0,10.826,6.413Z"
                                transform="translate(-0.235 -0.235)"
                                fill="rgb(var(--primaryRGB))"
                            />
                        </svg>
                    </Tooltip>
                </label>
            </div>

            <ImageCropper
                imageToCrop={imageToCrop}
                onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                uploadHandle={uploadImage}
                fileName={imageName}
            />
        </div>
    );
};
