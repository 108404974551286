/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const UpcomingIcon = (props) => {
    const { className = 'card__learning-status' } = props;
    return (
        <span className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <g opacity="0.5">
                    <path d="M0,0H24V24H0Z" fill="none" />
                    <path d="M19,4H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V10H19ZM19,8H5V6H19Zm-7,5h5v5H12Z" />
                </g>
            </svg>
        </span>
    );
};
