/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const AllCorrectLevelBadge = () => (
    <svg
        id="Badge-All-Correct-Level"
        xmlns="http://www.w3.org/2000/svg"
        width="63.99"
        height="64"
        viewBox="0 0 63.99 64"
    >
        <path
            id="Bg"
            d="M53.992,64H10A10,10,0,0,1,0,54V10.008C.2,4.417,5.21-.241,10,.01H53.992a10,10,0,0,1,10,10V54a10,10,0,0,1-10,10"
            fill="rgb(var(--primaryRGB))"
        />
        <path id="L" d="M13.844,54.961l2.112-9.994h2.673L16.982,52.78h3.26l-.464,2.181Z" fill="#fff" />
        <path
            id="E"
            d="M27.153,54.961H21.219l2.113-9.994h5.933l-.444,2.167H25.56l-.321,1.572h3.035l-.492,2.167H24.747l-.39,1.887h3.261Z"
            fill="#fff"
        />
        <path
            id="V"
            d="M35.705,44.967h2.83l-4.956,9.994H30.6l-.711-9.994H32.5l.185,5.476v.348a12.477,12.477,0,0,1-.11,1.75h.055q.094-.438.3-1.076a8.4,8.4,0,0,1,.379-.995Z"
            fill="#fff"
        />
        <path
            id="E-2"
            data-name="E"
            d="M43.662,54.961H37.728l2.113-9.994h5.933l-.444,2.167H42.069l-.321,1.572h3.035l-.492,2.167H41.256l-.39,1.887h3.261Z"
            fill="#fff"
        />
        <path id="L-2" data-name="L" d="M45.6,54.961l2.112-9.994h2.673L48.735,52.78h3.26l-.464,2.181Z" fill="#fff" />
        <rect id="Bound" width="63.99" height="64" fill="none" />
        <path
            id="Circle_"
            data-name="Circle "
            d="M15238.675,17414.688a8.666,8.666,0,0,1-4.682-15.951,10.37,10.37,0,0,0,10.1,12.672c.326,0,.652-.014.971-.045.135-.012.284-.029.484-.057a8.674,8.674,0,0,1-6.065,3.344C15239.228,17414.674,15238.955,17414.688,15238.675,17414.688Z"
            transform="translate(-15213.727 -17373.344)"
            fill="#fff"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
            opacity="0.2"
        />
        <path id="Dust" d="M13.288,30.159a1,1,0,1,1-1-1,1,1,0,0,1,1,1" transform="translate(-0.02)" fill="#fff" />
        <path
            id="Dust_02"
            data-name="Dust 02"
            d="M47.734,35.553a1,1,0,1,1-1-1,1,1,0,0,1,1,1"
            transform="translate(-0.02)"
            fill="#fff"
        />
        <path
            id="Glimmer"
            d="M18.734,16.67a8.74,8.74,0,0,0-2.4-2.88,8.726,8.726,0,0,0,2.4-2.879,8.726,8.726,0,0,0,2.4,2.879,8.74,8.74,0,0,0-2.4,2.88"
            transform="translate(-0.02)"
            fill="#fff"
            opacity="0.3"
        />
        <path
            id="Glimmer_02"
            data-name="Glimmer 02"
            d="M49.927,15.95a15.286,15.286,0,0,0-4.193-5.039,15.286,15.286,0,0,0,4.193-5.039,15.286,15.286,0,0,0,4.193,5.039,15.286,15.286,0,0,0-4.193,5.039"
            transform="translate(-0.02)"
            fill="#fff"
            opacity="0.5"
        />
        <path
            id="Circle_03"
            data-name="Circle 03"
            d="M47.63,22.026A11.351,11.351,0,1,1,35.279,11.773,11.351,11.351,0,0,1,47.63,22.026"
            transform="translate(-0.02)"
            fill="#fff"
        />
        <path
            id="Circle_02"
            data-name="Circle 02"
            d="M40.722,26.739a10.369,10.369,0,1,1-11.283-9.366,10.369,10.369,0,0,1,11.283,9.366"
            transform="translate(-0.02)"
            fill="#fff"
            opacity="0.5"
        />
        <path
            id="Check"
            d="M34.94,26.532a1,1,0,0,1-.712-.3l-2.4-2.433a1,1,0,1,1,1.424-1.4l1.676,1.7,4.794-5.034a1,1,0,0,1,1.448,1.379l-5.505,5.781a1,1,0,0,1-.715.31Z"
            transform="translate(-0.02)"
            fill="rgb(var(--primaryRGB))"
        />
    </svg>
);
