/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HeartsExplode } from '../ExplodeAnimations';
import { common } from '../../helpers';
import { statisticsBodyClasses } from '../../actions';
import DynamicCoacherAvatar from '../DynamicMedia/DynamicCoacherAvatar';

export const TeacherMessage = (props) => {
    const { message = [], alt = '', delay = 800, bottom = true, top = false, correctAnswerRate = false } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [messageReceived, setMessageReceived] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const coacherAvatar = useSelector((state) => state.tenant.tenantCoacherAvatar);

    useEffect(() => {
        const timer = {};
        setMessageReceived(false);

        // To disable horizontal and vertical scrollbars when hearts animations zoom in
        dispatch(statisticsBodyClasses('overflow-hidden'));

        timer.messageTimer = window.setTimeout(() => {
            setMessageReceived(true);
        }, delay + 500);

        timer.animateTimer = window.setTimeout(() => {
            setAnimationClass('teacher-results-wrapper-animate');
        }, delay + 2500);

        timer.bodyClassTimer = window.setTimeout(() => {
            // Remove overflow-hidden from the body after hearts animation
            dispatch(statisticsBodyClasses(''));
        }, delay + 3500);

        return () => {
            window.clearTimeout(timer.messageTimer);
            window.clearTimeout(timer.animateTimer);
            window.clearTimeout(timer.bodyClassTimer);
        };
    }, []);

    return (
        <div className={`teacher teacher__results-wrapper ${animationClass}`}>
            <Fade bottom={bottom} duration={1000}>
                <span className="teacher__avatar">
                    <DynamicCoacherAvatar
                        path={coacherAvatar}
                        alt={alt || t('avatar_image')}
                        className="teacher__avatar-image"
                    />
                </span>
            </Fade>

            <Fade bottom={bottom} top={top} delay={delay}>
                <span className="teacher__bubble">
                    <span className="teacher__bubble-text">
                        <span>{typeof message === 'string' ? common.sanitizeString(message) : message}</span>
                    </span>
                    {correctAnswerRate && messageReceived && <HeartsExplode />}
                </span>
            </Fade>
        </div>
    );
};
