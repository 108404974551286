/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

function UserInviteModalBody({ onCancel, onInvite, inviteEmailRef, error }) {
    return (
        <form className="needs-validation user-edit-form" id="userEditForm" noValidate>
            <div className="row col-12 d-flex justify-content-center">
                <div className="form-group col-8">
                    <input
                        autoComplete="off"
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        name="email"
                        placeholder="user@example.com"
                        ref={inviteEmailRef}
                        required
                    />
                    <div className="invalid-feedback">Email is required</div>
                </div>
                <div style={{ marginBottom: 15 }}>Invite multiple users by separating email addresses by comma</div>
                {error !== '' && (
                    <div className="form-group col-8 d-flex justify-content-center">
                        <div>{error}</div>
                    </div>
                )}
                <div
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    className="form-group col-8 d-flex justify-content-center"
                >
                    <button type="submit" className="btn btn-pop-save btn-md mr-4" onClick={onInvite}>
                        Invite
                    </button>
                    <button
                        type="button"
                        className="btn btn-md text-uppercase btn-pop-cancel btn-suggest-edits-pop"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    );
}

UserInviteModalBody.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onInvite: PropTypes.func.isRequired,
    inviteEmailRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
        .isRequired,
    error: PropTypes.string,
};

UserInviteModalBody.defaultProps = {
    error: '',
};

export default UserInviteModalBody;
