/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseRoutes, StaticRoutes } from '../../../constants';
import { levelService } from '../../../services/level';
import { adminRoles } from '../../../constants/user';
import { getTenantProperties } from '../../../services/tenant-properties.service';
import RestartGameIcon from '../../Icons/RestartGameIcon';

const Menu = () => {
    const [spinClass, setSpinClass] = useState('spin-forward');
    const user = useSelector((state) => state.authentication.user);
    const ongoingLevel = useSelector((state) => state.level);
    const [showRestartGameConfirm, setShowRestartGameConfirm] = useState(false);
    const [tenantProperties, setTenantProperties] = useState(null);
    let isAdmin = false;
    const history = useHistory();
    const location = useLocation();
    const { levelId, roomId } = useParams();

    if (typeof user !== 'undefined' && adminRoles.includes(user.role_id)) {
        isAdmin = true;
    }

    function closeModal() {
        setTimeout(() => {
            setShowRestartGameConfirm(false);
        }, 200);
    }

    function showModal() {
        setShowRestartGameConfirm(true);
        setSpinClass('spin-forward');
    }

    function handleRestartGame() {
        setShowRestartGameConfirm(false);
        levelService
            .restartLevel(ongoingLevel.levelId, ongoingLevel.attemptId)
            .then((response) => {
                if (response.success) {
                    history.goBack();

                    const timer = setTimeout(() => {
                        history.replace(location.pathname);
                    }, 100);

                    return () => clearTimeout(timer);
                }
            })
            .catch(() => {});
    }

    useEffect(() => {
        getTenantProperties().then((result) => {
            if (result.status === 200) {
                setTenantProperties(result.data);
            }
        });
    }, []);

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="header__dropdown-btn" />
                <Dropdown.Menu>
                    {history.location.pathname !== BaseRoutes.OVERVIEW && (
                        <Link className="dropdown-item" to={BaseRoutes.OVERVIEW}>
                            <Trans i18nKey="go_to_overview" />
                        </Link>
                    )}
                    {ongoingLevel.levelId !== 0 && levelId && roomId && (
                        <Dropdown.Item href="#" onClick={showModal}>
                            <Trans i18nKey="restart_game" />
                        </Dropdown.Item>
                    )}
                    {isAdmin && (
                        <Dropdown.Item href={StaticRoutes.ADMIN_PANEL_URL}>
                            <Trans i18nKey="admin_panel" />
                        </Dropdown.Item>
                    )}
                    {tenantProperties && tenantProperties.brand_portal_url !== '' && (
                        <Dropdown.Item href={tenantProperties.brand_portal_url}>
                            <Trans i18nKey="exit_brand_learning" />
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item href={StaticRoutes.LOGOUT_URL}>
                        <Trans i18nKey="logout" />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal
                show={showRestartGameConfirm}
                onHide={closeModal}
                backdrop="static"
                className="restart-game"
                keyboard={false}
            >
                <Modal.Body>
                    <RestartGameIcon className={spinClass} />
                    <div className="restart-game__content text-center">
                        <span className="restart-game__content__header">
                            <Trans i18nKey="restart_conformation" />
                        </span>
                        <p>
                            <Trans i18nKey="restart_conformation_message" />
                        </p>
                        <div className="restart-game__content__buttons">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    setSpinClass('spin-reverse');
                                    closeModal();
                                }}
                            >
                                <Trans i18nKey="no" />
                            </button>
                            <button type="button" className="btn btn-outline-primary" onClick={handleRestartGame}>
                                <Trans i18nKey="yes" />
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Menu;
