/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Trans } from 'react-i18next';
import { Achievement } from '../../../components/Achievements';

export function AchievementStatus({ tenantAchievements }) {
    const processedTenantAchievements = tenantAchievements.reverse();

    return (
        <div className="card achievements-status-card stat-card">
            <div className="stat-card__title">
                <Trans i18nKey="achieved_titles" />
            </div>
            <div className="stat-card__content stat-card__content--space-between stat-card__content--margin-y">
                {processedTenantAchievements.map((achievement, index) => (
                    <div key={index} className="players-achievements-card__achievement-badge">
                        <Achievement
                            type={achievement.name}
                            percentage={achievement.percentage}
                            locked={achievement.locked}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
