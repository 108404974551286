/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Trans, useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { Accordion } from 'react-bootstrap';
import { TeacherMessage } from '../../components/Stats/TeacherMessage';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { attemptService } from '../../services/attempt';
import QuestionCardReview from '../../components/Room/Cards/QuestionCardReview/QuestionCardReview';
import { BaseRoutes, passRates, statistics } from '../../constants';
import { ProfileCard } from '../../components/ProfileCard';
import { setOngoingLevel, stopLoading } from '../../actions';
import { Loader } from '../../components/Loader';
import { common } from '../../helpers';
import { levelService } from '../../services/level';
import animationData from './BackgroundAnimationData.json';
import { setRoomDetails } from '../../actions/roomDetails.action';
import useScreenWidth from '../../hooks/useScreenWidth/useScreenWidth';

function Statistics({ match }) {
    const { roomId, levelId } = match.params;
    const [feedback, setFeedback] = useState([]);
    const dispatch = useDispatch();
    const deviceWidth = useScreenWidth();
    const questionsData = useSelector((state) => state.statistics);
    const statisticsClasses = useSelector((state) => state.statisticsClasses);
    const userInfo = useSelector((state) => state.authentication.user);
    const preview = useSelector((state) => state.preview);
    const [nextLevelFeedback, setNextLevelFeedback] = useState({});
    const [restartGameButtonStyle, setRestartGameButtonStyle] = useState('btn-outline-primary');
    const [nextButtonStyle, setNextButtonStyle] = useState('btn-outline-primary');
    const [trainerMessage, setTrainerMessage] = useState([]);
    const [correctAnswerRateState, setCorrectAnswerRateState] = useState(0);
    const [holdCoinsNewValueSet, setHoldCoinsNewValueSet] = useState(true);
    const [levelResultsReceived, setLevelResultsReceived] = useState(false);
    const [sideBarAnimateDone, setSideBarAnimateDone] = useState(false);
    const [nextGameUrl, setNextGameUrl] = useState(null);
    const [nextButtonLabel, setNextButtonLabel] = useState('');
    const [profileAreaLeftValue, setProfileAreaLeftValue] = useState(deviceWidth * 0.75);
    const [profileSidebarStyle, setProfileSidebarStyle] = useState({});

    let restartGameUrl = `${BaseRoutes.ROOMS}/${roomId}${BaseRoutes.LEVELS}/${levelId}${BaseRoutes.QUESTION}`;

    if (preview.isPreview) {
        restartGameUrl = `${BaseRoutes.PREVIEW}${BaseRoutes.ROOMS}/${roomId}${BaseRoutes.LEVELS}/${levelId}${BaseRoutes.QUESTION}`;
    }

    const history = useHistory();
    const { t } = useTranslation();

    const backgroundAnimationOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    useEffect(() => {
        const internalProfileWidth = document.getElementById('final-result-content-row').offsetWidth;
        const finalResultContentRowLeft = document.getElementById('final-result-content-row').offsetLeft;

        const profileAreaLeft = internalProfileWidth * 0.75 + finalResultContentRowLeft;

        if (deviceWidth >= 1200 && sideBarAnimateDone) {
            setProfileSidebarStyle({
                left: profileAreaLeft,
            });
        } else if (deviceWidth < 1200 && sideBarAnimateDone) {
            setProfileSidebarStyle({
                left: '100%',
            });
        }
    }, [deviceWidth, sideBarAnimateDone]);

    useEffect(() => {
        if (common.isEmptyObject(questionsData) && feedback.length <= 0) {
            history.push(BaseRoutes.HOME);
        }

        let correctAnswerCount = 0;
        dispatch(setOngoingLevel(0, 0, 0));

        attemptService
            .getFinalResult(questionsData.attemptId)
            .then((feedbackResponse) => {
                setFeedback(feedbackResponse);

                feedbackResponse.map((answer) => {
                    if (answer.isCorrect) {
                        correctAnswerCount++;
                    }

                    if (answer.allCorrect) {
                        answer.correctAnswer = t('any_combination_is_correct_response');
                    }
                });

                const correctAnswerRate = (correctAnswerCount / feedbackResponse.length) * 100;
                setCorrectAnswerRateState(correctAnswerRate);

                if (correctAnswerRate >= passRates.MIN_PASS_RATE && correctAnswerRate < passRates.GOOD_PASS_RATE) {
                    const fiftyPercentMessage = [
                        <Trans i18nKey="good_job_lets_make_one_more_attempt" />,
                        <Trans i18nKey="nice_job_lets_have_one_more_try" />,
                        <Trans i18nKey="nice_job_lets_have_one_more_try" />,
                    ];
                    setTrainerMessage(fiftyPercentMessage[Math.floor(Math.random() * fiftyPercentMessage.length)]);
                } else if (
                    correctAnswerRate >= passRates.GOOD_PASS_RATE &&
                    correctAnswerRate < passRates.EXCELLENT_PASS_RATE
                ) {
                    const seventyFivePercentMessage = [
                        <Trans i18nKey="great_job" />,
                        <Trans i18nKey="excellent_job" />,
                        <Trans i18nKey="superb_job" />,
                    ];
                    setTrainerMessage(
                        seventyFivePercentMessage[Math.floor(Math.random() * seventyFivePercentMessage.length)],
                    );
                } else if (correctAnswerRate >= passRates.EXCELLENT_PASS_RATE) {
                    const hundredPercentMessage = [
                        <Trans i18nKey="excellent_you_made_it" />,
                        <Trans i18nKey="superb_you_did_it" />,
                        <Trans i18nKey="outstanding_you_are_triumphant" />,
                    ];
                    setTrainerMessage(hundredPercentMessage[Math.floor(Math.random() * hundredPercentMessage.length)]);
                } else {
                    setTrainerMessage(
                        <Trans
                            i18nKey="try_again_you_need_more_answers"
                            values={{ answer_rate_to_gain_coins: statistics.RIGHT_ANSWERS_RATE_TO_GAIN_COINS }}
                        />,
                    );
                }

                levelService
                    .getLevelPosition(questionsData)
                    .then((result) => {
                        setNextLevelFeedback(result);

                        if (result && result.nextUnlockedLevelId) {
                            // profile block populate when level passed and coins are increased
                            populateProfileBlock(correctAnswerRate, result);
                            setNextButtonStyle('btn-primary');
                            preview.isPreview ? setPreviewGamePlayNext(result) : setNormalGamePlayNext(result);
                        } else if (result) {
                            setRestartGameButtonStyle('btn-primary');
                            populateProfileBlock(correctAnswerRate, result);
                        } else {
                            setRestartGameButtonStyle('btn-primary');
                            setNextGameUrl(null);
                        }
                    })
                    .then(() => {
                        setHoldCoinsNewValueSet(false);
                    });
            })
            .then(() => {
                dispatch(stopLoading());
            });
    }, []);

    const setPreviewGamePlayNext = (levelResult) => {
        if (levelResult.nextRoomId) {
            setNextGameUrl(null);
        } else {
            setNextButtonLabel(t('next_level'));
            setNextGameUrl(
                `${BaseRoutes.PREVIEW}${BaseRoutes.ROOMS}/${roomId}${BaseRoutes.LEVELS}/${levelResult.nextUnlockedLevelId}${BaseRoutes.QUESTION}`,
            );
        }
    };

    const setNormalGamePlayNext = (levelResult) => {
        if (levelResult.nextRoomId) {
            setNextButtonLabel(t('next_room'));
            setNextGameUrl(`${BaseRoutes.ROOMS}/${levelResult.nextRoomId}${BaseRoutes.LEVELS}`);
        } else {
            setNextButtonLabel(t('next_level'));
            setNextGameUrl(
                `${BaseRoutes.ROOMS}/${roomId}${BaseRoutes.LEVELS}/${levelResult.nextUnlockedLevelId}${BaseRoutes.QUESTION}`,
            );
        }
    };

    const populateProfileBlock = (correctAnswerRate, result) => {
        if (correctAnswerRate >= 75 && userInfo.achieved_coins < result.achievedCoins) {
            const animationStartTimer = setTimeout(() => {
                setLevelResultsReceived(true);

                const animationEndTimer = setTimeout(() => {
                    setLevelResultsReceived(false);
                    setSideBarAnimateDone(true);
                }, 6000);

                return () => clearTimeout(animationEndTimer);
            }, 5000);

            return () => clearTimeout(animationStartTimer);
        }
    };

    return (
        <>
            {/* eslint-disable-next-line no-restricted-globals */}
            <div className={`backdrop ${levelResultsReceived && 'backdrop-show'}`} />
            <Loader />
            <section className={`body ${statisticsClasses.classes}`}>
                <div className="container statistics__container">
                    <Breadcrumb path={questionsData.roomName} />
                    <section className="statistics question-wrap d-flex flex-column justify-content-center">
                        <div id="final-result-content-row" className="row">
                            {/* eslint-disable-next-line no-restricted-globals */}
                            <div
                                className={`animated-col ${deviceWidth >= 1200 && sideBarAnimateDone ? 'col-9' : 'col-12'}`}
                            >
                                <div className="statistics__background_glitter_animation">
                                    <Lottie options={backgroundAnimationOptions} height={725} width={985} />
                                </div>

                                {feedback && feedback.length > 0 && (
                                    <TeacherMessage
                                        addClass="mx-auto slide-from-top"
                                        message={trainerMessage}
                                        correctAnswerRate={
                                            correctAnswerRateState >= statistics.RIGHT_ANSWERS_RATE_TO_GAIN_COINS
                                        }
                                        alt={t('avatar_image')}
                                    />
                                )}

                                <div className="statistics__wrapper">
                                    <Accordion>
                                        {feedback &&
                                            feedback.map((fb, idx) => (
                                                <Accordion.Item eventKey={idx} key={idx}>
                                                    <Fade bottom>
                                                        <QuestionCardReview
                                                            questionNumber={idx + 1}
                                                            buttonProps={null}
                                                            questionTitle={fb.question.replace(/\*/g, '_')}
                                                            correctAnswer={fb.correctAnswer}
                                                            status={fb.isCorrect}
                                                            questionType={fb.question_type}
                                                            media={fb.media}
                                                            animeDelay={idx < 4 ? idx * 1000 : 500}
                                                        />
                                                    </Fade>
                                                </Accordion.Item>
                                            ))}
                                    </Accordion>
                                </div>

                                <div className="text-center statistics__buttons-wrapper mt-3 mb-10 ">
                                    {feedback && feedback.length > 0 && !common.isEmptyObject(nextLevelFeedback) && (
                                        <Fade>
                                            <div className="statistics__button-wrapper">
                                                <Link className="btn btn-outline-primary" to={BaseRoutes.OVERVIEW}>
                                                    <Trans i18nKey="go_to_overview" />
                                                </Link>
                                                <Link className={`btn ${restartGameButtonStyle}`} to={restartGameUrl}>
                                                    <Trans i18nKey="restart_game" />
                                                </Link>

                                                {nextGameUrl && (
                                                    <Link className={`btn ${nextButtonStyle}`} to={nextGameUrl}>
                                                        {nextButtonLabel}
                                                    </Link>
                                                )}
                                                <br />
                                                <br />
                                            </div>
                                        </Fade>
                                    )}
                                </div>
                            </div>
                            <div id="final-result-profile-sidebar-wrapper" className="pb-sidebar__wrapper">
                                <div
                                    id="final-result-profile-sidebar"
                                    className={`sb pb-sidebar ${levelResultsReceived && 'pb-sidebar-animate'} ${
                                        sideBarAnimateDone && 'pb-sidebar-animate-done'
                                    }`}
                                    style={profileSidebarStyle}
                                >
                                    <ProfileCard
                                        canUpdateUser={levelResultsReceived}
                                        holdCoinsNewValueSet={holdCoinsNewValueSet}
                                        displayPoweredBy={false}
                                        showCoinsCountUpAnimation
                                        isResultScreen
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    );
}

export { Statistics };
