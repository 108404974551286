/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { roomDetails as roomDetailsTypes } from '../constants';

const initialState = {
    roomInfo: {},
};

export function roomDetails(state = initialState, action) {
    switch (action.type) {
        case roomDetailsTypes.SET_ROOM_DETAILS:
            return action.payload;

        default: {
            return state;
        }
    }
}
