/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import { OverviewLoadingScreen } from '../../components/OverviewLoadingScreen';
import { LevelSelect } from '../LevelSelect';
import { Overview } from '../Overview';
import { BaseRoutes, errorRoutes } from '../../constants';
import { PreviewRoute, PrivateRoute } from '../../components/Utils';
import Question from '../Question';
import { Statistics } from '../Statistics/Statistics';
import { MessageAlert } from '../../components/MessageAlert';
import { TenantsStatsOverview } from '../StatsOverview/TenantsStatsOverview';
import QuestionPreview from '../Preview/Question/QuestionPreview';
import LearningContentPreview from '../Preview/Question/LearningContentPreview';
import CompleteUserSetup from '../CompleteUserSetup';
import { Error404 } from '../Errors/Error404';
import { Loader } from '../../components/Loader';
import { Shop } from '../Shop/Shop';
import { UserPermissions } from '../../constants/user';

function App() {
    const { pathname } = useLocation();

    return (
        <div className="wrapper">
            <MessageAlert />
            <Loader />
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

                <Route path={BaseRoutes.LOGIN} exact component={Login} />
                <Route path={BaseRoutes.HOME} exact component={OverviewLoadingScreen} />
                <Route path={BaseRoutes.FORGOT_PASSWORD} exact component={ForgotPassword} />
                <Route path={BaseRoutes.COMPLETE_USER_SETUP} exact component={CompleteUserSetup} />

        <PrivateRoute path={BaseRoutes.OVERVIEW} component={Overview} />
        <PrivateRoute path={BaseRoutes.ROOMS} exact component={Overview} />
        <PrivateRoute path={`${BaseRoutes.ROOMS}/:roomId${BaseRoutes.LEVELS}`} exact component={LevelSelect} permission={UserPermissions.PLAY_GAME_ROOMS} />
        <PrivateRoute path={BaseRoutes.STATS_OVERVIEW} component={TenantsStatsOverview} />
        <PrivateRoute
          path={`${BaseRoutes.ROOMS}/:roomId${BaseRoutes.LEVELS}/:levelId${BaseRoutes.STATISTICS}`}
          component={Statistics}
          permission={UserPermissions.PLAY_GAME_ROOMS}
        />
        <PrivateRoute
          path={`${BaseRoutes.ROOMS}/:roomId${BaseRoutes.LEVELS}/:levelId${BaseRoutes.QUESTION}`}
          component={Question}
          permission={UserPermissions.PLAY_GAME_ROOMS}
        />
        <PrivateRoute path={BaseRoutes.SHOP} component={Shop} />

        {/* Preview routes */}
        <PreviewRoute
          path={`${BaseRoutes.PREVIEW}${BaseRoutes.ROOMS}/:roomId${BaseRoutes.LEVELS}`}
          exact
          component={LevelSelect}
          permission={UserPermissions.READ_GAME_ROOMS}
        />
        <PreviewRoute
          path={`${BaseRoutes.PREVIEW}${BaseRoutes.LEARNING_CONTENT}/:contentId`}
          component={LearningContentPreview}
          permission={UserPermissions.READ_GAME_ROOMS}
        />
        <PreviewRoute
          path={`${BaseRoutes.PREVIEW}${BaseRoutes.ROOMS}/:roomId${BaseRoutes.LEVELS}/:levelId${BaseRoutes.QUESTION}`}
          exact
          component={Question}
          permission={UserPermissions.READ_GAME_ROOMS}
        />
        <PreviewRoute
          path={`${BaseRoutes.PREVIEW}${BaseRoutes.ROOMS}/:roomId${BaseRoutes.LEVELS}/:levelId${BaseRoutes.QUESTION}/:questionType/:questionId`}
          component={QuestionPreview}
          permission={UserPermissions.READ_GAME_ROOMS}
        />
        <PreviewRoute
          path={`${BaseRoutes.PREVIEW}${BaseRoutes.ROOMS}/:roomId${BaseRoutes.LEVELS}/:levelId${BaseRoutes.STATISTICS}`}
          component={Statistics}
          permission={UserPermissions.READ_GAME_ROOMS}
        />
        {/* End preview routes */}

                <Route path={errorRoutes.NOT_FOUND} component={Error404} />
                <Route path="*">
                    <Error404 />
                </Route>
            </Switch>
        </div>
    );
}

export { App };
