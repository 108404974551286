/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Trans } from 'react-i18next';

export function AllTimeCoins(props) {
    const { total, currency } = props;

    return (
        <>
            <div className="card shop__card card__all-time-coins">
                <div className="card__title">
                    <Trans i18nKey="all_time_earnings" />
                </div>
                <div className="card__content">
                    <div className="content_left"></div>
                    <div className="content_right">
                        <span className="all-time-coins">{total}</span>
                        <span className="shop__currency">{currency}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
