/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';
import QuestionContent from '../QuestionContent/QuestionContent';
import MQOptionWithImage from '../../../components/MQOption/MQOptionWithImage';
import MQOptionWithoutImage from '../../../components/MQOption/MQOptionWithoutImage';

function MultipleChoice(props) {
    const { question, changeEvent, selectedAnswers } = props;
    const { t } = useTranslation();

    return (
        <>
            <Fade bottom duration={500}>
                <QuestionContent key={question.questionId} {...question} />

                <div className="row multiple-answer">
                    {question.answers.map((qAnswer, idx) => (
                        <div key={idx} className="col">
                            {question.hasAnswerMedia ? (
                                <MQOptionWithImage
                                    answer={qAnswer}
                                    changeEvent={changeEvent}
                                    alt={t('multiple_question_option_image')}
                                    isChecked={selectedAnswers && selectedAnswers[qAnswer.answerId]}
                                />
                            ) : (
                                <MQOptionWithoutImage
                                    answer={qAnswer}
                                    changeEvent={changeEvent}
                                    isChecked={selectedAnswers && selectedAnswers[qAnswer.answerId]}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </Fade>
        </>
    );
}

export { MultipleChoice };
