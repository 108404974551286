import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DynamicCoacherAvatar = ({
  path, alt, classNames, ...rest
}) => {
  const [isFileMissing, setIsFileMissing] = useState(false);

  useEffect(() => {
    setIsFileMissing(false);
  }, [path]);

  const onError = () => {
    setIsFileMissing(true);
  };

  return (
    <>
      {isFileMissing ? <img src="/frontend/images/avatar.png" alt={alt} className={classNames} />
        : (
          <img
            src={path}
            onError={onError}
            alt={alt}
            className={classNames}
            {...rest}
          />
        )}
    </>
  );
};

export default DynamicCoacherAvatar;

DynamicCoacherAvatar.propTypes = {
  path: PropTypes.string.isRequired,
  alt: PropTypes.string,
  classNames: PropTypes.string,
};

DynamicCoacherAvatar.defaultProps = {
  alt: 'image',
  classNames: '',
};
