/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const CloseCircle = ({ size = 40, color = 'rgb(var(--primaryRGB))', onClickCallBack }) => (
    <svg
        id="UI_-_Button-Close"
        data-name="UI - Button-Close"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 40 40"
        onClick={onClickCallBack}
    >
        <circle id="BG_White" data-name="BG White" cx="20" cy="20" r="20" fill="#fff" />
        <circle id="Key" cx="20" cy="20" r="20" fill={color} opacity="0.15" />
        <g id="icon-navigation-close_24px" transform="translate(8 8)">
            <rect id="Boundary" width="24" height="24" fill="none" />
            <line
                id="Line_71"
                data-name="Line 71"
                x1="12"
                y1="12"
                transform="translate(6 6)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeWidth="2"
            />
            <line
                id="Line_72"
                data-name="Line 72"
                y1="12"
                x2="12"
                transform="translate(6 6)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);
