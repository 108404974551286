import { client, responseHelper } from '../../helpers';

export const profileService = {
    updateProfileImage,
};

function updateProfileImage(file, originalImageName) {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const data = new FormData();

    data.append('image', file, originalImageName);

    return client
        .post('/profile/profile-image', data, config)
        .then(responseHelper.formatResponse)
        .catch(responseHelper.error);
}
