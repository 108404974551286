/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import ActionBar from './ActionBar';
import UserTable from './UserTable';
import UserInviteModal from './UserInviteModal';
import { getAllUsers } from '../../../services/users/list.service';
import { inviteUserWithEmail } from '../../../services/users/invite.service';
import DeleteUserModal from './DeleteUserModal/DeleteUserModal';
import { loggedUserInfo, startLoading, stopLoading } from '../../../actions';
import AdminLoader from '../../Admin/Loader/AdminLoader';
import AdminAlert from '../../Admin/MessageAlert/AdminAlert';
import { userService } from '../../../services/user';

function UserManagement() {
    const dispatch = useDispatch();
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [inviteEmailError, setInviteEmailError] = useState('');
    const [error, setError] = useState('');
    const [users, setUsers] = useState([]);

    const get = () => {
        dispatch(startLoading());
        getAllUsers()
            .then((response) => {
                if (response.status !== 200) {
                    setError(response.data.message);
                }
                setUsers(response.data.data);
            })
            .catch((apiError) => {
                setError(apiError);
            })
            .then(() => {
                dispatch(stopLoading());
            });
    };

    const inviteEmailRef = createRef();
    const onInvite = (event) => {
        event.preventDefault();

        inviteUserWithEmail(inviteEmailRef.current.value)
            .then((response) => {
                if (response.status !== 201) {
                    setError(response.data.message);
                    setInviteEmailError(response.data.message);
                } else {
                    get();
                    setError();
                    setShowInviteModal(false);
                }
            })
            .catch((apiError) => {
                setError(apiError.response.data.message);
                setInviteEmailError(apiError.response.data.message);
            });
    };

    useEffect(() => {
        get();

        userService.getUser().then((user) => {
            dispatch(loggedUserInfo(user));
        });
    }, []);

    return (
        <div>
            <AdminLoader />
            <AdminAlert />
            <div className="wrapper">
                <div className="center-panel">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between rpb-gap">
                            <h1>User Management</h1>
                            <div>{error}</div>
                        </div>
                        <ActionBar onClickInvite={() => setShowInviteModal(true)} />
                        <UserTable users={users} />
                    </div>
                </div>
            </div>
            <UserInviteModal
                show={showInviteModal}
                onCancel={() => setShowInviteModal(false)}
                onInvite={onInvite}
                inviteEmailRef={inviteEmailRef}
                error={inviteEmailError}
            />
            <DeleteUserModal users={users} reloadUsers={get} />
        </div>
    );
}

export default UserManagement;
