/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { levelType } from '../../../constants';
import { BeginnerFace, ExpertFace, AdvancedFace } from '../../Icons';

export const LevelCard = (props) => {
    const { variant, children = '', type } = props;

    return (
        <div className={`card level-select ${variant}`}>
            {type === levelType.BEGINNER && <BeginnerFace />}
            {type === levelType.ADVANCED && <AdvancedFace />}
            {type === levelType.EXPERT && <ExpertFace />}
            {children}
        </div>
    );
};
