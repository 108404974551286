/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect } from 'react';
import '../../helpers/i18n';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Profile } from './Profile';
import { AllBadges } from './AllBadges';
import { defaultLoad, nextLevelCoinsInfo, userAction } from '../../actions';
import { nextLevelCoinsService } from '../../services/nextLevelCoins';

function ProfileCard(props) {
    const {
        fullScreen = false,
        actionButtons = false,
        holdCoinsNewValueSet = false,
        canUpdateUser = true,
        displayPoweredBy = true,
        showCoinsCountUpAnimation = false,
        isResultScreen = false,
    } = props;
    const profileActions = useSelector((state) => state.profile);
    let className = 'profile-card';
    const dispatch = useDispatch();

    useEffect(() => {
        if (canUpdateUser) {
            dispatch(userAction.updateUser());
        }

        dispatch(defaultLoad());

        nextLevelCoinsService.getNextLevelCoins().then((coins) => {
            dispatch(nextLevelCoinsInfo(coins));
        });
    }, [canUpdateUser]);

    if (fullScreen) {
        className = 'profile-card profile-card--greeting mx-auto';
    }

    return (
        <div className="profile-card__overflow-fix profile-card__sticky">
            {!profileActions.showAllClick && !profileActions.showFewerClick && (
                <Fade right duration={800} distance="30px">
                    <Profile
                        className={className}
                        holdCoinsNewValueSet={holdCoinsNewValueSet}
                        actionButtons={actionButtons}
                        showCoinsCountUpAnimation={showCoinsCountUpAnimation}
                    />
                </Fade>
            )}

            {!profileActions.showAllClick && profileActions.showFewerClick && (
                <Slide top>
                    <Profile
                        className={className}
                        holdCoinsNewValueSet={holdCoinsNewValueSet}
                        actionButtons={actionButtons}
                        showCoinsCountUpAnimation={showCoinsCountUpAnimation}
                    />
                </Slide>
            )}

            {profileActions.showAllClick && !profileActions.showFewerClick && (
                <Slide bottom distance="250px">
                    <AllBadges
                        enableOnlyThreeColumns={isResultScreen}
                        className={className}
                        actionButtons={actionButtons}
                    />
                </Slide>
            )}

            {displayPoweredBy && (
                <div className="profile-card__powered-by">
                    <Trans i18nKey="powered_by_only" />{' '}
                    <a about="_blank" rel="noopener noreferrer" href="https://debroome.com">
                        deBroome
                    </a>
                </div>
            )}
        </div>
    );
}

export { ProfileCard };
