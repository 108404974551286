/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import PropTypes from 'prop-types';
import React from 'react';
import { questionTypes } from '../../constants';
import DynamicImage from '../DynamicMedia/DynamicImage';

const MQOptionWithImage = ({ changeEvent, answer, alt, isChecked }) => (
    <div className="multiple-answer__item">
        <input
            type="checkbox"
            className="multiple-answer__choose"
            value="active"
            onChange={(evt) => changeEvent(evt, answer)}
            name={questionTypes.MULTIPLE_CHOICES}
            defaultChecked={isChecked}
        />
        <label className="card multiple-answer__item-inner" htmlFor="multiple-ans-1">
            {answer.answerMedia ? (
                <div className="card__img-wrp">
                    <DynamicImage
                        path={answer.answerMedia ? answer.answerMedia : '/frontend/images/room_image.png'}
                        classNames="card__img"
                        alt={alt}
                    />
                </div>
            ) : (
                <div className="card__img-wrp wh-image mqo" />
            )}

            <span className="multiple-answer__text">{answer.answer}</span>
        </label>
    </div>
);

export default MQOptionWithImage;

MQOptionWithImage.propTypes = {
    answer: PropTypes.object.isRequired,
    changeEvent: PropTypes.func.isRequired,
    alt: PropTypes.string.isRequired,
};
