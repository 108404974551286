import { level } from '../constants';

export const setOngoingLevel = (id = 0, attemptId = 0, roomId = 0) => ({
  type: level.ONGOING_LEVEL,
  payload: {
    levelId: id,
    attemptId,
    roomId,
  },
});
