/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

const RestartGameIcon = ({ className }) => (
    <div className="restart-game__icon text-center">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="95.559"
            height="102.677"
            viewBox="0 0 95.559 102.677"
        >
            <g id="grf" transform="translate(98.728 87.145) rotate(167)">
                <path
                    id="Path_383"
                    data-name="Path 383"
                    d="M35.137,37.6a39.252,39.252,0,0,0-4.2,17.717c0,22.075,18.2,39.971,40.662,39.971a41.1,41.1,0,0,0,17.141-3.714"
                    transform="translate(-30.935 -9.328)"
                    fill="none"
                    stroke="#fd6119"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="5"
                />
                <path
                    id="Path_384"
                    data-name="Path 384"
                    d="M7.679,20.434,13.471,6.142,0,0"
                    transform="matrix(0.998, 0.07, -0.07, 0.998, 45.089, 75.301)"
                    fill="none"
                    stroke="#fd6119"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="5"
                />
                <path
                    id="Path_385"
                    data-name="Path 385"
                    d="M58.882,76.392a39.43,39.43,0,0,0,8.694-35.971c-5.5-21.4-27.609-34.371-49.382-28.965A40.78,40.78,0,0,0,2.5,19.184"
                    transform="translate(12.509 -4.222)"
                    fill="none"
                    stroke="#fd6119"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="5"
                />
                <path
                    id="Path_386"
                    data-name="Path 386"
                    d="M1.85,0-.032,15.266,14.6,17.786"
                    transform="translate(16.138 0) rotate(7)"
                    fill="none"
                    stroke="#fd6119"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="5"
                />
            </g>
        </svg>
    </div>
);

RestartGameIcon.propTypes = {
    className: PropTypes.string.isRequired,
};

export default RestartGameIcon;
