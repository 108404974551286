/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Table(props) {
    const { headers, recordSet, refreshTable } = props;
    return !refreshTable ? (
        <table className="table table-panel mb-0">
            <thead>
                <tr>
                    {headers.map((header, index) =>
                        header !== '' ? (
                            <th key={index} scope="col" className="text-uppercase">
                                {header}
                            </th>
                        ) : (
                            <th key={index} scope="col">
                                {' '}
                            </th>
                        ),
                    )}
                </tr>
            </thead>
            <tbody>{recordSet}</tbody>
        </table>
    ) : (
        <div className="d-flex justify-content-center align-items-center w-100 h-25">
            <Spinner animation="border" />
        </div>
    );
}

export default Table;
