/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const Shop = ({ size = 20, color = 'rgb(var(--primaryRGB))' }) => (
    <svg
        id="Icon_-_Shop"
        data-name="Icon - Shop"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
    >
        <rect id="Rectangle_881" data-name="Rectangle 881" width="24" height="24" fill="rgba(211,211,211,0)" />
        <line
            id="Line_113"
            data-name="Line 113"
            x2="17"
            transform="translate(3.5 20.5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
            opacity="0"
        />
        <path
            id="shopping_cart_FILL0_wght400_GRAD0_opsz24"
            d="M7,22a2,2,0,1,1,1.412-3.413A2,2,0,0,1,7,22Zm10,0a2,2,0,1,1,1.413-.587A1.925,1.925,0,0,1,17,22ZM6.15,6l2.4,5h7L18.3,6ZM7,17a1.851,1.851,0,0,1-1.7-.988,1.889,1.889,0,0,1-.05-1.962L6.6,11.6,3,4H1.975a.926.926,0,0,1-.7-.288A.989.989,0,0,1,1,3,.982.982,0,0,1,2,2H3.625a1,1,0,0,1,.525.15.932.932,0,0,1,.375.425L5.2,4H19.95a.938.938,0,0,1,.925.5,1.118,1.118,0,0,1-.025,1.05l-3.55,6.4a2.035,2.035,0,0,1-.725.775A1.926,1.926,0,0,1,15.55,13H8.1L7,15H18.025a.928.928,0,0,1,.7.287A.993.993,0,0,1,19,16a.984.984,0,0,1-1,1Zm1.55-6h0Z"
            transform="translate(1)"
            fill={color}
        />
    </svg>
);
