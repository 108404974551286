/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

export default {
    getCookie: (cookieName) => {
        let cookieValue = document.cookie;
        let cookieStart = cookieValue.indexOf(` ${cookieName}=`);

        if (cookieStart === -1) {
            cookieStart = cookieValue.indexOf(`${cookieName}=`);
        }

        if (cookieStart === -1) {
            cookieValue = null;
        } else {
            cookieStart = cookieValue.indexOf('=', cookieStart) + 1;
            let cookieEnd = cookieValue.indexOf(';', cookieStart);

            if (cookieEnd === -1) {
                cookieEnd = cookieValue.length;
            }

            cookieValue = unescape(cookieValue.substring(cookieStart, cookieEnd));
        }

        return cookieValue;
    },

    setCookie: (cookieName, cookieValue, expiryDays) => {
        let expires = '';

        if (expiryDays !== null && expiryDays !== undefined) {
            const date = new Date();
            date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000);
            expires = `;expires=${date.toUTCString()}`;
        }

        document.cookie = `${cookieName}=${cookieValue};path=/${expires}`;
    },
};
