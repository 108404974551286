/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_URL } from '../../constants/appUrls';
import { previewSet } from '../../actions';
import { readOnlySet } from '../../actions/readOnlySet.action';
import { UserPermissions } from '../../constants/user';
import { BaseRoutes } from '../../constants';
import { useHasPermission } from '../../hooks/useHasPermission';

function PrivateRoute({ component: Component, roles, permission, ...rest }) {
    const authEnable = true;
    const loginUrl = LOGIN_URL || '/';
    const auth = useSelector((state) => state.authentication);
    const readOnlyMode = useSelector((state) => state.readOnlyMode);
    const dispatch = useDispatch();
    const hasPermission = useHasPermission(permission);

    useEffect(() => {
        dispatch(previewSet(0));
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (authEnable && !auth.valid_user) {
                    window.location.href = loginUrl;
                    return false;
                }

                if (permission && !hasPermission) {
                    window.location.href = `${BaseRoutes.OVERVIEW}`;
                }
                return <Component isPreview={false} {...props} />;
            }}
        />
    );
}

export { PrivateRoute };
