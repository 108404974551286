export const CommunityStatus = () => (
  <svg
    id="Icon_community-stats"
    className="community-stats-icon"
    data-name="Icon / community-stats"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect id="Rectangle_881" data-name="Rectangle 881" width="24" height="24" fill="rgba(211,211,211,0)" />
    <path id="Path_448" data-name="Path 448" d="M0,6.682V14.7" transform="translate(6 4.797)" fill="none" stroke="#fd6119" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_110" data-name="Line 110" y2="14" transform="translate(10 5.5)" fill="none" stroke="#fd6119" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_111" data-name="Line 111" y2="10" transform="translate(14 9.5)" fill="none" stroke="#fd6119" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_112" data-name="Line 112" y2="12" transform="translate(18 7.5)" fill="none" stroke="#fd6119" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_113" data-name="Line 113" x2="17" transform="translate(3.5 20.5)" fill="none" stroke="#fd6119" strokeLinecap="round" strokeWidth="2" opacity="0" />
  </svg>
);
