/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { App } from './src/modules/App';
import { history, store } from './src/helpers';
import UserManagement from './src/components/Pages/UserManagement';

if (document.getElementById('root')) {
    render(
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>,
        document.getElementById('root'),
    );
}

if (document.getElementById('user-management-dashboard')) {
    render(
        <Provider store={store}>
            <UserManagement />
        </Provider>,
        document.getElementById('user-management-dashboard'),
    );
}
