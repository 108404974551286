/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { languageLoadedActions } from '../constants';

const initialState = false;

export function languageLoadedReducer(state = initialState, action) {
    switch (action.type) {
        case languageLoadedActions.SET_LANGUAGE_LOADED_STATUS:
            return true;
        default: {
            return state;
        }
    }
}
