/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Modal } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { CloseCircle, GlitteringBox } from '../Icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export function MobileModal(properties) {
    const {
        show,
        modalImageNoBottom,
        modalPurchasedDone,
        modalImage,
        modalTitle,
        modalDescription,
        modalPrice,
        modalPriceCurrency,
        closeModalCallBack,
        okButtonClickCallBack,
        buyButtonClickCallBack,
    } = properties;

    return (
        <Modal
            show={show}
            fullscreen={true}
            onHide={closeModalCallBack}
            backdrop="static"
            className={`purchase-modal-mobile ${modalPurchasedDone ? 'modal-top' : ''}`}
            keyboard={false}
        >
            <Modal.Body>
                {!modalPurchasedDone && (
                    <div className="modal-close" onClick={closeModalCallBack}>
                        <CloseCircle />
                    </div>
                )}

                <div className="item__info">
                    {!modalPurchasedDone && (
                        <>
                            <div className={`item__image ${modalImageNoBottom ? 'item__image-no-bottom' : ''}`}>
                                <img src={modalImage} alt="" />
                            </div>
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={8}
                                className="product-images-mobile-screen"
                                freeMode={true}
                            >
                                {Array.from({ length: 10 })
                                    .fill()
                                    .map((_, index) => (
                                        <SwiperSlide key={index}>
                                            <img
                                                src={modalImage}
                                                alt=""
                                                className={modalImageNoBottom ? 'image-no-bottom' : ''}
                                            />
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                        </>
                    )}

                    {modalPurchasedDone && (
                        <Fade>
                            <div className="item__image item__purchased-done">
                                <GlitteringBox />
                            </div>
                        </Fade>
                    )}

                    <div className="item__purchase-info">
                        <div className="item__description">
                            <span className={`title ${modalPurchasedDone ? 'full-width' : ''}`}>{modalTitle}</span>
                            <p
                                className={`description description-mobile ${modalPurchasedDone ? 'full-width' : ''}`}
                                dangerouslySetInnerHTML={{ __html: modalDescription }}
                            />
                        </div>
                        {modalPrice && (
                            <div className="item__price">
                                <span className="shop__price">{modalPrice}</span>
                                <span className="shop__currency">{modalPriceCurrency}</span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="item__actions">
                    {modalPurchasedDone ? (
                        <Fade>
                            <button type="button" className="btn btn-primary btn-ok" onClick={okButtonClickCallBack}>
                                OK
                            </button>
                        </Fade>
                    ) : (
                        <>
                            <button
                                type="button"
                                className="btn btn-outline-primary btn-cancel"
                                onClick={closeModalCallBack}
                            >
                                CANCEL
                            </button>
                            <button type="button" className="btn btn-primary btn-buy" onClick={buyButtonClickCallBack}>
                                BUY
                            </button>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}
