/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import Fade from 'react-reveal/Fade';
import { Trans } from 'react-i18next';
import { AdvancedFace, BeginnerFace, ExpertFace } from '../../../components/Icons';
import { levelType } from '../../../constants';

function LevelWelcome(props) {
    const { difficulty } = props;

    return (
        <Fade bottom>
            <div className="feedback level-intro level-description start--animate">
                <div className="container">
                    <div className="feedback-inner">
                        <div className="level-welcome-loading">
                            <div>
                                {difficulty === levelType.BEGINNER && (
                                    <div className="start__anim-wrapper">
                                        <BeginnerFace baseClass="start__anim-level-ico" />
                                        <div className="start__anim-level-text">
                                            <Trans i18nKey="beginner" />
                                        </div>
                                    </div>
                                )}

                                {difficulty === levelType.ADVANCED && (
                                    <div className="start__anim-wrapper">
                                        <AdvancedFace baseClass="start__anim-level-ico" />
                                        <div className="start__anim-level-text">
                                            <Trans i18nKey="advanced" />
                                        </div>
                                    </div>
                                )}

                                {difficulty === levelType.EXPERT && (
                                    <div className="start__anim-wrapper">
                                        <ExpertFace baseClass="start__anim-level-ico" />
                                        <div className="start__anim-level-text">
                                            <Trans i18nKey="expert" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
}

export default LevelWelcome;
