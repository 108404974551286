/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const Login3DaysBadge = () => (
    <svg id="Badge-Login-3Days-Row" xmlns="http://www.w3.org/2000/svg" width="64" height="64.01" viewBox="0 0 64 64.01">
        <path
            id="Bg"
            d="M54,64.01H10a10,10,0,0,1-10-10v-44C.2,4.418,5.211-.241,10,.01H54a10,10,0,0,1,10,10v44a10,10,0,0,1-10,10"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="Screen"
            d="M53.2,52.337H11.283A3.04,3.04,0,0,1,8.242,49.3V19.879h48V49.3a3.04,3.04,0,0,1-3.04,3.04"
            fill="#fff"
            opacity="0.2"
        />
        <path id="Dust" d="M44.108,24.388a1.091,1.091,0,1,1-1.091-1.106,1.1,1.1,0,0,1,1.091,1.106" fill="#fff" />
        <path
            id="Glimmer_02"
            data-name="Glimmer 02"
            d="M17.306,41.648a9.644,9.644,0,0,0-2.614-3.185,9.63,9.63,0,0,0,2.614-3.184,9.63,9.63,0,0,0,2.614,3.184,9.644,9.644,0,0,0-2.614,3.185"
            fill="#fff"
            opacity="0.5"
        />
        <path
            id="Header"
            d="M56.243,17.667h-48V14.394a3.625,3.625,0,0,1,3.84-3.362H52.4a3.625,3.625,0,0,1,3.84,3.362Z"
            fill="#fff"
            opacity="0.6"
        />
        <path id="Head" d="M38.782,32.312a6.818,6.818,0,1,1-6.818-6.818,6.818,6.818,0,0,1,6.818,6.818" fill="#fff" />
        <path
            id="Body"
            d="M45.487,52.22v-1.6a8.02,8.02,0,0,0-8.041-8H26.482a8.02,8.02,0,0,0-8.041,8v1.6Z"
            fill="#fff"
        />
        <path
            id="Circel"
            d="M25.8,15.593a10.362,10.362,0,1,1-11.275-9.36A10.361,10.361,0,0,1,25.8,15.593"
            fill="#fff"
        />
        <path
            id="Gimmer"
            d="M46.4,42.256a16.841,16.841,0,0,0-4.575-5.573A16.851,16.851,0,0,0,46.4,31.11a16.851,16.851,0,0,0,4.575,5.573A16.841,16.841,0,0,0,46.4,42.256"
            fill="#fff"
            opacity="0.5"
        />
        <path
            id="_3"
            data-name="3"
            d="M18.714,13.644a2.249,2.249,0,0,1-.534,1.5,3.038,3.038,0,0,1-1.546.912V16.1q2.356.3,2.356,2.227a2.413,2.413,0,0,1-1.04,2.068,4.844,4.844,0,0,1-2.887.754,8.573,8.573,0,0,1-1.408-.109,7.527,7.527,0,0,1-1.4-.4V18.527a5.7,5.7,0,0,0,1.259.467,5.266,5.266,0,0,0,1.181.15,2.43,2.43,0,0,0,1.221-.234.8.8,0,0,0,.383-.739.829.829,0,0,0-.2-.595,1.306,1.306,0,0,0-.645-.315,5.181,5.181,0,0,0-1.159-.107h-.579V15.242h.592q1.938,0,1.938-.993a.607.607,0,0,0-.286-.552,1.425,1.425,0,0,0-.769-.182,3.533,3.533,0,0,0-1.869.606l-1.055-1.7a5.144,5.144,0,0,1,1.529-.734,6.626,6.626,0,0,1,1.794-.219,3.955,3.955,0,0,1,2.292.58,1.842,1.842,0,0,1,.831,1.6"
            fill="rgb(var(--primaryRGB))"
        />
        <rect id="Bound" width="64" height="64.01" fill="none" />
    </svg>
);
