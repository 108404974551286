/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from '../../../ModalWindow/ModalWindow';
import UserInviteModalBody from './UserInviteModalBody';

function UserInviteModal({ show, onCancel, onInvite, inviteEmailRef, error }) {
    const footer = (
        <small style={{ margin: 0, padding: 0 }}>
            Upon inviting users, users will receive a link to complete the setup of their Brand Learning account and
            accept your invitation to join your Brand Learning. They will be asked to provide their full name. The email
            will be sent from no-reply@brandlearning.app.
        </small>
    );

    return (
        <ModalWindow
            visibility={show}
            title="Invite user"
            body={
                <UserInviteModalBody
                    onInvite={onInvite}
                    onCancel={onCancel}
                    inviteEmailRef={inviteEmailRef}
                    error={error}
                />
            }
            footer={footer}
        />
    );
}

UserInviteModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onInvite: PropTypes.func.isRequired,
    inviteEmailRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
        .isRequired,
    error: PropTypes.string,
};

UserInviteModal.defaultProps = {
    error: '',
};

export default UserInviteModal;
