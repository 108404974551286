/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Favorite, School, Terrain, BusinessCenter, Star, EmojiEvents } from '@material-ui/icons/';

export const ProfileType = (props) => {
    const { label = 'Novice' } = props;
    const { t } = useTranslation();

    const icons = {
        novice: <Favorite style={{ color: 'rgb(var(--primaryRGB))' }} />,
        apprentice: <School style={{ color: 'rgb(var(--primaryRGB))' }} />,
        explorer: <Terrain style={{ color: 'rgb(var(--primaryRGB))' }} />,
        advocate: <BusinessCenter style={{ color: 'rgb(var(--primaryRGB))' }} />,
        professional: <Star style={{ color: 'rgb(var(--primaryRGB))' }} />,
        ambassador: <EmojiEvents style={{ color: 'rgb(var(--primaryRGB))' }} />,
    };

    return (
        <div className="profile-card__work-type">
            {icons[label]}
            <span className="profile-card__work-type-text"> {t(label)}</span>
        </div>
    );
};
