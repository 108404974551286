/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { Button } from '../../../components/Form/Button/Button';
import { mediaFiles, mediaTypes } from '../../../constants';
import { common } from '../../../helpers';
import { TeacherMessage } from '../../../components/Room/Teacher/Message/Message';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DynamicImage from '../../../components/DynamicMedia/DynamicImage';
import DynamicAudio from '../../../components/DynamicMedia/DynamicAudio';
import DynamicVideo from '../../../components/DynamicMedia/DynamicVideo';

function Content(props) {
    const { dismissHandler, content, roomAndLevelInfo } = props;
    const [mediaType, setMediaType] = useState(null);
    const [imageClass, setImageClass] = useState('');
    const { t } = useTranslation();
    const learningContent = useRef();
    const checkContentAvailable = content.file_path && content.learning_content ? '' : 'teacher__margin-bottom-0';

    useLayoutEffect(() => {
        if (learningContent.current) {
            learningContent.current.firstElementChild.classList.add('learning_content__text__margin-bottom-0');
        }
    });

    useEffect(() => {
        if (mediaFiles.IMAGE.includes(content.file_extension)) {
            const img = new Image();
            img.src = content.file_path;

            img.onload = function () {
                if (this.width > this.height) {
                    setImageClass('learning_content__landscape_image');
                } else {
                    setImageClass('learning_content__portrait_image');
                }
            };

            return setMediaType('image');
        }
        if (mediaFiles.AUDIO.includes(content.file_extension)) {
            return setMediaType('audio');
        }
        if (mediaFiles.VIDEO.includes(content.file_extension)) {
            return setMediaType('video');
        }
        return null;
    }, [content]);

    return (
        <div className="feedback level-description">
            <div className="container">
                <Breadcrumb
                    path={roomAndLevelInfo && `${roomAndLevelInfo.roomTitle} / ${t(roomAndLevelInfo.levelPrefix)}`}
                />

                <Fade bottom duration={500}>
                    <div className="feedback-inner">
                        <TeacherMessage
                            message=<Trans i18nKey="read_below_and_answer" />
                            delay={0}
                            addClass={checkContentAvailable}
                        />
                        <div className="learning_content">
                            <div className="learning_content__title">{content.name}</div>

                            {content.file_path && mediaType === mediaTypes.IMAGE && imageClass && (
                                <div className="center">
                                    <DynamicImage
                                        path={content.file_path}
                                        className={`learning_content__media ${imageClass}`}
                                        alt={t('learning_section_image')}
                                    />
                                </div>
                            )}
                            {content.file_path && mediaType === mediaTypes.AUDIO && (
                                <DynamicAudio
                                    path={content.file_path}
                                    className="learning_content__media"
                                    width="100%"
                                    height="65px"
                                    controls
                                />
                            )}
                            {content.file_path && mediaType === mediaTypes.VIDEO && (
                                <DynamicVideo
                                    className="learning_content__media video-player"
                                    path={content.file_path}
                                    width="100%"
                                    controls
                                />
                            )}
                            <div
                                className="learning_content__text"
                                ref={learningContent}
                                dangerouslySetInnerHTML={{
                                    __html: common.sanitizeHtmlContent(content.learning_content),
                                }}
                            />

                            <div className="learning_content__button">
                                <Button size="md" onClick={dismissHandler}>
                                    <Trans i18nKey="hard_quiz_title_screen_button" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
}

Content.propTypes = {
    content: PropTypes.shape({
        file_path: PropTypes.string,
        file_extension: PropTypes.string,
    }).isRequired,
    roomAndLevelInfo: PropTypes.shape({
        roomTitle: PropTypes.string.isRequired,
        levelPrefix: PropTypes.string.isRequired,
    }).isRequired,
};

export default Content;
