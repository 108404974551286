/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import './Button.css';

export const Button = (props) => {
    const {
        variant = 'btn-primary',
        size = 'sm',
        type = 'button',
        marginTop = '',
        handleClick = null,
        children,
        ...rest
    } = props;
    return (
        <button type={type} className={`btn ${variant} btn-${size} ${marginTop}`} onClick={handleClick} {...rest}>
            {children}
        </button>
    );
};
