/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { adminAlertMessage } from '../constants';

const initialState = {};

export function adminMessage(state = initialState, action) {
    switch (action.type) {
        case adminAlertMessage.SHOW_SUCCESS:
            return action.payload;

        case adminAlertMessage.SHOW_ERROR:
            return action.payload;

        case adminAlertMessage.RESET:
            return action.payload;

        default: {
            return state;
        }
    }
}
