/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useContext } from 'react';
import Fade from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import PropTypes from 'prop-types';
import { AccordionContext, useAccordionButton, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { common } from '../../../../helpers';
import Caret from '../../../Icons/Caret';
import { questionTypes } from '../../../../constants';
import DynamicImage from '../../../DynamicMedia/DynamicImage';

const ContextAwareToggle = ({ eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const collapseAnswer = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button type="button" className="statistics__additional-btn" onClick={collapseAnswer}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.4" viewBox="0 0 12 7.4">
                {isCurrentEventKey ? <Caret /> : <Caret down />}
            </svg>
        </button>
    );
};

const QuestionCardReview = (props) => {
    const { status, questionTitle, correctAnswer, animeDelay, questionNumber, questionType, media } = props;
    const { t } = useTranslation();

    return (
        <Fade>
            <div className="card statistics__item ">
                <div className="statistics__info-wrapper">
                    <Flip left delay={animeDelay}>
                        {status ? (
                            <span className="statistics__status-badge">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26.828"
                                    height="19.961"
                                    viewBox="0 0 26.828 19.961"
                                >
                                    <path
                                        d="M-1.758,10.163,5.53,17.548,22.242,0"
                                        transform="translate(3.172 1.414)"
                                        fill="none"
                                        stroke="#fd6120"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                </svg>
                            </span>
                        ) : (
                            <span className="statistics__status-badge statistics__status-badge--cross">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24.828"
                                    height="24.828"
                                    viewBox="0 0 24.828 24.828"
                                >
                                    <g transform="translate(-3.586 -3.586)">
                                        <line
                                            x1="22"
                                            y1="22"
                                            transform="translate(5 5)"
                                            fill="none"
                                            stroke="#2b2b2b"
                                            strokeLinecap="round"
                                            strokeWidth="2"
                                        />
                                        <line
                                            y1="22"
                                            x2="22"
                                            transform="translate(5 5)"
                                            fill="none"
                                            stroke="#2b2b2b"
                                            strokeLinecap="round"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>
                            </span>
                        )}
                    </Flip>
          <div className="statistics__info">
            <div className="answer__header">
              {`${t('question')} ${questionNumber}`}
            </div>
            {common.sanitizeString(questionTitle.replaceAll('[|#|]', ' '))}
            <div className="statistics__info_answers">
              <br />
                            <Accordion.Body>
                                <div className="answer__header">{t('correct_answer')}</div>
                                {questionType === questionTypes.MULTIPLE_CHOICES && media.length > 0 ? (
                                    <>
                                        {media.map((optionMedia, idx) => (
                                            <div className="row mq-container" key={idx}>
                                                <div className="col-md-12">
                                                    <DynamicImage
                                                        classNames="mq-container__thumbnail"
                                                        path={optionMedia.url}
                                                        alt={t('multiple_question_option_image')}
                                                    />
                                                    <p>{optionMedia.text}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <p>{common.sanitizeString(correctAnswer)}</p>
                                )}
                            </Accordion.Body>
                        </div>
                    </div>
                </div>
                <div className="statistics__additional-wrapper">
                    <ContextAwareToggle eventKey={questionNumber - 1} />
                </div>
            </div>
        </Fade>
    );
};

QuestionCardReview.propTypes = {
    status: PropTypes.number.isRequired,
    questionTitle: PropTypes.string.isRequired,
    correctAnswer: PropTypes.string.isRequired,
    questionType: PropTypes.number.isRequired,
    media: PropTypes.array.isRequired,
    animeDelay: PropTypes.number,
};

QuestionCardReview.defaultProps = {
    animeDelay: 0,
};

export default QuestionCardReview;
