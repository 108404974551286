export const questionConstants = {
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FETCHING_ERROR: 'FETCHING_ERROR',
};

export const questionTypes = {
  TRUE_FALSE: 1,
  FILL_IN_THE_BLANKS: 2,
  MULTIPLE_CHOICES: 3,
  SEQUENTIAL: 4,
};

export const questionDifficulty = {
  HARD: 'hard',
  SOFT: 'soft',
};

export const theme = {
  SET_THEME: 'SET_THEME',
};

export const game = {
  DEFAULT_GAME_ID: 1,
};

export const language = {
  DEFAULT: 'en',
};

export const loader = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
};

export const error = {
  SHOW_ERROR: 'SHOW_ERROR',
  HIDE_ERROR: 'HIDE_ERROR',
};

export const statistics = {
  ALLOW_ACCESS: 'ALLOW_ACCESS',
  AVOID_ACCESS: 'AVOID_ACCESS',
  BODY_CLASSES: 'BODY_CLASSES',
  RIGHT_ANSWERS_RATE_TO_GAIN_COINS: 80,
};

export const profile = {
  DEFAULT_PICTURE: '../../../../frontend/img/avatar.png',
  AVATAR_USER: '../../../../frontend/img/avatar-user.png',
  AVATAR_PLACEHOLDER: '../frontend/images/avatar-placeholder.svg',
};

export const rewards = {
  TOTAL_NO_OF_BADGES_PER_ROW: 4,
  TOTAL_NO_OF_BADGES_PER_ROW_FOR_SMALL_SCREEN: 3,
};

export const level = {
  ONGOING_LEVEL: 'ONGOING_LEVEL',
  DISMISS_LEARNING_CONTENT: 'DISMISS_LEARNING_CONTENT',
};

export const question = {
  SET_EFFECT: 'SET_EFFECT',
};

export const userInfo = {
  LOGGED_USER_INFO: 'LOGGED_USER_INFO',
  ACHIEVED_COINS: 'ACHIEVED_COINS',
};

export const nextLevel = {
  NEXT_LEVEL_COINS_REQUIRED: 'NEXT_LEVEL_COINS_REQUIRED',
};

export const timer = {
  HARD_LEARNING_TIMER: 'HARD_LEARNING_TIMER',
};

export const mediaTypes = {
  AUDIO: 'audio',
  IMAGE: 'image',
  VIDEO: 'video',
};

export const mediaFiles = {
  AUDIO: ['mp3'],
  IMAGE: ['jpg', 'jpeg', 'png'],
  VIDEO: ['mp4'],
};

export const roomDetails = {
  SET_ROOM_DETAILS: 'SET_ROOM_DETAILS',
};

export const sounds = {
  ON: false,
};

export const previewInfo = {
  SET_PREVIEW: 'SET_PREVIEW',
};

export const customMessageActions = {
  SET_CUSTOM_MESSAGES: 'SET_CUSTOM_MESSAGES',
};

export const passRates = {
  MIN_PASS_RATE: 50,
  GOOD_PASS_RATE: 80,
  EXCELLENT_PASS_RATE: 100,
};

export const languageLoadedActions = {
  SET_LANGUAGE_LOADED_STATUS: 'SET_LANGUAGE_LOADED_STATUS',
};

export const communityStatActions = {
  SET_COMMUNITY_STAT: 'SET_COMMUNITY_STAT',
};

export const buyItems = {
  ITEM_TO_BE_PURCHASED: 'ITEM_TO_BE_PURCHASED',
  SPENDABLE_COINS: 'SPENDABLE_COINS',
};

export const readOnlyModeActions = {
  SET_READ_ONLY_MODE: 'SET_READ_ONLY_MODE',
};
