/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const HeartsExplode = () => (
    <span className="hearts__explode">
        <svg xmlns="http://www.w3.org/2000/svg" width="459.837" height="385.914" viewBox="0 0 459.837 385.914">
            <g transform="matrix(0.526647, 0, 0, 0.528468, -92.098328, -30.160315)" opacity="0.71">
                <path
                    d="M 585.091 311.81 C 587.09 309.368 585.696 305.678 582.583 305.167 C 580.778 304.872 578.997 305.807 578.214 307.459 C 576.864 304.607 572.933 304.286 571.137 306.881 C 570.097 308.384 570.179 310.396 571.337 311.81 C 571.872 312.463 575.159 315.512 576.988 317.196 C 577.68 317.837 578.749 317.837 579.441 317.196 C 581.27 315.514 584.553 312.466 585.091 311.81 Z"
                    fill="rgb(var(--primaryRGB))"
                    transform="matrix(0.981627, 0.190151, -0.191468, 0.981627, 70.245671, -104.227189)"
                />
                <path
                    d="M 868.165 316.554 C 870.911 313.231 869.029 308.181 864.778 307.464 C 862.296 307.045 859.84 308.334 858.774 310.614 C 856.945 306.71 851.576 306.249 849.11 309.784 C 847.672 311.846 847.785 314.615 849.386 316.554 C 850.123 317.446 854.607 321.608 857.105 323.908 C 858.05 324.782 859.509 324.782 860.454 323.908 C 862.949 321.607 867.431 317.445 868.165 316.554 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.65;"
                    transform="matrix(0.99862, 0.052335, -0.052698, 0.99862, 17.836132, -44.508167)"
                />
                <path
                    d="M 612.954 215.412 C 615.016 212.546 613.605 208.189 610.414 207.57 C 608.551 207.209 606.707 208.321 605.907 210.288 C 604.535 206.92 600.505 206.522 598.654 209.572 C 597.574 211.351 597.658 213.739 598.859 215.412 C 599.412 216.182 602.777 219.773 604.651 221.757 C 605.36 222.512 606.455 222.512 607.164 221.757 C 609.038 219.772 612.403 216.181 612.954 215.412 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.74;"
                />
                <path
                    d="M 762.712 241.52 C 765.459 238.197 763.579 233.148 759.328 232.43 C 756.846 232.011 754.389 233.3 753.323 235.58 C 751.495 231.676 746.126 231.215 743.659 234.75 C 742.22 236.812 742.332 239.581 743.933 241.52 C 744.669 242.412 749.152 246.574 751.649 248.874 C 752.594 249.749 754.053 249.749 754.998 248.874 C 757.494 246.573 761.977 242.411 762.712 241.52 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.428"
                />
                <path
                    d="M 622.773 379.757 C 624.976 376.73 623.445 372.128 620.017 371.475 C 618.015 371.093 616.04 372.268 615.187 374.345 C 613.702 370.788 609.373 370.368 607.396 373.589 C 606.243 375.468 606.342 377.991 607.638 379.757 C 608.234 380.57 611.86 384.362 613.88 386.458 C 614.644 387.255 615.82 387.255 616.579 386.458 C 618.583 384.361 622.183 380.569 622.773 379.757 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.79;"
                    transform="matrix(0.680738, 0.730003, -0.73506, 0.680738, 475.165937, -328.022355)"
                />
                <path
                    d="M 709.136 267.882 C 710.788 265.891 709.665 262.858 707.114 262.423 C 705.621 262.168 704.141 262.944 703.502 264.318 C 702.405 261.976 699.184 261.699 697.704 263.82 C 696.84 265.057 696.908 266.719 697.868 267.882 C 698.31 268.417 700.999 270.914 702.497 272.295 C 703.064 272.82 703.939 272.82 704.506 272.295 C 706.005 270.914 708.695 268.414 709.136 267.882 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.86;"
                />
                <path
                    d="M 676.487 379.661 C 678.139 377.67 677.016 374.637 674.465 374.202 C 672.972 373.947 671.492 374.723 670.853 376.097 C 669.756 373.755 666.535 373.478 665.055 375.599 C 664.191 376.836 664.259 378.498 665.219 379.661 C 665.661 380.196 668.35 382.693 669.848 384.074 C 670.415 384.599 671.29 384.599 671.857 384.074 C 673.356 382.693 676.046 380.193 676.487 379.661 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.235"
                />
                <circle cx="675.692" cy="277.102" r="5.76" fill="rgb(var(--primaryRGB))" opacity="0.266" />
                <circle cx="719.504" cy="390.228" r="5.76" fill="rgb(var(--primaryRGB))" opacity="0.74" />
                <circle cx="691.054" cy="364.514" r="3.201" fill="rgb(var(--primaryRGB))" />
                <circle cx="625.776" cy="281.653" r="5.76" fill="rgb(var(--primaryRGB))" opacity="0.208" />
                <circle cx="709.905" cy="315.236" r="3.84" fill="rgb(var(--primaryRGB))" opacity="0.342" />
                <circle cx="648.12" cy="284.525" r="3.84" fill="rgb(var(--primaryRGB))" />
                <circle cx="601.562" cy="251.37" r="1.84" fill="rgb(var(--primaryRGB))" />
                <circle cx="646.2" cy="355.553" r="1.92" fill="rgb(var(--primaryRGB))" />
                <path
                    d="M 566.638 361.766 C 568.881 359.026 567.317 354.885 563.823 354.313 C 561.798 353.981 559.798 355.03 558.921 356.884 C 557.406 353.683 552.994 353.323 550.98 356.235 C 549.812 357.922 549.904 360.179 551.204 361.766 C 551.804 362.499 555.493 365.92 557.545 367.81 C 558.321 368.529 559.521 368.529 560.298 367.81 C 562.35 365.923 566.034 362.502 566.638 361.766 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.67"
                />
                <path
                    d="M 495.863 329.615 C 497.908 326.916 496.519 322.814 493.364 322.231 C 491.522 321.89 489.696 322.937 488.9 324.789 C 487.548 321.618 483.563 321.243 481.726 324.115 C 480.653 325.791 480.733 328.039 481.918 329.615 C 482.464 330.34 485.786 333.721 487.637 335.589 C 488.337 336.299 489.421 336.299 490.124 335.589 C 491.98 333.72 495.316 330.339 495.863 329.615 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.42"
                    transform="matrix(0.956022, -0.292285, 0.29431, 0.956022, -75.369491, 157.373026)"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="4.186"
                    transform="matrix(2.388889, 0, 0, -2.298611, 437.620758, 230.199768)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.51"
                />
                <path
                    d="M 741.572 446.435 C 744.325 443.113 742.453 438.064 738.203 437.347 C 735.721 436.928 733.261 438.216 732.191 440.496 C 730.369 436.593 725 436.132 722.527 439.666 C 721.084 441.728 721.191 444.495 722.79 446.435 C 723.524 447.327 728.001 451.488 730.495 453.788 C 731.439 454.661 732.898 454.661 733.845 453.788 C 736.345 451.487 740.836 447.326 741.572 446.435 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.75"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -86.694334, 190.428667)"
                />
                <path
                    d="M 481.77 445.539 C 484.523 442.217 482.65 437.168 478.4 436.451 C 475.919 436.032 473.459 437.321 472.389 439.6 C 470.567 435.697 465.198 435.236 462.724 438.77 C 461.282 440.832 461.389 443.6 462.988 445.539 C 463.722 446.431 468.199 450.592 470.693 452.892 C 471.637 453.765 473.096 453.765 474.043 452.892 C 476.543 450.591 481.034 446.43 481.77 445.539 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.73"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -94.244415, 127.562676)"
                />
                <path
                    d="M 666.555 442.841 C 669.308 439.519 667.435 434.47 663.185 433.753 C 660.704 433.334 658.244 434.623 657.174 436.902 C 655.352 432.999 649.983 432.538 647.51 436.072 C 646.067 438.134 646.174 440.902 647.773 442.841 C 648.507 443.733 652.984 447.894 655.478 450.194 C 656.422 451.067 657.881 451.067 658.828 450.194 C 661.328 447.893 665.819 443.732 666.555 442.841 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.53"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -88.062111, 172.176595)"
                />
                <path
                    d="M 839.77 229.539 C 842.523 226.217 840.651 221.168 836.401 220.451 C 833.919 220.032 831.459 221.32 830.389 223.6 C 828.567 219.697 823.198 219.236 820.725 222.77 C 819.282 224.832 819.389 227.599 820.988 229.539 C 821.722 230.431 826.199 234.592 828.693 236.891 C 829.637 237.765 831.096 237.765 832.043 236.891 C 834.543 234.591 839.034 230.43 839.77 229.539 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.47"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -30.933361, 207.69461)"
                />
                <path
                    d="M 836.771 216.539 C 839.514 213.216 837.627 208.167 833.375 207.45 C 830.892 207.031 828.436 208.319 827.373 210.6 C 825.54 206.696 820.169 206.235 817.707 209.77 C 816.27 211.832 816.386 214.599 817.99 216.539 C 818.727 217.431 823.216 221.592 825.716 223.892 C 826.662 224.766 828.122 224.766 829.066 223.892 C 831.559 221.591 836.037 217.43 836.771 216.539 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.71"
                    transform="matrix(0.981503, 0.190785, -0.192107, 0.981503, 56.790966, -153.857088)"
                />
                <path
                    d="M 875.834 164.23 C 878.587 160.908 876.715 155.859 872.465 155.142 C 869.983 154.723 867.523 156.011 866.453 158.291 C 864.631 154.388 859.262 153.927 856.789 157.461 C 855.346 159.523 855.453 162.29 857.052 164.23 C 857.786 165.122 862.263 169.283 864.757 171.582 C 865.701 172.456 867.16 172.456 868.107 171.582 C 870.607 169.282 875.098 165.121 875.834 164.23 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.7"
                    transform="matrix(0.882976, -0.4678, 0.471041, 0.882976, 24.309518, 424.477743)"
                />
                <path
                    d="M 696.245 101.676 C 698.986 98.354 697.095 93.306 692.841 92.589 C 690.357 92.17 687.902 93.458 686.84 95.738 C 685.003 91.835 679.631 91.375 677.171 94.908 C 675.736 96.97 675.854 99.737 677.459 101.676 C 678.198 102.567 682.691 106.728 685.194 109.027 C 686.141 109.901 687.601 109.901 688.544 109.027 C 691.036 106.727 695.512 102.566 696.245 101.676 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.49"
                    transform="matrix(0.945172, 0.325448, -0.327703, 0.945172, 70.788207, -217.990599)"
                />
                <path
                    d="M 773.595 165.781 C 776.348 162.459 774.476 157.41 770.225 156.693 C 767.744 156.274 765.284 157.562 764.214 159.842 C 762.392 155.939 757.023 155.478 754.55 159.012 C 753.107 161.074 753.214 163.841 754.813 165.781 C 755.547 166.673 760.024 170.834 762.518 173.133 C 763.462 174.007 764.921 174.007 765.868 173.133 C 768.368 170.833 772.859 166.672 773.595 165.781 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.7"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -17.383881, 189.782342)"
                />
                <path
                    d="M 685.21 189.989 C 687.963 186.667 686.09 181.618 681.84 180.901 C 679.359 180.482 676.899 181.77 675.829 184.05 C 674.007 180.147 668.638 179.686 666.165 183.22 C 664.722 185.282 664.829 188.049 666.428 189.989 C 667.162 190.881 671.639 195.042 674.133 197.341 C 675.077 198.215 676.536 198.215 677.483 197.341 C 679.983 195.041 684.474 190.88 685.21 189.989 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.8"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -25.922506, 169.128214)"
                />
                <path
                    d="M 779.955 365.431 C 782.299 362.604 780.705 358.306 777.087 357.695 C 774.975 357.339 772.881 358.435 771.97 360.376 C 770.419 357.053 765.848 356.661 763.743 359.669 C 762.515 361.425 762.606 363.78 763.967 365.431 C 764.592 366.191 768.403 369.733 770.526 371.69 C 771.33 372.434 772.572 372.434 773.378 371.69 C 775.506 369.732 779.329 366.19 779.955 365.431 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.7"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -65.797578, 197.63057)"
                />
                <path
                    d="M 545.77 162.539 C 548.523 159.217 546.65 154.168 542.4 153.451 C 539.919 153.032 537.459 154.32 536.389 156.6 C 534.567 152.697 529.198 152.236 526.724 155.77 C 525.282 157.832 525.389 160.599 526.988 162.539 C 527.722 163.431 532.199 167.592 534.693 169.891 C 535.637 170.765 537.096 170.765 538.043 169.891 C 540.543 167.591 545.034 163.43 545.77 162.539 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.54"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -23.406489, 134.580644)"
                />
                <path
                    d="M 466.77 146.539 C 469.523 143.217 467.65 138.168 463.4 137.451 C 460.919 137.032 458.459 138.32 457.389 140.6 C 455.567 136.697 450.198 136.236 447.724 139.77 C 446.282 141.832 446.389 144.599 447.987 146.539 C 448.722 147.431 453.199 151.592 455.693 153.891 C 456.637 154.765 458.096 154.765 459.043 153.891 C 461.543 151.591 466.034 147.43 466.77 146.539 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.41"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -21.871885, 114.994186)"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="2.512"
                    transform="matrix(2.388889, 0, 0, -2.298611, 989.620789, 314.199799)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.74"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="3.767"
                    transform="matrix(2.388889, 0, 0, -2.298611, 824.620789, 432.199799)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.49"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="2.512"
                    transform="matrix(2.388889, 0, 0, -2.298611, 473.620758, 115.199806)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.7"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="4.186"
                    transform="matrix(2.388889, 0, 0, -2.298611, 898.620789, 270.199799)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.41"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="5.76"
                    transform="matrix(2.388889, 0, 0, -2.298611, 930.620789, 382.199768)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.49"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="4.923"
                    transform="matrix(2.388889, 0, 0, -2.298611, 905.741455, 483.664276)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.49"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="1.674"
                    transform="matrix(2.388889, 0, 0, -2.298611, 588.620789, 131.199768)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.49"
                />
                <path
                    d="M 544.312 264.196 C 546.58 261.448 545.01 257.27 541.487 256.677 C 539.43 256.331 537.397 257.396 536.52 259.282 C 534.996 256.053 530.547 255.672 528.511 258.597 C 527.324 260.302 527.424 262.593 528.755 264.196 C 529.367 264.934 533.09 268.378 535.164 270.28 C 535.95 271.003 537.158 271.003 537.939 270.28 C 540.001 268.377 543.705 264.933 544.312 264.196 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.68"
                    transform="matrix(0.882274, 0.469114, -0.472364, 0.882274, 187.733859, -220.646551)"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="2.093"
                    transform="matrix(2.388889, 0, 0, -2.298611, 763.434021, 320.33725)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.64"
                />
                <path
                    d="M 393.45 281.497 C 396.189 278.176 394.292 273.13 390.037 272.414 C 387.552 271.995 385.097 273.283 384.037 275.561 C 382.196 271.66 376.822 271.2 374.363 274.732 C 372.929 276.792 373.049 279.559 374.656 281.497 C 375.396 282.388 379.894 286.547 382.399 288.845 C 383.348 289.718 384.808 289.718 385.751 288.845 C 388.242 286.546 392.717 282.387 393.45 281.497 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.83"
                    transform="matrix(0.882274, 0.469114, -0.472364, 0.882274, 177.907685, -147.090501)"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="5.76"
                    transform="matrix(2.388889, 0, 0, -2.298611, 330.317963, 171.397537)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.56"
                />
                <path
                    d="M 336.9 447.76 C 340.237 443.702 337.927 437.534 332.743 436.658 C 329.716 436.147 326.724 437.721 325.433 440.506 C 323.191 435.738 316.643 435.174 313.648 439.491 C 311.901 442.01 312.047 445.392 314.006 447.76 C 314.906 448.85 320.387 453.933 323.438 456.743 C 324.593 457.81 326.372 457.81 327.522 456.743 C 330.556 453.931 336.007 448.849 336.9 447.76 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.58"
                    transform="matrix(0.882274, 0.469114, -0.472364, 0.882274, 249.485037, -100.041194)"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="5.76"
                    transform="matrix(2.388889, 0, 0, -2.298611, 269.879578, 324.447632)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.59"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="4.201"
                    transform="matrix(2.388889, 0, 0, -2.298611, 386.928894, 385.09848)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.73"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="4.923"
                    transform="matrix(1.887426, 0, 0, -1.816098, 725.46051, 553.032043)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.68"
                />
                <path
                    d="M 858.382 495.944 C 861.135 492.622 859.263 487.573 855.013 486.856 C 852.531 486.437 850.071 487.725 849.001 490.005 C 847.179 486.102 841.81 485.641 839.337 489.175 C 837.894 491.237 838.001 494.004 839.6 495.944 C 840.334 496.836 844.811 500.997 847.305 503.297 C 848.249 504.17 849.708 504.17 850.655 503.297 C 853.155 500.996 857.646 496.835 858.382 495.944 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.46"
                    transform="matrix(0.981616, 0.190211, -0.191529, 0.981616, 110.484895, -152.3822)"
                />
                <path
                    d="M 552.202 531.472 C 554.764 527.951 552.983 522.599 548.996 521.84 C 546.668 521.396 544.371 522.762 543.379 525.178 C 541.652 521.041 536.618 520.553 534.319 524.299 C 532.978 526.484 533.093 529.418 534.6 531.472 C 535.293 532.417 539.51 536.827 541.859 539.265 C 542.748 540.192 544.115 540.192 544.998 539.265 C 547.329 536.826 551.515 532.416 552.202 531.472 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.64"
                    transform="matrix(0.680738, 0.730003, -0.73506, 0.680738, 563.697514, -227.194531)"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="4.923"
                    transform="matrix(2.388889, 0, 0, -2.298611, 806.943787, 599.871765)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.49"
                />
                <path
                    d="M 642.016 566.643 C 645.157 562.853 643.02 557.092 638.172 556.274 C 635.341 555.796 632.534 557.267 631.314 559.867 C 629.235 555.414 623.109 554.888 620.288 558.92 C 618.641 561.272 618.763 564.431 620.588 566.643 C 621.425 567.66 626.533 572.408 629.378 575.032 C 630.455 576.028 632.12 576.028 633.2 575.032 C 636.053 572.407 641.177 567.659 642.016 566.643 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.64"
                    transform="matrix(0.998633, -0.052095, 0.052456, 0.998633, -28.8259, 33.662086)"
                />
                <path
                    d="M 587.605 432.606 C 589.951 429.775 588.355 425.472 584.733 424.861 C 582.619 424.504 580.522 425.602 579.61 427.545 C 578.058 424.218 573.482 423.825 571.374 426.837 C 570.145 428.595 570.236 430.953 571.599 432.606 C 572.224 433.366 576.04 436.912 578.165 438.872 C 578.969 439.616 580.213 439.616 581.02 438.872 C 583.15 436.911 586.978 433.365 587.605 432.606 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.81"
                    transform="matrix(0.970099, -0.241873, 0.243549, 0.970099, -87.909419, 153.112584)"
                />
                <path
                    d="M 457.21 523.895 C 460.267 520.178 458.151 514.529 453.403 513.727 C 450.63 513.258 447.89 514.7 446.707 517.251 C 444.654 512.884 438.656 512.367 435.913 516.321 C 434.313 518.629 434.447 521.726 436.241 523.895 C 437.065 524.894 442.085 529.549 444.88 532.123 C 445.938 533.101 447.567 533.101 448.621 532.123 C 451.4 529.548 456.393 524.893 457.21 523.895 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.58"
                    transform="matrix(0.882274, 0.469114, -0.472364, 0.882274, 299.753834, -147.962115)"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="5.76"
                    transform="matrix(2.388889, 0, 0, -2.29861, 337.955536, 578.194275)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.49"
                />
                <path
                    d="M 236.46 478.885 C 240.075 474.489 237.573 467.807 231.957 466.858 C 228.678 466.304 225.436 468.009 224.038 471.026 C 221.609 465.861 214.515 465.25 211.271 469.927 C 209.378 472.656 209.537 476.319 211.659 478.885 C 212.634 480.065 218.571 485.572 221.877 488.616 C 223.128 489.772 225.055 489.772 226.301 488.616 C 229.588 485.57 235.493 480.064 236.46 478.885 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.58"
                    transform="matrix(0.882274, 0.469114, -0.472364, 0.882274, 252.222682, -48.818494)"
                />
                <path
                    d="M 505.654 643.861 C 509.486 639.202 506.834 632.121 500.882 631.115 C 497.407 630.528 493.971 632.335 492.489 635.532 C 489.915 630.058 482.396 629.411 478.958 634.367 C 476.952 637.26 477.121 641.142 479.37 643.861 C 480.403 645.112 486.695 650.948 490.199 654.175 C 491.525 655.4 493.567 655.4 494.888 654.175 C 498.371 650.946 504.63 645.111 505.654 643.861 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.58"
                    transform="matrix(0.98162, 0.190187, -0.191505, 0.98162, 132.19903, -81.848613)"
                />
                <path
                    d="M 793.817 657.773 C 797.784 652.986 795.085 645.71 788.962 644.677 C 785.386 644.073 781.841 645.931 780.3 649.215 C 777.674 643.59 769.937 642.926 766.374 648.019 C 764.294 650.989 764.448 654.979 766.753 657.773 C 767.81 659.058 774.261 665.055 777.855 668.369 C 779.215 669.627 781.318 669.627 782.682 668.369 C 786.286 665.053 792.757 659.056 793.817 657.773 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.54"
                    transform="matrix(0.882976, -0.467801, 0.471042, 0.882976, -218.131518, 441.902114)"
                />
                <circle
                    cx="5.76"
                    cy="5.76"
                    r="5.76"
                    transform="matrix(2.580771, 0, 0, -2.483241, 639.686462, 727.91333)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.38"
                />
                <path
                    d="M 657.429 726.691 C 661.84 721.368 658.841 713.276 652.029 712.127 C 648.052 711.455 644.109 713.52 642.395 717.173 C 639.474 710.918 630.871 710.179 626.906 715.843 C 624.594 719.148 624.766 723.582 627.328 726.691 C 628.504 728.12 635.679 734.789 639.676 738.476 C 641.189 739.876 643.527 739.876 645.045 738.476 C 649.052 734.788 656.249 728.119 657.429 726.691 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.6"
                    transform="matrix(0.981616, 0.19021, -0.191528, 0.981616, 150.813722, -108.847105)"
                />
                <path
                    d="M 498.691 340.489 C 500.934 337.321 499.37 332.533 495.876 331.872 C 493.851 331.488 491.851 332.701 490.974 334.845 C 489.459 331.143 485.047 330.727 483.033 334.094 C 481.865 336.045 481.957 338.654 483.257 340.489 C 483.857 341.337 487.546 345.292 489.598 347.478 C 490.374 348.309 491.574 348.309 492.351 347.478 C 494.403 345.296 498.087 341.34 498.691 340.489 Z"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.67"
                />
            </g>
        </svg>
    </span>
);
