/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Error } from '../../components/Error/Error';

function Error404() {
    return <Error numericCode="404" messageTitle="Not found" messageBody="Content not found" />;
}

export { Error404 };
