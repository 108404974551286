/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import axios from 'axios';
import { API_BASE_URL, BASE_URL } from '../constants/appUrls';

export const client = axios.create({
    baseURL: API_BASE_URL,
    timeout: 50000,
    withCredentials: true, // send cookies
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export const baseClient = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    withCredentials: true, // send cookies
});

export const baseJsonClient = {
    ...baseClient,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

export const tenantApiJsonClient = client;
