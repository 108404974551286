export const deleteUserModal = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};

export const adminAlertMessage = {
  SHOW_SUCCESS: 'SHOW_SUCCESS',
  SHOW_ERROR: 'SHOW_ERROR',
  RESET: 'RESET',
};
