/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideDeleteUserModal, showSuccess, startLoading, stopLoading, showError } from '../../../../actions';
import { deleteAndAnonymizeUser } from '../../../../services/user/deleteUser.service';

function AnonymizeUserBody(props) {
    const { tenantName, userToBeDeleted, reloadUsers } = props;
    const dispatch = useDispatch();
    const hideModal = () => {
        dispatch(hideDeleteUserModal());
    };
    const deleteUserModalContent = useSelector((state) => state.deleteUserContent);

    const anonymizeAndDeleteUser = () => {
        hideModal();
        dispatch(startLoading());

        deleteAndAnonymizeUser(deleteUserModalContent.email)
            .then((response) => {
                dispatch(showSuccess(response.data.message));
            })
            .catch((error) => {
                dispatch(showError(error.response.data.message));
            })
            .then(() => {
                reloadUsers();
                dispatch(stopLoading());
            });
    };

    return (
        <form className="needs-validation user-edit-form" id="transferContentAndDelete" noValidate>
            <div className="row col-12 d-flex justify-content-center mt-n3">
                <div className="form-group col-12">
                    <p className="text-center h5">{userToBeDeleted}</p>
                    <p className="text-center pt-2">
                        {`All user data will be deleted from ${tenantName} Brand Learning.`}
                    </p>
                </div>
                <div
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    className="form-group col-12 d-flex justify-content-center"
                >
                    <button
                        type="button"
                        className="btn btn-pop-cancel btn-md mr-4 w-auto"
                        onClick={anonymizeAndDeleteUser}
                    >
                        Delete User
                    </button>
                    <button
                        type="button"
                        className="btn btn-md text-uppercase btn-pop-save btn-suggest-edits-pop"
                        onClick={hideModal}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    );
}

AnonymizeUserBody.defaultProps = {
    error: '',
};

export default AnonymizeUserBody;
