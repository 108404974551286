/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FastestAnswerFeedback from './images/FastestAnswerFeedback';
import { Button } from '../../../Form/Button/Button';
import DynamicCoacherAvatar from '../../../DynamicMedia/DynamicCoacherAvatar';

export const FastestAnswer = (props) => {
    const { time = '0', handleFeedback = '' } = props;
    const { t } = useTranslation();
    const coacherAvatar = useSelector((state) => state.tenant.tenantCoacherAvatar);

    return (
        <>
            <div className="teacher mx-auto">
                <span className="teacher__avatar">
                    <DynamicCoacherAvatar
                        path={coacherAvatar}
                        alt={t('avatar_image')}
                        className="teacher__avatar-image"
                    />
                </span>
                <span className="teacher__bubble">
                    <span className="teacher__bubble-text">
                        <Trans i18nKey="hurray" /> <br /> <Trans i18nKey="you_set_personal_record" />
                    </span>
                </span>
            </div>
            <div className="card reward-card">
                <FastestAnswerFeedback />

                <div className="reward-card__message mb-5">
                    <strong>
                        <Trans i18nKey="personal_record" />
                    </strong>{' '}
                    <br />
                    <Trans i18nKey="fastest_answer_ever" /> <br />
                    {time} <Trans i18nKey="seconds" />
                </div>

                <Button size="md" onClick={handleFeedback}>
                    <Trans i18nKey="ok" />
                </Button>
            </div>
        </>
    );
};
