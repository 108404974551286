/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { TeacherMessage } from '../../components/Room/Teacher/Message/Message';
import { questionTypes, questionDifficulty, BaseRoutes } from '../../constants';
import { MultipleChoice, TrueAndFalse, FillInTheBlanks } from './QuestionTypes';
import { Button } from '../../components/Form/Button/Button';
import { questionService } from '../../services/question';
import { QuestionFeedBack } from './Feedback/QuestionFeedBack';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { attemptService } from '../../services/attempt';
import { isTimeOut, setOngoingLevel, startLoading, statisticActions } from '../../actions';
import { Loader } from '../../components/Loader';
import { common } from '../../helpers';
import LevelWelcome from './Welcome/LevelWelcome';
import { HardLearningStartingScreen } from './QuestionTypes/HardLearningStartingScreen';
import Content from './LearningContent/Content';
import Draggable from './QuestionTypes/Draggable';
import Caret from '../../components/Icons/Caret';

function Question({ history, match }) {
    const dispatch = useDispatch();
    const { levelId, roomId } = match.params;
    const [questions, setQuestions] = useState({});
    const [sectionKey, setSectionKey] = useState(Math.random().toString(36).substring(2, 20));
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState([]);
    const [difficulty, setDifficulty] = useState(questionDifficulty.SOFT);
    const [questionCount, setQuestionCount] = useState(0);
    const [answer, setAnswer] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [attempt, setAttempt] = useState({});
    const [readyToStart, setReadyToStart] = useState(false);
    const [levelWelcome, setLevelWelcome] = useState(true);
    const [userLevelType, setUserLevelType] = useState(null);
    const [questionLoadTime, setQuestionLoadTime] = useState(0);
    const [totalTimeSpentInSeconds, setTotalTimeSpentInSeconds] = useState(0);
    const [showHardLearningStartScreen, setShowHardLearningStartScreen] = useState(false);
    const [learningSection, setLearningSection] = useState({});
    const [ongoingSectionId, setOngoingSectionId] = useState(null);
    const [disableQuestionNextButtonClick, setDisableQuestionNextButtonClick] = useState(false);
    const [isFirstQuestionOfTheLeaningSection, setIsFirstQuestionOfTheLeaningSection] = useState(true);
    const preview = useSelector((state) => state.preview);
    const { t } = useTranslation();

    // Hard learning questions timer
    const timer = useSelector((state) => state.timer);

    // To handle learning content dismiss action
    const learningContent = useSelector((state) => state.learningContent);

    useEffect(() => {
        attemptService
            .getAttempt(levelId, preview.isPreview)
            .then((attempt) => {
                if (attempt && attempt[0] !== undefined) {
                    const activeAttempt = attempt[0];

                    dispatch(setOngoingLevel(levelId, activeAttempt.game.attemptId, activeAttempt.game.roomId));
                    setAttempt(activeAttempt);
                    setUserLevelType(activeAttempt.roomAndLevelInfo.levelType);

                    // get questions
                    questionService.getQuestions(levelId).then((levelQuestions) => {
                        const allQuestionsCount =
                            levelQuestions[questionDifficulty.SOFT].length +
                            levelQuestions[questionDifficulty.HARD].length;
                        setQuestions(levelQuestions);

                        // Redirect to rooms if its no questions
                        if (allQuestionsCount <= 0) {
                            return history.push(BaseRoutes.ROOMS);
                        }

                        setQuestionCount(allQuestionsCount);
                        addCurrentQuestion(activeAttempt, levelQuestions);
                    });
                }
            })
            .catch(() => {});

        if (learningContent.dismissed) {
            window.setTimeout(() => {
                setLevelWelcome(false);
                setReadyToStart(true);
                setLearningSection({});
            }, 100);

            dismissLearningContent(false);
        }
    }, [learningContent]);

    // When the page loads this function is triggered to set the question
    const addCurrentQuestion = (activeAttempt, levelQuestions) => {
        setQuestionLoadTime(Date.now());
        setDisableQuestionNextButtonClick(false);

        const softLearningQuestions = levelQuestions[questionDifficulty.SOFT];
        const hardLearningQuestions = levelQuestions[questionDifficulty.HARD];
        let currentDifficulty = questionDifficulty.SOFT;
        let currentQuestionIndex = 0;

        // When there are no soft learning questions then hard learning questions should be started
        if (softLearningQuestions.length === 0) {
            currentDifficulty = questionDifficulty.HARD;
        }

        if (!common.isEmptyObject(activeAttempt) && !Array.isArray(activeAttempt.game.lastAnsweredQuestion)) {
            const lastQuestionId = activeAttempt.game.lastAnsweredQuestion.questionId;

            if (preview.isPreview !== 1 && softLearningQuestions.length > 0) {
                softLearningQuestions.map((question, idx) => {
                    if (question.questionId === lastQuestionId) {
                        currentQuestionIndex = idx + 1;
                    }
                });
            }

            if (preview.isPreview !== 1) {
                hardLearningQuestions.map((question, idx) => {
                    if (question.questionId === lastQuestionId) {
                        currentDifficulty = questionDifficulty.HARD;
                        currentQuestionIndex = idx + 1;
                    }
                });
            }

            setDifficulty(currentDifficulty);

            if (
                currentDifficulty === questionDifficulty.HARD ||
                (currentDifficulty === questionDifficulty.SOFT &&
                    levelQuestions[currentDifficulty].length <= currentQuestionIndex)
            ) {
                // Starting hard learning
                setDifficulty(questionDifficulty.HARD);
                setReadyToStart(true);
                setShowHardLearningStartScreen(true);
            } else if (currentQuestionIndex <= levelQuestions[currentDifficulty].length) {
                setIfCurrentQuestionFirstQuestionOfTheLearningContent(
                    levelQuestions[currentDifficulty],
                    levelQuestions[currentDifficulty][currentQuestionIndex].sectionId,
                    levelQuestions[currentDifficulty][currentQuestionIndex].questionId,
                );
                setOngoingSectionId(levelQuestions[currentDifficulty][currentQuestionIndex].sectionId);
                checkAndSetLearningContentScreenIfAny(
                    levelQuestions[currentDifficulty][currentQuestionIndex].sectionId,
                    levelQuestions,
                    currentDifficulty,
                    currentQuestionIndex,
                );

                setTimeout(() => {
                    setCurrentQuestion(levelQuestions[currentDifficulty][currentQuestionIndex]);
                    setCurrentQuestionIndex(currentQuestionIndex);

                    if (currentDifficulty === questionDifficulty.HARD) {
                        setReadyToStart(true);
                    }
                }, 100);
            } else {
                redirectToStatics();
            }
        } else if (currentDifficulty === questionDifficulty.HARD) {
            // Starting hard learning when there are no soft learning questions
            setDifficulty(questionDifficulty.HARD);
            setReadyToStart(true);
            setShowHardLearningStartScreen(true);
        } else {
            setIfCurrentQuestionFirstQuestionOfTheLearningContent(
                levelQuestions[questionDifficulty.SOFT],
                levelQuestions[questionDifficulty.SOFT][currentQuestionIndex].sectionId,
                levelQuestions[questionDifficulty.SOFT][currentQuestionIndex].questionId,
            );

            checkAndSetLearningContentScreenIfAny(
                levelQuestions[questionDifficulty.SOFT][currentQuestionIndex].sectionId,
                levelQuestions,
                questionDifficulty.SOFT,
                currentQuestionIndex,
            );

            setTimeout(() => {
                setCurrentQuestionIndex(currentQuestionIndex);
                setCurrentQuestion(levelQuestions[questionDifficulty.SOFT][currentQuestionIndex]);
            }, 100);
        }
    };

    // This function is triggered to set the next question when the user clicks the Next button on the current question
    const setQuestion = (noFeedbackCheck) => {
        setQuestionLoadTime(Date.now());
        setDisableQuestionNextButtonClick(false);
        const questionNumber = currentQuestionIndex + 1;

        if (difficulty === questionDifficulty.SOFT && questions[difficulty].length <= questionNumber) {
            // Starting hard learning
            setDifficulty(questionDifficulty.HARD);
            setReadyToStart(true);
            setShowHardLearningStartScreen(true);
        } else if (difficulty === questionDifficulty.HARD && questions[difficulty].length <= questionNumber) {
            // Show final result screen at the end of the hard learning questions
            redirectToStatics();
        } else if (questions[difficulty].length >= questionNumber) {
            const isFirstQuestionOfTheSection = setIfCurrentQuestionFirstQuestionOfTheLearningContent(
                questions[difficulty],
                questions[difficulty][questionNumber].sectionId,
                questions[difficulty][questionNumber].questionId,
            );
            setOngoingSectionId(questions[difficulty][questionNumber].sectionId);

            checkAndSetLearningContentScreenIfAny(
                questions[difficulty][questionNumber].sectionId,
                questions,
                difficulty,
                questionNumber,
            );

            if (noFeedbackCheck) {
                handleNoFeedback(isFirstQuestionOfTheSection);
            }

            setCurrentQuestionIndex(questionNumber);
            setCurrentQuestion(questions[difficulty][questionNumber]);
        } else {
            redirectToStatics();
        }
    };

    const checkAndSetLearningContentScreenIfAny = (sectionId, levelQuestions, currentDifficulty, questionIndex) => {
        if (ongoingSectionId !== sectionId) {
            levelQuestions.learningSections.map((section) => {
                if (section.id === levelQuestions[currentDifficulty][questionIndex].sectionId) {
                    setOngoingSectionId(section.id);
                    setLearningSection(section);
                }
            });
        }
    };

    const setIfCurrentQuestionFirstQuestionOfTheLearningContent = (questionSet, sectionId, questionId) => {
        const questionsBelongsToSection = questionSet.filter((question) => question.sectionId === sectionId);
        const [first] = questionsBelongsToSection;

        if (first.questionId === questionId) {
            setIsFirstQuestionOfTheLeaningSection(true);
            return true;
        }

        setIsFirstQuestionOfTheLeaningSection(false);
        return false;
    };

    const answerPayload = () => {
        const userAnswerIds = [];
        const userAnswerValues = [];
        const timeSpentForAnswerInSeconds = Math.round((Date.now() - questionLoadTime) / 1000);
        const totalTime = totalTimeSpentInSeconds + timeSpentForAnswerInSeconds;

        setTotalTimeSpentInSeconds(totalTime);

        if (typeof answer !== 'undefined' && answer) {
            Object.keys(answer).forEach((key) => {
                if (key !== undefined && answer[key] !== undefined) {
                    if (currentQuestion.questionTypeId === questionTypes.SEQUENTIAL) {
                        userAnswerIds.push(answer[key].id);
                        userAnswerValues.push(answer[key].value);
                    } else {
                        userAnswerIds.push(key);
                        userAnswerValues.push(answer[key]);
                    }
                }
            });
        }

        const answerIds =
            currentQuestion.questionTypeId === questionTypes.FILL_IN_THE_BLANKS
                ? JSON.stringify(userAnswerValues)
                : JSON.stringify(userAnswerIds);
        const { attemptId } = attempt.game;
        const isQuestionsOver =
            difficulty === questionDifficulty.HARD && questions[difficulty].length === currentQuestionIndex + 1;

        // Reset previous answers
        setAnswer(null);

        return {
            questionTypeId: `${currentQuestion.questionTypeId}`,
            difficulty,
            attemptId,
            roomId: Number(roomId),
            userAnswerIds: answerIds,
            userAnswerValues: JSON.stringify(userAnswerValues),
            isQuizOver: isQuestionsOver,
            isTimeOut: timer.timePassed,
            totalEffectiveTimeSpend: totalTime,
            questionEffectiveTimeSpend: timeSpentForAnswerInSeconds,
            totalHardQuestionCount: questions[questionDifficulty.HARD].length,
            totalQuestionCount: questionCount,
        };
    };

    const postAnswer = async () => {
        // If a question is hard learning question and the allocated time is over,
        // the user should not be allowed to answer the question
        if (!disableQuestionNextButtonClick) {
            await questionService.postAnswer(levelId, currentQuestion.questionId, answerPayload()).then((feedBack) => {
                if (feedBack.isFastestAnswer) {
                    setFeedback(feedBack);
                    setQuestion();
                } else if (
                    feedBack.hideFeedbackScreen ||
                    currentQuestion.difficulty.toLowerCase() === questionDifficulty.HARD
                ) {
                    setSectionKey(Math.random().toString(36).substring(2, 20));
                    setQuestion(true);
                } else {
                    setFeedback(feedBack);
                    setQuestion();
                }
            });
        }
    };

    const handleAnswer = (event, selectedAnswer = null) => {
        if (currentQuestion.questionTypeId === questionTypes.TRUE_FALSE) {
            setAnswer({ [selectedAnswer.answerId]: selectedAnswer.answer.toLowerCase() });
        } else if (currentQuestion.questionTypeId === questionTypes.MULTIPLE_CHOICES) {
            if (answer && Object.keys(answer).indexOf(String(selectedAnswer.answerId)) > -1) {
                const ansKey = Object.keys(answer).find((key) => key === String(selectedAnswer.answerId));
                const newAnswer = { ...answer };
                delete newAnswer[ansKey];
                setAnswer(newAnswer);
            } else {
                setAnswer({ ...answer, [selectedAnswer.answerId]: selectedAnswer.answer });
            }
        } else if (currentQuestion.questionTypeId === questionTypes.FILL_IN_THE_BLANKS) {
            const userAnswer = event.target.value;
            setAnswer({ ...answer, [selectedAnswer]: userAnswer });
        } else if (currentQuestion.questionTypeId === questionTypes.SEQUENTIAL) {
            setAnswer(selectedAnswer);
        }
    };

    // This function is triggered every time after each and every soft learning question except when there is a feedback.
    // And triggered by a click event.
    const handleFeedback = () => {
        // If current questions are hard learning questions, then need to set readyToStart as true
        // Sine there isn't any learning content associated with hard learning.
        if (difficulty === questionDifficulty.HARD) {
            setReadyToStart(true);
        } else if (isFirstQuestionOfTheLeaningSection && difficulty === questionDifficulty.SOFT) {
            // If any soft learning content is set and is it the first question of the learning content,
            // then the related learning content should be loaded. So we set readyToStart as false to do that.
            setReadyToStart(false);
        }

        setAnswer(null);
        setFeedback(null);
    };

    // This function is triggered every time after each and every soft learning question except when there is no feedback.
    // Not triggered by a click event. So need to handle this in a separate function (not from handleFeedback()).
    const handleNoFeedback = (isFirstQuestionOfTheSection) => {
        // If current questions are hard learning questions, then need to set readyToStart as true
        // Sine there isn't any learning content associated with hard learning.
        if (difficulty === questionDifficulty.HARD) {
            setReadyToStart(true);
        } else if (isFirstQuestionOfTheSection && difficulty === questionDifficulty.SOFT) {
            // If any soft learning content is set and is it the first question of the learning content,
            // then the related learning content should be loaded. So we set readyToStart as false to do that.
            setReadyToStart(false);
        }

        setAnswer(null);
        setFeedback(null);
    };

    const redirectToStatics = () => {
        dispatch(startLoading());
        dispatch(
            statisticActions.allowAccess({
                userType: 'INTERNAL',
                attemptId: attempt.game.attemptId,
                roomId,
                levelId,
                roomName: attempt.roomAndLevelInfo ? attempt.roomAndLevelInfo.roomTitle : '',
            }),
        );

        let previousPath = history.location.pathname.split('/').slice(0, 5).join('/');

        if (preview.isPreview) {
            previousPath = history.location.pathname.split('/').slice(0, 6).join('/');
        }

        setTimeout(() => {
            history.push(`${previousPath}${BaseRoutes.STATISTICS}`);
        }, 100);
    };

    const dismissHardLearningStartScreen = () => {
        setShowHardLearningStartScreen(false);
        setDifficulty(questionDifficulty.HARD);
        setCurrentQuestionIndex(0);
        setCurrentQuestion(questions[questionDifficulty.HARD][0]);
    };

    const dismissLearningContent = () => {
        const timer = window.setTimeout(() => {
            setLevelWelcome(false);
            setReadyToStart(true);
        }, 100);

        setLearningSection({});

        return () => clearTimeout(timer);
    };

    const showLearningContent = () => {
        if (difficulty === questionDifficulty.SOFT) {
            questions.learningSections.map((section) => {
                if (section.id === currentQuestion.sectionId) {
                    setLevelWelcome(false);
                    setReadyToStart(false);
                    setLearningSection(section);
                }
            });
        }
    };

    if (levelWelcome && userLevelType) {
        // Level icon will hide in 3s and then welcome state
        // should be set at false. Refer tart--animate class.
        window.setTimeout(() => {
            setLevelWelcome(false);
        }, 3000);

        return <LevelWelcome difficulty={userLevelType} />;
    }

    if (!levelWelcome && Object.keys(learningSection).length > 0 && !readyToStart) {
        return (
            <Content
                content={learningSection}
                dismissHandler={dismissLearningContent}
                roomAndLevelInfo={attempt.roomAndLevelInfo}
            />
        );
    }

    if (timer.timePassed) {
        setDisableQuestionNextButtonClick(true);
        postAnswer().then(() => {
            console.log('Time is over and answered automatically');
        });

        dispatch(isTimeOut({ timePassed: false, canAnswer: false }));
    }

    return (
        <>
            <Loader />

            {!showHardLearningStartScreen && difficulty === questionDifficulty.SOFT && !levelWelcome && (
                <Fade bottom duration={1000}>
                    <div className="show-learning-content" onClick={showLearningContent}>
                        <span>
                            <Caret />
                        </span>
                        <Trans i18nKey="back_to_text" />
                    </div>
                </Fade>
            )}
            {readyToStart && (
                <section className="body body--with-footer" key={sectionKey}>
                    <div className={`container h-100 ${!feedback && 'start--animate'}`} id="start">
                        <Breadcrumb
                            path={
                                attempt.roomAndLevelInfo &&
                                `${attempt.roomAndLevelInfo.roomTitle} / ${t(attempt.roomAndLevelInfo.levelPrefix)}`
                            }
                        />
                        <section className="question question-wrap d-flex flex-column justify-content-center">
                            {feedback ? (
                                <QuestionFeedBack handleFeedback={handleFeedback} feedback={feedback} />
                            ) : (
                                <>
                                    {readyToStart && (
                                        <>
                                            {!showHardLearningStartScreen && currentQuestion.question && (
                                                <div className="teacher mx-auto teacher--alt">
                                                    <TeacherMessage
                                                        message={currentQuestion.questionTitle}
                                                        isHardLearningQuestion={
                                                            currentQuestion.difficulty.toLowerCase() ===
                                                            questionDifficulty.HARD
                                                        }
                                                        duration={currentQuestion.duration}
                                                        questionId={currentQuestion.questionId}
                                                        delay={0}
                                                        addClass="teacher__margin-bottom-0"
                                                    />
                                                </div>
                                            )}
                                            {(() => {
                                                if (showHardLearningStartScreen) {
                                                    return (
                                                        <HardLearningStartingScreen
                                                            roomId={roomId}
                                                            levelId={levelId}
                                                            dismissHandler={dismissHardLearningStartScreen}
                                                        />
                                                    );
                                                }
                                                switch (currentQuestion.questionTypeId) {
                                                    case questionTypes.TRUE_FALSE:
                                                        return (
                                                            <TrueAndFalse
                                                                selectedAnswers={answer}
                                                                changeEvent={handleAnswer}
                                                                question={currentQuestion}
                                                            />
                                                        );
                                                    case questionTypes.MULTIPLE_CHOICES:
                                                        return (
                                                            <MultipleChoice
                                                                selectedAnswers={answer}
                                                                changeEvent={handleAnswer}
                                                                question={currentQuestion}
                                                            />
                                                        );
                                                    case questionTypes.FILL_IN_THE_BLANKS:
                                                        return (
                                                            <FillInTheBlanks
                                                                selectedAnswers={answer}
                                                                changeEvent={handleAnswer}
                                                                question={currentQuestion}
                                                            />
                                                        );
                                                    case questionTypes.SEQUENTIAL:
                                                        return (
                                                            <Draggable
                                                                selectedAnswers={answer}
                                                                changeEvent={handleAnswer}
                                                                question={currentQuestion}
                                                            />
                                                        );
                                                    default:
                                                }
                                            })()}
                                        </>
                                    )}
                                </>
                            )}
                        </section>

                        {currentQuestion.question && !feedback && !showHardLearningStartScreen && !timer.timePassed && (
                            <footer
                                className={`footer ${
                                    answer &&
                                    typeof answer === 'object' &&
                                    Object.keys(common.cleanObject(answer)).length > 0 &&
                                    'footer__show-answer-button'
                                }`}
                            >
                                <div className="container d-flex align-items-center h-100">
                                    <div className="footer__button-wrap">
                                        {answer &&
                                        typeof answer === 'object' &&
                                        Object.keys(common.cleanObject(answer)).length > 0 ? (
                                            <Button size="md" onClick={postAnswer} disabled={!timer.canAnswer}>
                                                <Trans i18nKey="soft_feedback_next" />
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                        {currentQuestion.length > 0 && currentQuestion.canSkip && (
                                            <Button size="md" onClick={postAnswer} disabled={!timer.canAnswer}>
                                                <Trans i18nKey="skip" />
                                            </Button>
                                        )}
                                    </div>
                                    <span className="footer__question-step">
                                        <Trans i18nKey="question" /> {currentQuestionIndex + 1}/
                                        {questions[difficulty].length}
                                    </span>
                                </div>
                            </footer>
                        )}
                    </div>
                </section>
            )}
        </>
    );
}

export default Question;
