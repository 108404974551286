/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import { Button } from '../Form/Button/Button';
import 'react-image-crop/dist/ReactCrop.css';

function ImageCropper(props) {
    const { imageToCrop, onImageCropped, uploadHandle, fileName } = props;

    const [show, setShow] = useState(false);
    const [cropConfig, setCropConfig] = useState({
        unit: '%',
        width: 8,
        aspect: 1,
        x: 0,
        y: 0,
    });

    const [imageRef, setImageRef] = useState();
    const [isDragged, setIsDragged] = useState(false);

    useEffect(() => {
        imageToCrop && setShow(true);
        setIsDragged(false);
        setCropConfig({ ...cropConfig, x: 0, y: 0 });
    }, [fileName]);

    const { t } = useTranslation();

    const handleClose = () => setShow(false);

    const handleSubmit = () => {
        if (isDragged) {
            uploadHandle();
            setShow(false);
        } else {
            alert(t('invalid_cropped_area'));
        }
    };

    async function cropImage(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef,
                crop,
                'croppedImage.jpeg', // destination filename
            );
            onImageCropped(croppedImage);
        }
    }

    function getCroppedImage(sourceImage, cropConfig, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    // returning an error
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }

                    blob.name = fileName;
                    resolve(blob);
                },
                'image/jpeg',
                1,
            );
        });
    }

    return (
        <div>
            {imageToCrop && (
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>
                            <Trans i18nKey="resize_image" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactCrop
                            src={imageToCrop}
                            crop={cropConfig}
                            ruleOfThirds
                            onImageLoaded={(imageRef) => setImageRef(imageRef)}
                            onComplete={(cropConfig) => cropImage(cropConfig)}
                            onChange={(cropConfig) => setCropConfig(cropConfig)}
                            crossorigin="anonymous"
                            onDragStart={(e) => setIsDragged(true)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" size="sm" onClick={handleClose}>
                            <Trans i18nKey="cancel" />
                        </Button>
                        <Button size="sm" onClick={handleSubmit}>
                            <Trans i18nKey="save" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

ImageCropper.defaultProps = {
    onImageCropped: () => {},
};

export default ImageCropper;
