/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { userInfo } from '../constants';

const initialState = {
    coins: 0,
};

export function coinsReducer(state = initialState, action) {
    switch (action.type) {
        case userInfo.ACHIEVED_COINS:
            return action.payload;

        default: {
            return state;
        }
    }
}
