/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { client } from '../../helpers';
import { HttpStatus } from '../../constants';

export const userService = {
    login,
    logOut,
    getUser,
};

function login() {}

function logOut() {}

function getUser() {
    return client.get('user-info').then(formatResponse).catch(error);
}

function error(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
    } else if (error.request) {
        // The request was made but no response was received
    } else {
        // Something happened in setting up the request that triggered an Error
    }
}

function formatResponse(response) {
    if (response.status && response.status === HttpStatus.OK) {
        return response.data ? response.data : false;
    }
    return false;
}
