import { error } from '../constants';

export const showError = (errorData = '', type = 'api') => ({
  type: error.SHOW_ERROR,
  payload: {
    type,
    show: true,
    data: errorData,
  },
});

export const hideError = () => ({
  type: error.HIDE_ERROR,
  payload: {
    show: false,
  },
});
