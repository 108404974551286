/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { Trans } from 'react-i18next';
import { HttpStatus } from '../constants';
import { showError } from '../actions/error.action';
import { store } from './store';

export const response = {
    error,
    formatResponse,
};

function error(error) {
    if (error.response) {
        console.info("'-------------Response error----------------'");
        console.log(error.response);
        console.info("'-------------------------------------------'");

        if (error.response.data !== undefined) {
            store.dispatch(showError(error.request));
        }
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
    } else if (error.request) {
        console.info('-------------Request error----------------');
        console.log(error.request);
        console.info("'-------------------------------------------'");

        if (error.request.data !== undefined) {
            store.dispatch(showError(error.request));
        }
        // The request was made but no response was received
    } else {
        const formattedError = {
            code: 500,
            data: [],
            message: <Trans i18nKey="general_error" />,
            success: false,
        };

        store.dispatch(showError(formattedError));
    }
}

function formatResponse(response) {
    if (response.status && response.status === HttpStatus.OK) {
        return response.data.data ? response.data.data : response.data;
    }
    return false;
}
