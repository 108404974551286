import { combineReducers } from 'redux';
import { questionReducer } from './question.reducer';
import { userReducer } from './user.reducer';
import { authenticationReducer } from './authentication.reducer';
import { themeReducer } from './theme.reducer';
import { loaderReducer } from './loader.reducer';
import { errorMessage } from './error.reducer';
import { questionStatistics } from './statistics.reducer';
import { profileReducer } from './profile.reducer';
import { ongoingLevel } from './level.reducer';
import { questionLoadEffect } from './questionLoadEffect.reducer';
import { loggedUser } from './loggedUser.reducer';
import { nextLevelCoinsInfo } from './nextLevelCoinsInfo.reducer';
import { timerReducer } from './timer.reducer';
import { learningContent } from './learningContent.reducer';
import { coinsReducer } from './coins.reducer';
import { statisticsClasses } from './statisticsClasses.reducer';
import { roomDetails } from './roomDetails.reducer';
import { previewReducer } from './preview.reducer';
import { tenantReducer } from './tenant.reducer';
import { languageLoadedReducer } from './languageLoaded.reducer';
import { communityStatReducer } from './communityStat.reducer';
import { deleteUserContent } from './deleteUserContent.reducer';
import { adminMessage } from './adminMessage.reducer';
import { purchaseItemsReducer } from './purchaseItemsReducer.reducer';
import { spendableCoins } from './spendableCoins.reducer';
import { readOnlyReducer } from './readOnly.reducer';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  question: questionReducer,
  user: userReducer,
  theme: themeReducer,
  loader: loaderReducer,
  error: errorMessage,
  statistics: questionStatistics,
  profile: profileReducer,
  level: ongoingLevel,
  questionLoadEffect,
  loggedUser,
  nextLevelCoinsInfo,
  timer: timerReducer,
  learningContent,
  coins: coinsReducer,
  statisticsClasses,
  roomDetails,
  preview: previewReducer,
  tenant: tenantReducer,
  languageLoaded: languageLoadedReducer,
  communityStat: communityStatReducer,
  deleteUserContent,
  adminMessage,
  purchaseItems: purchaseItemsReducer,
  spendableCoins,
  readOnlyMode: readOnlyReducer,
});

export default rootReducer;
