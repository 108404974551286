export const READABLE_COUNT = 100;

export const levelType = {
  BEGINNER: 1,
  ADVANCED: 2,
  EXPERT: 3,
};

export const badgeType = {
  WLB: 'WLB',
  FRB: 'FRB',
  LB3: 'LB3',
  LB5: 'LB5',
  ACL: 'ACL',
  ACR: 'ACR',
};

export const roomStatus = {
  ROOM_PUBLISHED: 'PUBLISHED',
  ROOM_AWAITING_APPROVAL: 'AWAITING APPROVAL',
  ROOM_DRAFT: 'DRAFT',
  ROOM_UNPUBLISHED: 'UNPUBLISHED',
  ROOM_PREVIEW: 'PREVIEW',
};

export const profileCard = {
  DEFAULT_LOAD: 'DEFAULT_LOAD',
  SHOW_ALL: 'SHOW ALL',
  SHOW_FEWER: 'SHOW_FEWER',
  ANIMATE_COIN: 'ANIMATE_COIN',
  IS_UPDATED: 'IS_UPDATED',
};
