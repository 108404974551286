/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { statistics } from '../constants';

const initialState = {};

export function questionStatistics(state = initialState, action) {
    switch (action.type) {
        case statistics.ALLOW_ACCESS:
            return action.payload;
        case statistics.AVOID_ACCESS:
            return {};
        default: {
            return state;
        }
    }
}
