/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userAction, startLoading } from '../../actions';
import { getPrivateTenantProperties, getPublicTenantProperties } from '../../services/tenant-properties.service';
import { tenantSetCoacherAvatar, tenantSetLogo } from '../../actions/tenant.action';

function OverviewLoadingScreen() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startLoading());

        getPublicTenantProperties().then((response) => {
            const { data } = response.data;
            dispatch(tenantSetCoacherAvatar(data.coacherAvatar));
        });

        getPrivateTenantProperties().then((response) => {
            const { data } = response;
            dispatch(tenantSetLogo(data.logo));
        });

        const timer = setTimeout(() => {
            dispatch(userAction.validateUser());
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    return <></>;
}

export { OverviewLoadingScreen };
