/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

function FastestAnswerFeedback() {
    return (
        <div className="reward-card__animation">
            <svg xmlns="http://www.w3.org/2000/svg" width="385.1" height="235.5" viewBox="0 0 385.1 235.5">
                <g id="clock">
                    <circle fill="none" stroke="rgb(var(--primaryRGB))" strokeWidth="4" cx="176.6" cy="186.5" r="47" />
                    <line
                        fill="none"
                        stroke="rgb(var(--primaryRGB))"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="176.6"
                        y1="191.3"
                        x2="200"
                        y2="180.7"
                    />
                    <line
                        id="clock-hand"
                        fill="none"
                        stroke="rgb(var(--primaryRGB))"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="176.6"
                        y1="156.1"
                        x2="176.6"
                        y2="191.3"
                    />
                </g>
                <g id="stars">
                    <polyline
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.64"
                        points="97.6,141.1 96.3,148.6 89.1,150.9 95.8,154.5 95.8,162.1 101.2,156.8 108.4,159.2 105.1,152.3 109.5,146.2 102,147.3 97.6,141.1"
                    />
                    <polyline
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.64"
                        points="48.9,6.3 39.8,20.7 23.2,19.2 33.7,32.4 27.1,48.2 42.6,41.9 55.1,53.1 54.2,36.1 68.6,27.2 52.5,22.9 48.9,6.3"
                    />
                    <polygon
                        fill="rgb(var(--primaryRGB))"
                        points="31.8,139.3 47.5,145.3 40.6,129.6 50.8,116.2 34.3,118 24.9,103.8 21.6,120.4 5.6,125 20.1,133.6 19.6,150.7"
                    />
                    <polygon
                        fill="rgb(var(--primaryRGB))"
                        points="266.6,140 272.2,145.2 272,137.6 278.7,134 271.4,131.8 270,124.3 265.6,130.5 258.1,129.6	262.7,135.7 259.5,142.5"
                    />
                    <polygon
                        fill="rgb(var(--primaryRGB))"
                        points="272.9,67.8 289,72.4 280.7,57.4 289.7,43.2 273.4,46.4 262.9,33.1 261,49.9 245.5,55.9 260.7,63.2 261.6,80.2"
                    />
                    <polygon
                        fill="rgb(var(--primaryRGB))"
                        points="175.3,113.6 180.6,116.8 179.2,110.7 183.9,106.7 177.7,106.2 175.3,100.5 172.9,106.2 166.6,106.7 171.4,110.7 169.9,116.8"
                    />
                    <polygon
                        fill="rgb(var(--primaryRGB))"
                        points="137.4,103.5 149.5,108.5 144.5,96.2 152.9,86.1 139.9,87 133,75.7 129.9,88.6 117.3,91.7 128.4,98.8 127.5,112"
                    />
                    <polyline
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.64"
                        points="224.7,76.6 217.8,87.9 204.9,87 213.2,97.1 208.3,109.5 220.3,104.4 230.2,112.9 229.4,99.7 240.4,92.6 227.8,89.5 224.7,76.6"
                    />
                    <polygon
                        fill="rgb(var(--primaryRGB))"
                        points="176.9,36.7 188.9,44.1 185.7,30.2 196.3,20.8 182.4,19.6 176.9,6.5 171.5,19.6 157.6,20.8 168.2,30.2 165,44.1"
                    />
                    <polyline
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.3"
                        points="360.1,76.9 351.2,91.4 334.7,90.3 345.3,103.3 339,119.1 354.4,112.6 367.1,123.6 366,106.6 380.1,97.5 364,93.4 360.1,76.9"
                    />
                </g>
            </svg>
        </div>
    );
}

export default FastestAnswerFeedback;
