import { profileCard } from '../constants';

export const defaultLoad = () => ({
  type: profileCard.DEFAULT_LOAD,
  payload: {
    showAllClick: false,
    showFewerClick: false,
  },
});

export const showAllBadgesClick = () => ({
  type: profileCard.SHOW_ALL,
  payload: {
    showAllClick: true,
    showFewerClick: false,
  },
});

export const showFewerBadgesClick = () => ({
  type: profileCard.SHOW_FEWER,
  payload: {
    showAllClick: false,
    showFewerClick: true,
  },
});

export const animateCoin = (state) => ({
  type: profileCard.ANIMATE_COIN,
  payload: {
    canAnimate: state,
  },
});

export const isProfileUpdated = (state) => ({
  type: profileCard.IS_UPDATED,
  payload: {
    isProfileUpdated: state,
  },
});
