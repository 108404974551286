/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isTimeOut } from '../../../../actions';

export const Timer = (props) => {
    const dispatch = useDispatch();

    const { duration = 0 } = props;
    const [animation, setAnimation] = useState('');

    useEffect(() => {
        dispatch(isTimeOut({ timePassed: false }));

        const timer = setTimeout(() => {
            setAnimation(`ticking-clock ${duration}s linear reverse forwards`);
        }, 2000);

        // To disable answering option
        const disabledTimer = setTimeout(
            () => {
                dispatch(isTimeOut({ timePassed: false, canAnswer: false }));
            },
            (duration + 2) * 1000,
        );

        // To submit question with additional seconds
        const dismissTimer = setTimeout(
            () => {
                dispatch(isTimeOut({ timePassed: true }));
            },
            (duration + 2) * 1000,
        );

        return () => {
            clearTimeout(timer);
            clearTimeout(disabledTimer);
            clearTimeout(dismissTimer);
        };
    }, []);

    return (
        <span className="teacher__ticking-clock">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M23.6,5.4c10.1,0,18.3,8.2,18.3,18.3c0,10.1-8.2,18.3-18.3,18.3C13.5,42,5.3,33.8,5.3,23.7C5.3,13.6,13.5,5.4,23.6,5.4"
                    />
                    <path
                        fill="rgb(var(--primaryRGB))"
                        d="M21.5,27.8v-1c0-0.8,0.2-1.6,0.5-2.2c0.4-0.6,1-1.3,2-2c0.9-0.7,1.5-1.2,1.8-1.6c0.3-0.4,0.4-0.9,0.4-1.4c0-0.6-0.2-1-0.6-1.3s-1-0.4-1.8-0.4c-1.3,0-2.8,0.4-4.5,1.3l-1.4-2.9c2-1.1,4-1.6,6.2-1.6c1.8,0,3.2,0.4,4.3,1.3c1.1,0.9,1.6,2,1.6,3.5c0,1-0.2,1.8-0.7,2.5s-1.3,1.5-2.5,2.4c-0.8,0.6-1.4,1.1-1.6,1.4c-0.2,0.3-0.3,0.8-0.3,1.3v0.8H21.5zM21,32.3c0-0.7,0.2-1.3,0.6-1.7s1-0.6,1.7-0.6c0.7,0,1.3,0.2,1.7,0.6c0.4,0.4,0.6,0.9,0.6,1.7c0,0.7-0.2,1.2-0.6,1.6c-0.4,0.4-1,0.6-1.7,0.6c-0.7,0-1.3-0.2-1.7-0.6C21.2,33.6,21,33,21,32.3z"
                    />
                    <path
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.3"
                        d="M23.6,42C13.5,42,5.3,33.8,5.3,23.7c0-10.1,8.2-18.3,18.3-18.3c9.1,0,16.7,6.7,18.1,15.5c0.2,1,0.3,2.1,0.3,3.2c0,5-2,9.5-5.3,12.7C33.5,40,29,42,24,42c-0.1,0-0.2,0-0.2,0C23.7,42,23.7,42,23.6,42 M24,0C24,0,24,0,24,0L24,0C24,0,24,0,24,0C17.4,0,11.4,2.7,7,7c-4.3,4.3-7,10.4-7,17c0,6.6,2.7,12.6,7,17c4.3,4.3,10.3,7,17,7c0,0,0,0,0,0c0,0,0,0,0,0c6.6,0,12.6-2.7,17-7c4.3-4.3,7-10.4,7-17c0-6.6-2.7-12.6-7-17C36.6,2.7,30.6,0,24,0"
                    />
                    <circle
                        id="ticking-fill"
                        fill="none"
                        stroke="rgb(var(--primaryRGB))"
                        strokeWidth="6"
                        strokeLinecap="round"
                        cx="24"
                        cy="24"
                        r="21"
                        style={{ animation: `${animation}` }}
                    />
                </g>
            </svg>
        </span>
    );
};
