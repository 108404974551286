/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { theme } from '../constants';

const initialState = {
    themeLogo: '',
    themeFont: '',
    themeBaseColor: '',
    lang: 'en', // TODO: another reducer
};

export function themeReducer(state = initialState, action) {
    switch (action.type) {
        case theme.SET_THEME:
            return action.payload;

        default: {
            return state;
        }
    }
}
