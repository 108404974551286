import { client, responseHelper } from '../../helpers';
import { game, language } from '../../constants';

export const attemptService = {
  getAttempt,
  getFinalResult,
};

function getAttempt(gameId = game.DEFAULT_GAME_ID, isPreview = 0) {
  return client.get(`/game/${gameId}?is_preview=${isPreview}`)
    .then(responseHelper.formatResponse)
    .catch(responseHelper.error);
}

function getFinalResult(attemptId, lang = language.DEFAULT) {
  return client.get(`attempts/${attemptId}/results?lang=${lang}`)
    .then(responseHelper.formatResponse)
    .catch(responseHelper.error);
}
