/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { questionTypes } from '../../../constants';
import QuestionContent from '../QuestionContent/QuestionContent';
import { common } from '../../../helpers';

function FillInTheBlanks(props) {
    const { question, changeEvent, selectedAnswers } = props;
    const [userEnteredAnswers, setUserEnteredAnswers] = useState({});
    const inputWrapperRefs = useRef([]);
    const inputRefs = useRef([]);

    useEffect(() => {
        if (selectedAnswers) {
            setUserEnteredAnswers(selectedAnswers);
        }
    }, [selectedAnswers]);

    const handleAnswerChange = (e, refIndex, initialWidth) => {
        const inputElement = inputRefs.current[refIndex];

        if (initialWidth < inputElement.scrollWidth) {
            inputRefs.current[refIndex].style.width = `${inputElement.scrollWidth}px`;
        } else {
            inputRefs.current[refIndex].style.width = `${initialWidth}px`;
        }

        changeEvent(e, refIndex);
    };

    return (
        <Fade bottom duration={500}>
            <>
                {question.file && <QuestionContent key={question.questionId} {...question} />}

                <div className="question__text">
                    {question.extraQuestion && <>{common.sanitizeString(question.extraQuestion)} </>}

                    <div className="question__fill-in-the-blanks-wrapper">
                        {question.question
                            .replace(/\[|\|#\|]/g, ' ')
                            .split('*****')
                            .reduce((prev, current, i) => {
                                if (!i) {
                                    return [current];
                                }

                                const answer = question.answers[i - 1].answer;
                                const inputWidth = question.answers[i - 1].answerWidth + 34;

                                return prev.concat(
                                    <div key={i} style={{ display: 'inline-block', position: 'relative' }}>
                                        <div
                                            ref={(el) => (inputWrapperRefs.current[i] = el)}
                                            className="input-hidden-label"
                                            aria-hidden="true"
                                        >
                                            {answer}
                                        </div>
                                        <input
                                            key={i}
                                            ref={(el) => (inputRefs.current[i] = el)}
                                            style={{width: `${inputWidth}px`}}
                                            type="text"
                                            className="question__input question__input-fill-in-the-blank"
                                            name={questionTypes.FILL_IN_THE_BLANKS}
                                            autoComplete="off"
                                            onChange={(e) => handleAnswerChange(e, i, inputWidth)}
                                        />
                                        <div className="dashed-line"></div>
                                    </div>,
                                    current,
                                );
                            }, [])}
                    </div>
                </div>
            </>
        </Fade>
    );
}

export { FillInTheBlanks };
