/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const AllCorrectRoomBadge = () => (
    <svg
        id="Badges-All-Correct-Room"
        xmlns="http://www.w3.org/2000/svg"
        width="63.99"
        height="64"
        viewBox="0 0 63.99 64"
    >
        <path
            id="Bg"
            d="M53.992,64H10A10,10,0,0,1,0,54V10.008C.2,4.417,5.21-.241,10,.01H53.992a10,10,0,0,1,10,10V54a10,10,0,0,1-10,10"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="Check"
            d="M34.94,26.532a1,1,0,0,1-.712-.3l-2.4-2.433a1,1,0,1,1,1.424-1.4l1.676,1.7,4.794-5.034a1,1,0,0,1,1.448,1.379l-5.505,5.781a1,1,0,0,1-.715.31Z"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="R"
            d="M15.1,51.324l-.772,3.637H11.65l2.112-9.994h2.646a4.357,4.357,0,0,1,2.639.683,2.4,2.4,0,0,1,.916,2.058,3.493,3.493,0,0,1-.486,1.859,3.068,3.068,0,0,1-1.442,1.21l1.531,3.528.273.656H16.927l-1.333-3.637Zm.431-2.03h.349a1.419,1.419,0,0,0,1.032-.372,1.392,1.392,0,0,0,.383-1.043.844.844,0,0,0-.237-.647.965.965,0,0,0-.686-.221H16Z"
            fill="#fff"
        />
        <path
            id="O"
            d="M26.655,44.81a3.682,3.682,0,0,1,2.765,1.015,3.892,3.892,0,0,1,.988,2.834,8.005,8.005,0,0,1-.679,3.326,5.366,5.366,0,0,1-1.877,2.31,4.859,4.859,0,0,1-2.776.8,3.807,3.807,0,0,1-2.84-1.018,3.864,3.864,0,0,1-1-2.831,7.818,7.818,0,0,1,.7-3.329,5.412,5.412,0,0,1,1.917-2.314,5,5,0,0,1,2.8-.8m1.053,3.876a2.008,2.008,0,0,0-.328-1.221,1.113,1.113,0,0,0-.95-.44,1.719,1.719,0,0,0-1.244.574,4.067,4.067,0,0,0-.907,1.579,6.451,6.451,0,0,0-.338,2.058q0,1.653,1.368,1.654a1.676,1.676,0,0,0,1.207-.567,3.9,3.9,0,0,0,.875-1.532,6.709,6.709,0,0,0,.317-2.1"
            fill="#fff"
        />
        <path
            id="O_02"
            data-name="O 02"
            d="M36.97,44.81a3.682,3.682,0,0,1,2.765,1.015,3.892,3.892,0,0,1,.988,2.834,8.005,8.005,0,0,1-.679,3.326,5.366,5.366,0,0,1-1.877,2.31,4.859,4.859,0,0,1-2.776.8,3.807,3.807,0,0,1-2.84-1.018,3.864,3.864,0,0,1-1-2.831,7.818,7.818,0,0,1,.7-3.329,5.412,5.412,0,0,1,1.917-2.314,5,5,0,0,1,2.8-.8m1.053,3.876a2.008,2.008,0,0,0-.328-1.221,1.113,1.113,0,0,0-.95-.44A1.719,1.719,0,0,0,35.5,47.6a4.067,4.067,0,0,0-.907,1.579,6.451,6.451,0,0,0-.338,2.058q0,1.653,1.368,1.654a1.676,1.676,0,0,0,1.207-.567,3.9,3.9,0,0,0,.875-1.532,6.709,6.709,0,0,0,.317-2.1"
            fill="#fff"
        />
        <path
            id="M"
            d="M45.174,54.961V47.79h-.055q-.458,2.85-.588,3.5l-.772,3.671H41.38l2.112-9.994h3.541v7.1h.055l3.268-7.1h3.6l-2.139,9.994h-2.4l.8-3.753q.342-1.559.738-2.98l.1-.438H51l-3.3,7.171Z"
            fill="#fff"
        />
        <path
            id="Circle_"
            data-name="Circle "
            d="M15238.675,17414.688a8.666,8.666,0,0,1-4.682-15.951,10.37,10.37,0,0,0,10.1,12.672c.326,0,.652-.014.971-.045.135-.012.284-.029.484-.057a8.674,8.674,0,0,1-6.065,3.344C15239.228,17414.674,15238.955,17414.688,15238.675,17414.688Z"
            transform="translate(-15213.697 -17373.025)"
            fill="#fff"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
            opacity="0.2"
        />
        <path id="Dust" d="M13.288,30.159a1,1,0,1,1-1-1,1,1,0,0,1,1,1" transform="translate(0.01 0.318)" fill="#fff" />
        <path
            id="Dust_02"
            data-name="Dust 02"
            d="M47.734,35.553a1,1,0,1,1-1-1,1,1,0,0,1,1,1"
            transform="translate(0.01 0.318)"
            fill="#fff"
        />
        <path
            id="Glimmer"
            d="M18.734,16.67a8.74,8.74,0,0,0-2.4-2.88,8.726,8.726,0,0,0,2.4-2.879,8.726,8.726,0,0,0,2.4,2.879,8.74,8.74,0,0,0-2.4,2.88"
            transform="translate(0.01 0.318)"
            fill="#fff"
            opacity="0.3"
        />
        <path
            id="Glimmer_02"
            data-name="Glimmer 02"
            d="M49.927,15.95a15.286,15.286,0,0,0-4.193-5.039,15.286,15.286,0,0,0,4.193-5.039,15.286,15.286,0,0,0,4.193,5.039,15.286,15.286,0,0,0-4.193,5.039"
            transform="translate(0.01 0.318)"
            fill="#fff"
            opacity="0.5"
        />
        <path
            id="Circle_03"
            data-name="Circle 03"
            d="M47.63,22.026A11.351,11.351,0,1,1,35.279,11.773,11.351,11.351,0,0,1,47.63,22.026"
            transform="translate(0.01 0.318)"
            fill="#fff"
        />
        <path
            id="Circle_02"
            data-name="Circle 02"
            d="M40.722,26.739a10.369,10.369,0,1,1-11.283-9.366,10.369,10.369,0,0,1,11.283,9.366"
            transform="translate(0.01 0.318)"
            fill="#fff"
            opacity="0.5"
        />
        <path
            id="Check-2"
            data-name="Check"
            d="M34.94,26.532a1,1,0,0,1-.712-.3l-2.4-2.433a1,1,0,1,1,1.424-1.4l1.676,1.7,4.794-5.034a1,1,0,0,1,1.448,1.379l-5.505,5.781a1,1,0,0,1-.715.31Z"
            transform="translate(0.01 0.318)"
            fill="rgb(var(--primaryRGB))"
        />
        <rect id="Bound" width="63.99" height="64" fill="none" />
    </svg>
);
