/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { buyItems } from '../constants';

const initialState = {
    itemIdToBuy: 0,
};

export function purchaseItemsReducer(state = initialState, action) {
    switch (action.type) {
        case buyItems.ITEM_TO_BE_PURCHASED:
            return action.payload;

        default: {
            return state;
        }
    }
}
