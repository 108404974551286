/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

const DynamicAudio = ({ path, isDownloadable = false, ...rest }) => {
    const [isFileMissing, setIsFileMissing] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setIsFileMissing(false);
    }, [path]);

    const onError = () => {
        setIsFileMissing(true);
    };

    return (
        <>
            {isFileMissing ? (
                <p className="media__not-found">{t('media_file_is_missing')}</p>
            ) : (
                <ReactPlayer
                    onError={onError}
                    url={path}
                    config={{
                        file: {
                            attributes: {
                                controlsList: !isDownloadable ? 'nodownload' : true,
                            },
                        },
                    }}
                    {...rest}
                />
            )}
        </>
    );
};

export default DynamicAudio;

DynamicAudio.propTypes = {
    path: PropTypes.string.isRequired,
};
