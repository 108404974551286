/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { level } from '../constants';

const initialState = {
    levelId: 0,
    attemptId: 0,
    roomId: 0,
};

export function ongoingLevel(state = initialState, action) {
    switch (action.type) {
        case level.ONGOING_LEVEL:
            return action.payload;

        default: {
            return state;
        }
    }
}
