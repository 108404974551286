/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { questionConstants } from '../constants';

const initialState = {};

export function questionReducer(state = initialState, action) {
    switch (action.type) {
        case questionConstants.FETCHING:
            return {
                questionFetching: true,
            };

        case questionConstants.FETCHED:
            return {
                questionFetched: true,
                questions: action.questions,
            };

        case questionConstants.FETCHING_ERROR:
            return {
                questionFetched: true,
            };
        default: {
            return state;
        }
    }
}
