/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Content from '../../Question/LearningContent/Content';
import { questionService } from '../../../services/question';
import { setRoomDetails } from '../../../actions/roomDetails.action';

const LearningContentPreview = ({ match }) => {
    const dispatch = useDispatch();
    const { contentId } = match.params;
    const [roomInfo, setRoomInfo] = useState({});
    const [learningContent, setLearningContent] = useState({});
    const dismissHandler = null;

    useEffect(() => {
        questionService.getContent(contentId).then((content) => {
            const roomData = {
                icon: content.roomIcon,
                roomTitle: content.roomTitle,
                levelPrefix: content.levelPrefix,
            };

            dispatch(setRoomDetails(roomData));

            // To smooth load
            const timer = setTimeout(() => {
                setRoomInfo(roomData);
                setLearningContent(content);
            }, 100);

            return () => clearTimeout(timer);
        });
    }, []);

    if (Object.keys(learningContent).length > 0) {
        return <Content content={learningContent} roomAndLevelInfo={roomInfo} dismissHandler={dismissHandler} />;
    }

    return null;
};

export default LearningContentPreview;
