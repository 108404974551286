/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

function Error(props) {
    const { numericCode = 404, messageTitle = '', messageBody = '', internalNumericCode = '' } = props;

    return (
        <div className="error-content">
            <img src="/frontend/img/logos/debroome.svg" alt="deBroome" className="logo" />
            <div className="text">
                <h1>
                    {numericCode} {messageTitle}
                </h1>
                <p>{messageBody}</p>

                {internalNumericCode && (
                    <p>
                        <span>Internal Reference: </span>
                        <span>{internalNumericCode}</span>
                    </p>
                )}
            </div>
            <div className="links">
                <ul>
                    <li>
                        <a href="/">Go back to start page</a>
                    </li>
                    <li>
                        <a href="mailto:support@deBroome.com" rel="noopener noreferrer">
                            Contact support@deBroome.com
                        </a>
                    </li>
                </ul>
            </div>
            <div className="footer">
                <p>
                    Powered by{' '}
                    <a href="https://debroome.com/" target="_blank" rel="noreferrer">
                        deBroome
                    </a>
                </p>
            </div>
        </div>
    );
}

export { Error };
