/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

function ActionBar({ onClickInvite }) {
    return (
        <div className="col-6 p-0 d-flex mb-4">
            <div className="pr-4">
                <button type="submit" className="btn btn-add-new btn__addUserButton--custom" onClick={onClickInvite}>
                    Invite User
                    <i className="demo-icon badad-icon-add_circle ml-4 bta-add-icon" />
                </button>
            </div>
        </div>
    );
}

ActionBar.propTypes = {
    onClickInvite: PropTypes.func.isRequired,
};

export default ActionBar;
