import { client, responseHelper } from '../../helpers';

export const questionService = {
  getQuestions,
  postAnswer,
  getContent,
};

function getQuestions(levelId) {
  return client.get(`levels/${levelId}/questions`)
    .then(responseHelper.formatResponse)
    .catch(responseHelper.error);
}

function postAnswer(levelId, questionId, questionData) {
  return client.post(`levels/${levelId}/questions/${questionId}/evaluations`, questionData)
    .then(responseHelper.formatResponse)
    .catch(responseHelper.error);
}

function getContent(contentId) {
  return client.get(`learning_content/${contentId}`)
    .then(responseHelper.formatResponse)
    .catch(responseHelper.error);
}
