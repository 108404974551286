/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { useSelector } from 'react-redux';
import { TeacherMessage } from '../../../components/Room/Teacher/Message/Message';
import { BaseRoutes } from '../../../constants';

function HardLearningStartingScreen(props) {
    const preview = useSelector((state) => state.preview);
    const { dismissHandler, roomId, levelId } = props;
    const message = <Trans i18nKey="good_job_ready_to_text_your_knowledge" />;
    let redirectUrl = `${BaseRoutes.ROOMS}/${roomId}${BaseRoutes.LEVELS}/${levelId}${BaseRoutes.QUESTION}`;

    if (preview.isPreview) {
        redirectUrl = `${BaseRoutes.PREVIEW}${BaseRoutes.ROOMS}/${roomId}${BaseRoutes.LEVELS}/${levelId}${BaseRoutes.QUESTION}`;
    }

    return (
        <>
            <div className="question__text">
                <Fade bottom duration={500}>
                    <div className="hard_learning_message_wrapper">
                        <TeacherMessage message={message} addClass="teacher__margin-bottom-0" />
                    </div>
                </Fade>
                <div className="text-center">
                    <Fade bottom delay={1700}>
                        <Link onClick={dismissHandler} className="btn btn-primary btn__margin-top-45" to={redirectUrl}>
                            <Trans i18nKey="im_ready" />
                        </Link>
                    </Fade>
                </div>
            </div>
        </>
    );
}

export { HardLearningStartingScreen };
