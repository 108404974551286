/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const DiamondsExplode = () => (
    <span className="diamonds__explode">
        <svg width="232.302" height="215.481" viewBox="-5 0 232.302 235.481" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-535 -206.318)" opacity="0.71">
                <circle
                    cx="3.201"
                    cy="3.201"
                    r="3.201"
                    transform="matrix(0.757672, 0, 0, 1.040929, 573.493591, 337.861359)"
                    fill="rgb(var(--primaryRGB))"
                />
                <circle
                    cx="564.578"
                    cy="279.014"
                    r="1.84"
                    fill="rgb(var(--primaryRGB))"
                    transform="matrix(0.789214, 0, 0, 1, 118.617249, 0)"
                />
                <circle
                    cx="1.92"
                    cy="1.92"
                    r="1.92"
                    transform="matrix(0.797996, 0, 0, 1.068237, 626.30011, 336.732147)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.462"
                />
                <path
                    d="M 537.705 312.69 C 536.686 310.315 535.252 308.234 533.502 306.592 C 535.252 304.952 536.685 302.872 537.705 300.497 C 538.724 302.872 540.158 304.952 541.907 306.592 C 540.158 308.234 538.723 310.314 537.705 312.69 Z"
                    fill="rgb(var(--primaryRGB))"
                    transform="matrix(-1, 0, 0, -1, 1075.408997, 613.187012)"
                />
                <circle
                    cx="1.84"
                    cy="1.84"
                    r="1.84"
                    transform="matrix(-1.002462, 0, 0, -1.349735, 557.485168, 327.447754)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.462"
                />
                <path
                    d="M 603.733 290.054 C 602.949 288.138 601.846 286.46 600.5 285.135 C 601.846 283.812 602.949 282.135 603.733 280.219 C 604.517 282.135 605.62 283.812 606.966 285.135 C 605.62 286.46 604.516 288.137 603.733 290.054 Z"
                    fill="rgb(var(--primaryRGB))"
                    transform="matrix(-1, 0, 0, -1, 1207.466003, 570.27298)"
                />
                <path
                    d="M 655.898 290.839 C 655.327 289.383 654.521 288.107 653.538 287.1 C 654.521 286.095 655.326 284.819 655.898 283.363 C 656.471 284.819 657.276 286.095 658.259 287.1 C 657.276 288.107 656.471 289.383 655.898 290.839 Z"
                    fill="rgb(var(--primaryRGB))"
                    transform="matrix(-1, 0, 0, -1, 1311.796997, 574.201996)"
                />
                <circle
                    cx="1.84"
                    cy="1.84"
                    r="1.84"
                    transform="matrix(1.105393, 0, 0, 1.427225, 694.200439, 282.378387)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.462"
                />
                <path
                    d="M 714.656 311.015 C 714.085 309.559 713.279 308.283 712.296 307.276 C 713.279 306.271 714.084 304.995 714.656 303.539 C 715.229 304.995 716.034 306.271 717.017 307.276 C 716.034 308.283 715.229 309.559 714.656 311.015 Z"
                    fill="rgb(var(--primaryRGB))"
                    transform="matrix(-1, 0, 0, -1, 1429.313049, 614.554016)"
                />
                <circle
                    cx="1.92"
                    cy="1.92"
                    r="1.92"
                    transform="matrix(0.747497, 0, 0, 0.999999, 687.818054, 337.062927)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.462"
                />
                <circle
                    cx="3.201"
                    cy="3.201"
                    r="3.201"
                    transform="matrix(0.818254, 0, 0, 1.040929, 701.178284, 324.383545)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.462"
                />
                <path
                    d="M 573.772 311.146 C 573.201 309.69 572.395 308.414 571.412 307.407 C 572.395 306.402 573.2 305.126 573.772 303.67 C 574.345 305.126 575.15 306.402 576.133 307.407 C 575.15 308.414 574.345 309.69 573.772 311.146 Z"
                    fill="rgb(var(--primaryRGB))"
                    transform="matrix(-1, 0, 0, -1, 1171.106406, 633.551366)"
                    opacity="0.462"
                />
                <circle
                    cx="1.92"
                    cy="1.92"
                    r="1.92"
                    transform="matrix(0.9495, 0, 0, 1.272949, 653.687744, 332.08429)"
                    fill="rgb(var(--primaryRGB))"
                    opacity="0.462"
                />
                <path
                    d="M 681.302 323.068 C 680.731 321.612 679.925 320.336 678.942 319.329 C 679.925 318.324 680.73 317.048 681.302 315.592 C 681.875 317.048 682.68 318.324 683.663 319.329 C 682.68 320.336 681.875 321.612 681.302 323.068 Z"
                    fill="rgb(var(--primaryRGB))"
                    transform="matrix(-1, 0, 0, -1, 1362.605042, 638.660004)"
                />
                <circle
                    cx="1.84"
                    cy="1.84"
                    r="1.84"
                    transform="matrix(-1.002462, 0, 0, -1.349734, 574.356323, 304.519989)"
                    fill="rgb(var(--primaryRGB))"
                />
            </g>
        </svg>
    </span>
);
