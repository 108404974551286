/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useRef } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';

const SortableElement = ({ id, index, height, cf, label }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

    const ref = useRef(null);

    useEffect(() => {
        const currentHeight = ref.current.clientHeight;
        return cf(currentHeight, true);
    }, []);

    const baseStyles = {
        transform: CSS.Transform.toString(transform),
        position: 'relative',
        zIndex: 1,
        width: '100%',
        transition,
    };

    let style = {};

    if (isDragging) {
        style = {
            ...baseStyles,
            zIndex: 2,
        };
    } else {
        style = {
            ...baseStyles,
        };
    }

    const cardStyle = {
        padding: isDragging ? '28px' : undefined,
        boxShadow: isDragging ? '0px 0px 12px 3px rgba(0,0,0,0.2)' : undefined,
        height: isDragging ? `${height + 70}px` : `${height + 44}px`,
    };

    return (
        <div className="drag-drop__item">
            <span className="drag-drop__number">{`0${index}`.slice(-2)}</span>
            <div className="outer-wrap">
                <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
                    <div className="card drag-drop__card" style={cardStyle}>
                        <div ref={ref}>{label}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SortableElement.prototype = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    lines: PropTypes.number.isRequired,
};

export default SortableElement;
