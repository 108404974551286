/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import PropTypes from 'prop-types';
import React from 'react';
import DynamicImage from '../../DynamicMedia/DynamicImage';

const InnerRoomImage = ({ image, alt }) => (
    <div className={`inner-room-image ${image ? 'loader' : 'solid'}`}>
        {image && <DynamicImage path={image} classNames="question__main-img" width="100%" alt={alt} />}
    </div>
);

export default InnerRoomImage;

InnerRoomImage.propTypes = {
    image: PropTypes.string,
};

InnerRoomImage.defaultProps = {
    image: null,
};
