/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import sanitizeHtml from 'sanitize-html';
import { questionTypes } from '../constants';

export const common = {
    cleanObject,
    isEmptyObject,
    blobToImage,
    sanitizeString,
    randomNumber,
    dummyTitle,
    sanitizeHtmlContent,
    sliceIntoChunks,
    separateNumberByThousands,
    getSubDomain,
};

function isEmptyObject(obj) {
    if (typeof obj === 'object') {
        return Object.keys(obj).length === 0;
    }
    return false;
}

function cleanObject(obj) {
    if (obj && typeof obj === 'object') {
        // eslint-disable-next-line no-restricted-syntax
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                // eslint-disable-next-line no-param-reassign
                delete obj[propName];
            }
        }
        return obj;
    }
    return false;
}

function blobToImage(blob) {
    return new Promise((resolve) => {
        const url = URL.createObjectURL(blob);
        const img = new Image();
        img.onload = () => {
            URL.revokeObjectURL(url);
            resolve(img);
        };
        img.src = url;
    });
}

function sanitizeString(content) {
    const tmpDom = document.createElement('div');
    tmpDom.innerHTML = content;
    return tmpDom.textContent || tmpDom.innerText || '';
}

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function dummyTitle(questionType) {
    if (typeof questionType === 'number') {
        switch (questionType) {
            case questionTypes.TRUE_FALSE:
                return 'dm_true_false_message';
            case questionTypes.FILL_IN_THE_BLANKS:
                return 'dm_fill_the_blank_message';
            case questionTypes.MULTIPLE_CHOICES:
                return 'dm_multiple_answer_message';
            case questionTypes.SEQUENTIAL:
                return 'dm_sequential_message';
            default:
                return '';
        }
    }
}

// <p> tags are allowed only
function sanitizeHtmlContent(html) {
    return sanitizeHtml(html, {
        allowedTags: ['p'],
    });
}

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

function separateNumberByThousands(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function getSubDomain() {
    try {
        const currentUrl = window.location.href;

        const url = new URL(currentUrl);
        const hostnameParts = url.hostname.split('.');

        if (hostnameParts.length >= 3) {
            return hostnameParts[0];
        } else {
            return null;
        }
    } catch (error) {
        console.log(error);
    }
}
