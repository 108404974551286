/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Trans } from 'react-i18next';
import { LineBar } from '../../Stats/ProgressElements/LineBar/LineBar';

const RoomActions = ({ completedRate, expectedTime }) => (
    <>
        <div className="d-flex justify-content-between align-items-center">
            {completedRate === 0 && (
                <>
                    <span className="badge bg-secondary">{expectedTime} Min</span>
                    <button type="button" className="btn btn-primary btn-xs">
                        <Trans i18nKey="start" />
                    </button>
                </>
            )}
        </div>
        {completedRate > 0 && <LineBar value={completedRate} />}
    </>
);

export default RoomActions;
