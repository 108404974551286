import { client, responseHelper } from '../../helpers';

export const roomService = {
  getRooms,
};

function getRooms() {
  return client.get('/get-rooms')
    .then(responseHelper.formatResponse)
    .catch(responseHelper.error);
}
