/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const Glimmer = ({ size = 24, color = 'rgb(var(--primaryRGB))' }) => (
    <svg
        id="Icon_-_LearningCoin"
        data-name="Icon - LearningCoin"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
    >
        <path
            id="Path_110"
            data-name="Path 110"
            d="M379.68,220.714a28.523,28.523,0,0,0-7.5-9.5,28.54,28.54,0,0,0,7.5-9.5,28.505,28.505,0,0,0,7.5,9.5A28.488,28.488,0,0,0,379.68,220.714Z"
            transform="translate(-368.449 -199.215)"
            fill={color}
        />
        <path
            id="Path_428"
            data-name="Path 428"
            d="M375.18,208.714a10.7,10.7,0,0,0-3-3.5,10.7,10.7,0,0,0,3-3.5,10.689,10.689,0,0,0,3,3.5A10.683,10.683,0,0,0,375.18,208.714Z"
            transform="translate(-356.949 -200.279)"
            fill={color}
        />
    </svg>
);
