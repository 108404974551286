/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

// eslint-disable-next-line import/no-extraneous-dependencies
import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';

// To be removed before go to the production
// Logger disabled
// const loggerMiddleware = createLogger();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunkMiddleware];

export const store = createStore(rootReducer, composeEnhancer(applyMiddleware(...middleware)));
