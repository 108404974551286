/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { ProfilePicturePlaceHolder } from '../Icons';

export function AvatarStack({ avatars = [], height = 42 }) {
    return (
        <div className="avatars-stack">
            {avatars.map((avatarUrl, index) => (
                <div
                    key={index}
                    className={`avatars-stack__item avatars-stack__avatar--size-${height} ${!avatarUrl ? 'avatars-stack__item--border' : ''}`}
                >
                    {avatarUrl ? (
                        <img src={avatarUrl} alt="avatar messages" />
                    ) : (
                        <ProfilePicturePlaceHolder height={height} width={height} />
                    )}
                </div>
            ))}
        </div>
    );
}

AvatarStack.defaultProps = {
    height: '32',
};
