/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import animationData from './loading';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

function Loader() {
    const loader = useSelector((state) => state.loader);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (loader.loading) {
            setShowLoader(true);
        }

        // When the loader stops, keep a slight delay to the stop to prevent the loader from not going full circle.
        if (showLoader && !loader.loading) {
            const timer = setTimeout(() => {
                setShowLoader(false);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [loader.loading]);

    if (showLoader) {
        return (
            <div className="loading">
                <Lottie options={defaultOptions} height={400} width={400} />
            </div>
        );
    }
    return null;
}

export { Loader };
