/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CookieService from '../../services/CookieService/CookieService';

const CookieNotice = () => {
    const { t } = useTranslation();
    const [isShowCookieNotice, setIsShowCookieNotice] = useState(false);
    const [isShowCookiePreferenceForm, setIsShowCookiePreferenceForm] = useState(false);
    const [currentShownAccordionPanel, setCurrentShownAccordionPanel] = useState(null);
    const [functionalCookiesAccepted, setFunctionalCookiesAccepted] = useState(true);
    const [trackingCookiesAccepted, setTrackingCookiesAccepted] = useState(true);

    useEffect(() => {
        if (!CookieService.getCookie('cookies_accepted')) {
            setIsShowCookieNotice(true);
        }
    }, []);

    useEffect(() => {
        if (isShowCookiePreferenceForm) {
            // Setting the focus on the open box to focus to keep the flow of the tab
            document.getElementById('cookie-preference-form').focus();
        }
    }, [isShowCookiePreferenceForm]);

    const saveUserCookiePreferences = (acceptAll) => {
        const functionalCookies = acceptAll === true ? true : functionalCookiesAccepted;
        const trackingCookies = acceptAll === true ? true : trackingCookiesAccepted;

        CookieService.setCookie('cookies_accepted', true, 365);
        CookieService.setCookie('functional_cookies_accepted', functionalCookies, 365);
        CookieService.setCookie('tracking_cookies_accepted', trackingCookies, 365);

        setIsShowCookieNotice(false);
        setIsShowCookiePreferenceForm(false);
    };

    const acceptAllCookies = () => {
        saveUserCookiePreferences(true);
    };

    const acceptSelectedCookies = () => {
        saveUserCookiePreferences(false);
    };

    const showCookiePreferencesForm = () => {
        setIsShowCookiePreferenceForm(true);
    };

    const hideCookiePreferencesForm = () => {
        setIsShowCookiePreferenceForm(false);
    };

    const functionalCookiesOptionChanged = () => {
        setFunctionalCookiesAccepted(!functionalCookiesAccepted);
    };

    const trackingCookiesOptionChanged = () => {
        setTrackingCookiesAccepted(!trackingCookiesAccepted);
    };

    const toggleAccordionPanel = (panel) => {
        panel = panel === currentShownAccordionPanel ? null : panel;
        setCurrentShownAccordionPanel(panel);
    };

    return (
        <div className="cookie-notice-container">
            {isShowCookieNotice && (
                <div className="cookie-notice-bottom">
                    <div className="cookie-notice-contents">
                        <span className="cookie-notice-text">
                            <Trans
                                i18nKey="cookie_notice_text"
                                components={[
                                    <a
                                        target="_blank"
                                        href="https://debroome.com/product-cookie-policy/"
                                        rel="noreferrer"
                                    >
                                        <Trans i18nKey="cookie_policy" />
                                    </a>,
                                    <a
                                        target="_blank"
                                        href="https://debroome.com/product-privacy-policy/"
                                        rel="noreferrer"
                                    >
                                        <Trans i18nKey="privacy_policy" />
                                    </a>,
                                ]}
                            />
                        </span>
                        <span className="cookie-notice-buttons">
                            <button type="button" className="options-btn dark-btn" onClick={showCookiePreferencesForm}>
                                <Trans i18nKey="preferences" />
                            </button>
                            <button type="button" className="options-btn light-btn" onClick={acceptAllCookies}>
                                <Trans i18nKey="accept_all" />
                            </button>
                        </span>
                    </div>
                </div>
            )}
            {isShowCookiePreferenceForm && (
                <div className="cookie-preference-form-area" tabIndex="-1" id="cookie-preference-form">
                    <div className="cookie-preference-form">
                        <div className="cookie-preference-form__title">
                            <>
                                <Trans i18nKey="cookie" />
                                <Trans i18nKey="preferences" />
                            </>

                            <button type="button" className="close" onClick={hideCookiePreferencesForm} />
                        </div>
                        <div className="cookie-preference-form__body">
                            <div className="cookie-form-desc">
                                <Trans
                                    i18nKey="cookie_form_desc"
                                    components={[
                                        <br />,
                                        <a
                                            target="_blank"
                                            href="https://debroome.com/product-cookie-policy/"
                                            rel="noreferrer"
                                        >
                                            https://debroome.com/product-cookie-policy
                                        </a>,
                                        <a
                                            target="_blank"
                                            href="https://debroome.com/product-privacy-policy/"
                                            rel="noreferrer"
                                        >
                                            https://debroome.com/product-privacy-policy
                                        </a>,
                                    ]}
                                />
                            </div>

                            <div className="cookie-options">
                                <div>
                                    <div className="cookie-row">
                                        <div className="cookie-card-btn">
                                            <div className="cookie-option-card">
                                                <button
                                                    type="button"
                                                    className="cookie-chevron"
                                                    onClick={() => toggleAccordionPanel('essential')}
                                                    aria-label="See the description"
                                                    aria-expanded={currentShownAccordionPanel === 'essential'}
                                                />
                                                <span
                                                    className="cookie-option-desc"
                                                    onClick={() => toggleAccordionPanel('essential')}
                                                >
                                                    <Trans i18nKey="essential_cookies" />
                                                </span>
                                                <span className="debroome-switch ml-auto">
                                                    <input
                                                        id="essential_cookies"
                                                        className="debroome-switch__input"
                                                        type="checkbox"
                                                        defaultChecked
                                                        disabled
                                                    />
                                                    <label
                                                        className="debroome-switch__label"
                                                        htmlFor="essential_cookies"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={`accordion__panel${currentShownAccordionPanel === 'essential' ? ' active ' : ''}`}
                                            aria-expanded={currentShownAccordionPanel === 'essential'}
                                        >
                                            <p>
                                                <Trans i18nKey="essential_cookies_desc" />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="cookie-row">
                                        <div className="cookie-card-btn">
                                            <div className="cookie-option-card">
                                                <button
                                                    type="button"
                                                    className="cookie-chevron"
                                                    onClick={() => toggleAccordionPanel('functional')}
                                                    aria-label="See the description"
                                                    aria-expanded={currentShownAccordionPanel === 'functional'}
                                                />
                                                <span
                                                    className="cookie-option-desc"
                                                    onClick={() => toggleAccordionPanel('functional')}
                                                >
                                                    <Trans i18nKey="functional_cookies" />
                                                </span>
                                                <span className="debroome-switch ml-auto">
                                                    <input
                                                        id="functional_cookies"
                                                        className="debroome-switch__input"
                                                        type="checkbox"
                                                        defaultChecked={functionalCookiesAccepted}
                                                        onClick={functionalCookiesOptionChanged}
                                                    />
                                                    <label
                                                        className="debroome-switch__label"
                                                        htmlFor="functional_cookies"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={`accordion__panel${currentShownAccordionPanel === 'functional' ? ' active ' : ''}`}
                                            aria-expanded={currentShownAccordionPanel === 'functional'}
                                        >
                                            <p>
                                                <Trans i18nKey="functional_cookies_desc" />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="cookie-row">
                                        <div className="cookie-card-btn">
                                            <div className="cookie-option-card">
                                                <button
                                                    type="button"
                                                    className="cookie-chevron"
                                                    onClick={() => toggleAccordionPanel('tracking')}
                                                    aria-expanded={currentShownAccordionPanel === 'tracking'}
                                                />
                                                <span
                                                    className="cookie-option-desc"
                                                    onClick={() => toggleAccordionPanel('tracking')}
                                                >
                                                    <Trans i18nKey="tracking_cookies" />
                                                </span>
                                                <span className="debroome-switch ml-auto">
                                                    <input
                                                        id="tracking_cookies"
                                                        className="debroome-switch__input"
                                                        type="checkbox"
                                                        defaultChecked={trackingCookiesAccepted}
                                                        onClick={trackingCookiesOptionChanged}
                                                    />
                                                    <label
                                                        className="debroome-switch__label"
                                                        htmlFor="tracking_cookies"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={`accordion__panel${currentShownAccordionPanel === 'tracking' ? ' active ' : ''}`}
                                            aria-expanded={currentShownAccordionPanel === 'tracking'}
                                        >
                                            <p>
                                                <Trans i18nKey="tracking_cookies_desc" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cookie-options-buttons">
                            <button type="button" className="options-btn dark-btn" onClick={acceptAllCookies}>
                                <Trans i18nKey="accept_all" />
                            </button>
                            <button type="button" className="options-btn light-btn" onClick={acceptSelectedCookies}>
                                <Trans i18nKey="accept_selected" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CookieNotice;
