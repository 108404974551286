/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const LockIcon = () => (
    <span className="level-select__level-status level-select__level-status--locked">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="27.015" viewBox="0 0 20 27.015">
            <g transform="translate(-6 -1.985)">
                <g transform="translate(6 14)" fill="none" stroke="#2b2b2b" strokeLinejoin="round" strokeWidth="2">
                    <rect width="20" height="15" stroke="none" />
                    <rect x="1" y="1" width="18" height="13" fill="none" />
                </g>
                <path
                    d="M19.427,13.133V6.2a5.026,5.026,0,0,0-5.04-5.133A4.912,4.912,0,0,0,9.427,6.2v6.929"
                    transform="translate(1.547 1.914)"
                    fill="none"
                    stroke="#2b2b2b"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    </span>
);
