/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const TextBadge = (props) => {
    const { variant = 'bg-secondary', text = '', ...rest } = props;
    return (
        <span className={`badge ${variant}`} {...rest}>
            {text}
        </span>
    );
};
