/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar } from '../Avatar/Avatar';
import AnimatedNumber from 'react-animated-numbers';
import { Glimmer } from '../Icons/Glimmer';

export function SpendableCoins(props) {
    const { currency } = props;
    const [currentSpendableCoins, setCurrentSpendableCoins] = useState(null);
    const spendableCoins = useSelector((state) => state.spendableCoins.coins);
    const [animateCoins, setAnimateCoins] = useState(false);

    useEffect(() => {
        if (currentSpendableCoins) {
            setAnimateCoins(true);
            const timer = setTimeout(() => {
                setCurrentSpendableCoins(spendableCoins);
            }, 600);

            return () => clearTimeout(timer);
        } else {
            setAnimateCoins(false);
            setCurrentSpendableCoins(spendableCoins);
        }
    }, [spendableCoins]);

    return (
        <>
            <div className="card shop__card card__spendable-coins">
                <div className="card__title">
                    <Trans i18nKey="spendable_learning_coins" />
                </div>
                <div className="card__content">
                    <div className="content_left">
                        <Avatar avatar="/frontend/img/avatar-user.png" />
                    </div>
                    <div className="content_right">
                        <div className="spendable-coins">
                            <div className="glimmer-icon">
                                <Glimmer />
                            </div>
                            {animateCoins && currentSpendableCoins !== spendableCoins ? (
                                <AnimatedNumber
                                    animateToNumber={spendableCoins}
                                    configs={(number, index) => {
                                        return { mass: 1, tension: 90 * (index + 1), friction: 100 };
                                    }}
                                />
                            ) : (
                                currentSpendableCoins
                            )}
                        </div>
                        <span className="shop__currency">{currency}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
