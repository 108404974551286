import { readOnlyModeActions } from '../constants';

const initialState = {
  readOnly: false,
};

export function readOnlyReducer(state = initialState, action) {
  switch (action.type) {
    case readOnlyModeActions.SET_READ_ONLY_MODE:
      return action.payload;
    default: {
      return state;
    }
  }
}
