import { statistics } from '../constants';

export const statisticActions = {
  allowAccess,
  avoidAccess,
};

function allowAccess(parameters) {
  return {
    type: statistics.ALLOW_ACCESS,
    payload: parameters,
  };
}

function avoidAccess() {
  return {
    type: statistics.AVOID_ACCESS,
    payload: {
      show: false,
    },
  };
}
