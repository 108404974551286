/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Timer } from '../../../Stats/ProgressElements/QuestionTimer/Timer';
import { common } from '../../../../helpers';
import PositiveFeedback from './images/PositiveFeedback';
import DynamicCoacherAvatar from '../../../DynamicMedia/DynamicCoacherAvatar';

export const TeacherMessage = (props) => {
    const {
        message = [],
        addClass = '',
        alt = '',
        feedback = false,
        delay = 800,
        bottom = true,
        top = false,
        isHardLearningQuestion = false,
        duration = 10,
        questionId = null,
        bubbleTextClasses = '',
        messageClasses = '',
        animationDuration = 1000,
        distance = '100%',
    } = props;

    const { t } = useTranslation();
    const [messageReceived, setMessageReceived] = useState(false);
    const coacherAvatar = useSelector((state) => state.tenant.tenantCoacherAvatar);

    useEffect(() => {
        setMessageReceived(false);
        const timer = window.setTimeout(() => {
            setMessageReceived(true);
        }, delay + 500);

        return () => window.clearTimeout(timer);
    }, []);

    const StaticTimingMessage = () => (
        <>
            <Trans i18nKey="hurry_before_time_runs_out" />
        </>
    );

    return (
        <Fade bottom={bottom} top={top} delay={delay} duration={animationDuration} distance={distance}>
            <div className={`teacher ${addClass}`}>
                <span className="teacher__avatar">
                    <DynamicCoacherAvatar
                        path={coacherAvatar}
                        alt={alt || t('avatar_image')}
                        className="teacher__avatar-image"
                    />
                </span>
                <span className="teacher__bubble">
                    <span className={`teacher__bubble-text ${bubbleTextClasses}`}>
                        <span className={messageClasses}>
                            {typeof message === 'string' ? common.sanitizeString(message) : message}
                        </span>

                        {isHardLearningQuestion && (
                            <>
                                <br />
                                <StaticTimingMessage />
                            </>
                        )}
                    </span>
                    {isHardLearningQuestion && <Timer key={questionId} duration={duration} />}
                    {feedback && messageReceived && <PositiveFeedback />}
                </span>
            </div>
        </Fade>
    );
};
