/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

function PositiveFeedback() {
    return (
        <span className="teacher__explode">
            <svg xmlns="http://www.w3.org/2000/svg" width="212.302" height="215.481" viewBox="0 0 212.302 215.481">
                <g transform="translate(-535 -206.318)" opacity="0.71">
                    <path
                        d="M425.156,199.5a4.6,4.6,0,1,0-7.717-4.882,4.6,4.6,0,1,0-7.717,4.882c.6.733,4.289,4.154,6.341,6.044a2.026,2.026,0,0,0,2.753,0C420.868,203.657,424.552,200.236,425.156,199.5Z"
                        transform="translate(126.331 191.325)"
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.462"
                    />
                    <path
                        d="M428.729,201.138a5.6,5.6,0,1,0-9.389-5.94,5.6,5.6,0,1,0-9.39,5.94c.736.892,5.219,5.054,7.716,7.354a2.465,2.465,0,0,0,3.349,0C423.511,206.191,427.994,202.029,428.729,201.138Z"
                        transform="translate(327.928 67.442) rotate(3)"
                        fill="rgb(var(--primaryRGB))"
                    />
                    <path
                        d="M428.729,201.138a5.6,5.6,0,1,0-9.389-5.94,5.6,5.6,0,1,0-9.39,5.94c.736.892,5.219,5.054,7.716,7.354a2.465,2.465,0,0,0,3.349,0C423.511,206.191,427.994,202.029,428.729,201.138Z"
                        transform="translate(169.116 14.354)"
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.428"
                    />
                    <path
                        d="M428.729,201.138a5.6,5.6,0,1,0-9.389-5.94,5.6,5.6,0,1,0-9.39,5.94c.736.892,5.219,5.054,7.716,7.354a2.465,2.465,0,0,0,3.349,0C423.511,206.191,427.994,202.029,428.729,201.138Z"
                        transform="translate(313.87 39.203)"
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.428"
                    />
                    <path
                        d="M428.729,201.138a5.6,5.6,0,1,0-9.389-5.94,5.6,5.6,0,1,0-9.39,5.94c.736.892,5.219,5.054,7.716,7.354a2.465,2.465,0,0,0,3.349,0C423.511,206.191,427.994,202.029,428.729,201.138Z"
                        transform="translate(165.774 317.73) rotate(-14)"
                        fill="rgb(var(--primaryRGB))"
                    />
                    <path
                        d="M420.705,197.468a3.361,3.361,0,1,0-5.634-3.564,3.36,3.36,0,1,0-5.634,3.564c.442.535,3.131,3.032,4.629,4.413a1.479,1.479,0,0,0,2.009,0C417.574,200.5,420.264,198,420.705,197.468Z"
                        transform="translate(268.318 69.235)"
                        fill="rgb(var(--primaryRGB))"
                    />
                    <path
                        d="M420.705,197.468a3.361,3.361,0,1,0-5.634-3.564,3.36,3.36,0,1,0-5.634,3.564c.442.535,3.131,3.032,4.629,4.413a1.479,1.479,0,0,0,2.009,0C417.574,200.5,420.264,198,420.705,197.468Z"
                        transform="translate(140.032 74.389)"
                        fill="rgb(var(--primaryRGB))"
                    />
                    <path
                        d="M420.705,197.468a3.361,3.361,0,1,0-5.634-3.564,3.36,3.36,0,1,0-5.634,3.564c.442.535,3.131,3.032,4.629,4.413a1.479,1.479,0,0,0,2.009,0C417.574,200.5,420.264,198,420.705,197.468Z"
                        transform="translate(235.669 181.014)"
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.235"
                    />
                    <circle
                        cx="5.76"
                        cy="5.76"
                        r="5.76"
                        transform="translate(649.819 270.163)"
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.266"
                    />
                    <circle
                        cx="5.76"
                        cy="5.76"
                        r="5.76"
                        transform="translate(693.631 383.289)"
                        fill="rgb(var(--primaryRGB))"
                    />
                    <circle
                        cx="3.201"
                        cy="3.201"
                        r="3.201"
                        transform="translate(667.74 360.134)"
                        fill="rgb(var(--primaryRGB))"
                    />
                    <circle
                        cx="5.76"
                        cy="5.76"
                        r="5.76"
                        transform="translate(599.903 274.714)"
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.208"
                    />
                    <circle
                        cx="3.84"
                        cy="3.84"
                        r="3.84"
                        transform="translate(685.952 310.217)"
                        fill="rgb(var(--primaryRGB))"
                        opacity="0.342"
                    />
                    <circle
                        cx="3.84"
                        cy="3.84"
                        r="3.84"
                        transform="translate(624.167 279.506)"
                        fill="rgb(var(--primaryRGB))"
                    />
                    <circle
                        cx="1.84"
                        cy="1.84"
                        r="1.84"
                        transform="translate(579.609 248.351)"
                        fill="rgb(var(--primaryRGB))"
                    />
                    <circle
                        cx="1.92"
                        cy="1.92"
                        r="1.92"
                        transform="translate(624.167 352.454)"
                        fill="rgb(var(--primaryRGB))"
                    />
                </g>
            </svg>
        </span>
    );
}

export default PositiveFeedback;
