import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProfilePercentage } from '../Stats/ProgressElements/ProfilePercentage/ProfilePercentage';
import { ProfileType } from '../Stats/ProgressElements/ProfileType/ProfileType';
import { LearningCoins } from '../Stats/ProgressElements/LearningCoins/LearningCoins';
import { IconBadge } from '../Badges/IconBadge/IconBadge';
import { Button } from '../Form/Button/Button';
import { common, history } from '../../helpers';
import { BaseRoutes, rewards } from '../../constants';
import '../../helpers/i18n';
import { showAllBadgesClick } from '../../actions';
import { CommunityStatus } from '../Icons/CommunityStatus';
import { Shop } from '../Icons';

function Profile(props) {
    const {
        className = 'profile-card',
        holdCoinsNewValueSet = false,
        actionButtons = true,
        showCoinsCountUpAnimation = false,
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authentication = useSelector((state) => state.authentication);
    const nextLevelCoinsInfo = useSelector((state) => state.nextLevelCoinsInfo);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [avatarImage, setAvatar] = useState('');
    const [fullName, setFullName] = useState('');
    const [levelReadTitle, setLevelReadTitle] = useState('');
    const [achievedCoins, setAchievedCoins] = useState(0);
    const [badges, setBadges] = useState([]);
    const [pendingBadges, setPendingBadges] = useState([]);
    const [completionRate, setCompletionRate] = useState(0);
    const [coinsNeedToAchieveNextLevel, setCoinsNeedToAchieveNextLevel] = useState({});
    const [currentAvailableBadgesCount, setCurrentAvailableBadgesCount] = useState(0);
    const [badgeContents, setBadgesContents] = useState([]);
    const [emptyBadges, setEmptyBadges] = useState([]);
    const [totalNoOfBadgesPerRow, seTotalNoOfBadgesPerRow] = useState(rewards.TOTAL_NO_OF_BADGES_PER_ROW);
    const [isShopAvailable, setIsShopAvailable] = useState(false);

    useEffect(() => {
        // Set avatar from the auth state quickly if that is not empty
        if (Object.keys(authentication).length > 0 && typeof authentication.user !== 'undefined') {
            const authUser = authentication.user;
            const badgeContents = [];

            // eslint-disable-next-line no-restricted-globals
            if (screen.width < 1920) {
                seTotalNoOfBadgesPerRow(rewards.TOTAL_NO_OF_BADGES_PER_ROW_FOR_SMALL_SCREEN);
            } else {
                seTotalNoOfBadgesPerRow(rewards.TOTAL_NO_OF_BADGES_PER_ROW);
            }

            setAvatar(authUser.avatar);
            setFullName(`${authUser.first_name} ${authUser.last_name}`);
            setLevelReadTitle(authUser.level_read_title_translation_key);
            setAchievedCoins(authUser.achieved_coins);
            setBadges(authUser.badges);
            setPendingBadges(authUser.pending_badges);
            setCompletionRate(authUser.completion_rate);

            if (typeof authUser.badges !== 'undefined' && typeof authUser.pending_badges !== 'undefined') {
                setCurrentAvailableBadgesCount(authUser.badges.length + authUser.pending_badges.length);

                authUser.badges.map((badge) => {
                    if (badgeContents.length < totalNoOfBadgesPerRow) {
                        badgeContents.push(
                            <IconBadge
                                variant={badge.css_class_name}
                                title={t(badge.translation_key)}
                                key={badge.id}
                            />,
                        );
                    }
                });

                authUser.pending_badges.map((badge) => {
                    if (badgeContents.length < totalNoOfBadgesPerRow) {
                        badgeContents.push(
                            <IconBadge
                                variant={badge.css_class_name}
                                additionalClass="learning-badge--pending"
                                key={`'pending-${badge.id}'`}
                                title={t(badge.translation_key)}
                            />,
                        );
                    }
                });

                setBadgesContents(badgeContents);

                // Maximum badges count in the profile card is 4 or 3(for small screen)
                if (
                    currentAvailableBadgesCount < totalNoOfBadgesPerRow &&
                    currentAvailableBadgesCount % totalNoOfBadgesPerRow > 0
                ) {
                    const emptyBadgesContents = [];
                    const total = totalNoOfBadgesPerRow - (currentAvailableBadgesCount % totalNoOfBadgesPerRow);

                    for (let i = 0; i < total; i++) {
                        emptyBadgesContents.push(
                            <IconBadge
                                variant="learning-badge"
                                additionalClass="learning-badge--empty"
                                key={`'empty-${i}'`}
                            />,
                        );
                    }

                    setEmptyBadges(emptyBadgesContents);
                }
            }
        }

        if (Object.keys(nextLevelCoinsInfo).length > 0) {
            const { coinInfo } = nextLevelCoinsInfo;
            setCoinsNeedToAchieveNextLevel(coinInfo.nextLevelCoinsInfo);
        }
    }, [authentication, nextLevelCoinsInfo, currentAvailableBadgesCount, screenWidth]);

    useEffect(() => {
        checkForShopAvailability();
    }, []);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const levelSelect = () => {
        history.push(BaseRoutes.ROOMS);
    };

    const viewAllBadges = () => {
        dispatch(showAllBadgesClick());
    };

    const goToStatsOverview = () => {
        history.push(BaseRoutes.STATS_OVERVIEW);
    };

    const handleShopButtonClick = () => {
        history.push(BaseRoutes.SHOP);
    };

    const checkForShopAvailability = () => {
        // const isProduction = process.env.NODE_ENV.trim() === 'production';
        // const subDomain = common.getSubDomain();
        // setIsShopAvailable(isProduction ? subDomain === 'demo' : true);
        const url = new URL(window.location.href);

        const domainSections = url.hostname.split('.');
        const topDomain = `${domainSections[domainSections.length - 2]}.${domainSections[domainSections.length - 1]}`;

        if (
            topDomain === 'brandlearning.app' &&
            domainSections[0] !== 'demo' &&
            domainSections[0] !== 'intrum' &&
            domainSections[0] !== 'babybjorn' &&
            domainSections[0] !== 'uniplay'
        ) {
            setIsShopAvailable(false);
        } else {
            setIsShopAvailable(true);
        }
    };

    return (
        <div className={className}>
            <ProfilePercentage profileImage={avatarImage} completionRate={completionRate} />

            {Object.keys(coinsNeedToAchieveNextLevel).length > 0 && (
                <span className="badge rounded-pill badge--coin">
                    {coinsNeedToAchieveNextLevel.needMore} <Trans i18nKey="coins_to_become" />{' '}
                    {coinsNeedToAchieveNextLevel.nextLevelReadTitleName}
                </span>
            )}

            <div className="profile-card__profile-name">{fullName}</div>

            <ProfileType icon="/frontend/images/heart.svg" label={levelReadTitle} />

            <LearningCoins
                label={<Trans i18nKey="learning_coins" />}
                holdCoinsNewValueSet={holdCoinsNewValueSet}
                currentUserAchievedCoins={achievedCoins}
                showCoinsCountUpAnimation={showCoinsCountUpAnimation}
            />

            {typeof badges !== 'undefined' && typeof pendingBadges !== 'undefined' && (
                <div className="profile-card__learning-badge-wrapper">
                    <div className="d-flex justify-content-between">
                        <span className="profile-card__badge-count-lbl">
                            {badges.length} <Trans i18nKey="out_of" /> {currentAvailableBadgesCount}{' '}
                            <Trans i18nKey="badges" />
                        </span>
                        {currentAvailableBadgesCount > totalNoOfBadgesPerRow && (
                            <a href="#" className="profile-card__badge-show-link" onClick={viewAllBadges}>
                                <Trans i18nKey="show_all" />
                            </a>
                        )}
                    </div>
                    <div className="profile-card__learning-badge-inner">
                        {badgeContents.map((d) => d)}
                        <div className="empty-badges">{emptyBadges}</div>
                    </div>
                </div>
            )}

            <div className="profile-card__action-btn-wrapper">
                {actionButtons && (
                    <Button size="md" onClick={levelSelect} variant="btn-primary btn-block">
                        <Trans i18nKey="continue" />
                    </Button>
                )}
                <div className="profile-card__action-btn-row">
                    <span role="button" onClick={goToStatsOverview}>
                        <CommunityStatus size={20} />
                        <span className="profile-card__action-btn-row__text">
                            <Trans i18nKey="community_stats" />
                        </span>
                    </span>
                    {isShopAvailable && (
                        <span role="button" className="profile-card__shop-btn">
                            <Shop />
                            <span
                                className="profile-card__action-btn-row__text profile-card__shop-btn__text"
                                onClick={handleShopButtonClick}
                            >
                                <Trans i18nKey="shop" />
                            </span>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

export { Profile };
