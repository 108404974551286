/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../Table/Table';
import TableRecordSet from './TableRecordSet';

function UserTable({ users }) {
    const headers = ['Full name', 'Email', 'Last login', 'Invited at', 'Accepted'];

    const tableRecordSet = <TableRecordSet users={users} />;

    return (
        <div className="ad-card-lis">
            <div className="card card-shadow rpb-gap">
                <Table headers={headers} recordSet={tableRecordSet} />
            </div>
        </div>
    );
}

UserTable.propTypes = {
    users: PropTypes.arrayOf(Object).isRequired,
};

export default UserTable;
