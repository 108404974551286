import { theme } from '../constants';

export const themeSetAction = (logo = '') => ({
  type: theme.SET_THEME,
  payload: {
    themeLogo: logo,
    themeFont: '',
    themeBaseColor: '',
  },
});
