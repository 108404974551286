/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import useSound from 'use-sound';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../../../components/Form/Button/Button';
import { TeacherMessage } from '../../../components/Room/Teacher/Message/Message';
import { FastestAnswer } from '../../../components/Room/Teacher/Message/FastestAnswer';
import { sounds } from '../../../constants';
import { common } from '../../../helpers';

function QuestionFeedBack(props) {
    const { feedback, handleFeedback } = props;
    const { t } = useTranslation();
    const isPositive = !!feedback.isCorrect;
    const { isFastestAnswer } = feedback;
    const [audioId, setAudioId] = useState(0);
    const successMessages = [t('good_job'), t('hurray'), t('well_done')];

    const rightMusic = [
        '/frontend/sounds/result_right/results-right1.wav',
        '/frontend/sounds/result_right/results-right2.wav',
        '/frontend/sounds/result_right/results-right3.wav',
    ];

    const wrongMusic = [
        '/frontend/sounds/result-wrong/results-wrong1.wav',
        '/frontend/sounds/result-wrong/results-wrong2.wav',
        '/frontend/sounds/result-wrong/results-wrong3.wav',
    ];

    const [message, setMessage] = useState('');

    const [rightPlay] = useSound(rightMusic[audioId], { volume: 1 });

    const [wrongPlay] = useSound(wrongMusic[audioId], { volume: 1 });

    useEffect(() => {
        const avatarMessage =
            !feedback.excludeHeader && !isPositive ? `${common.sanitizeString(feedback.feedbackTitle)} \n` : '';
        setMessage(`${avatarMessage} ${isPositive ? successMessages[common.randomNumber(0, 2)] : ''}`);
        return () => setAudioId(common.randomNumber(0, 2));
    }, []);

    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            if (sounds.ON) {
                if (isFastestAnswer !== false) {
                    rightPlay();
                } else if (isPositive) {
                    rightPlay();
                } else {
                    wrongPlay();
                }
            }
        }, 500);

        return () => clearTimeout(timer);
    });

    if (isFastestAnswer !== false) {
        return <FastestAnswer time={isFastestAnswer.toString()} handleFeedback={handleFeedback} />;
    }
    return (
        <div className="feedback h-100">
            <div className="container d-flex align-items-center h-100">
                <div className="feedback-inner">
                    <TeacherMessage
                        message={message}
                        feedback={isPositive}
                        messageClasses="teacher__display-linebreak"
                        bottom
                        animationDuration={500}
                    />

                    <Fade bottom delay={1000} duration={500}>
                        <div className="feedback__text response">
                            <p className="feedback__text__margin-bottom-0">
                                {common.sanitizeString(feedback.feedback)}
                            </p>
                        </div>
                    </Fade>

                    <Fade bottom delay={2000}>
                        <div className="text-center">
                            <Button size="md" marginTop="btn__margin-top-45" onClick={handleFeedback}>
                                <Trans i18nKey="soft_feedback_next" />
                            </Button>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export { QuestionFeedBack };
