/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import AnimatedNumber from 'react-animated-numbers';
import { Glimmer } from '../Icons/Glimmer';

export function SpendableCoinsFooter(props) {
    const { currency } = props;
    const [currentSpendableCoins, setCurrentSpendableCoins] = useState(null);
    const spendableCoins = useSelector((state) => state.spendableCoins.coins);
    const [animateCoins, setAnimateCoins] = useState(false);

    useEffect(() => {
        if (currentSpendableCoins) {
            setAnimateCoins(true);
            const timer = setTimeout(() => {
                setCurrentSpendableCoins(spendableCoins);
            }, 600);

            return () => clearTimeout(timer);
        } else {
            setAnimateCoins(false);
            setCurrentSpendableCoins(spendableCoins);
        }
    }, [spendableCoins]);

    return (
        <Fade bottom={true}>
            <div className="spendable-coins-mobile-widget">
                <div className="title">
                    <Trans i18nKey="spendable_learning_coins" />
                </div>

                <div className="coins-info">
                    <div className="glimmer-icon">
                        <Glimmer />
                    </div>
                    <div className="spendable-coins">
                        {animateCoins && currentSpendableCoins !== spendableCoins ? (
                            <AnimatedNumber
                                animateToNumber={spendableCoins}
                                configs={(number, index) => {
                                    return { mass: 1, tension: 90 * (index + 1), friction: 100 };
                                }}
                            />
                        ) : (
                            currentSpendableCoins
                        )}
                    </div>
                    <span className="currency">{currency}</span>
                </div>
            </div>
        </Fade>
    );
}
