/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { userConstants } from '../constants/user';
import { userService } from '../services/user';
import { history } from '../helpers';
import { BaseRoutes } from '../constants';
import { isProfileUpdated } from './profileCardToggle.action';

export const userAction = {
    validateUser,
    updateUser,
};

function validateUser(isPreview) {
    return (dispatch) => {
        dispatch(validateRequest());

        userService
            .getUser()
            .then((user) => {
                dispatch(validate(user));

                if (!isPreview) {
                    history.push(BaseRoutes.OVERVIEW);
                }
            })
            .catch((error) => {
                dispatch(validationFail());
            });
    };

    function validateRequest() {
        return { type: userConstants.VALIDATE_USER_REQUEST };
    }

    function validate(user) {
        return { type: userConstants.VALIDATE_USER, user };
    }

    function validationFail() {
        return { type: userConstants.INVALID_USER };
    }
}

// For update user details(coins,etc..) after complete the level.
function updateUser() {
    return (dispatch) => {
        userService
            .getUser()
            .then((user) => {
                dispatch(update(user));
                dispatch(isProfileUpdated(true));
            })
            .catch((error) => {
                dispatch(fail());
            });
    };

    function update(user) {
        return { type: userConstants.UPDATE_USER, user };
    }

    function fail() {
        return { type: userConstants.UPDATE_USER_FAILED };
    }
}
