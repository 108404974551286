/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedNumber from 'react-animated-numbers';
import { DiamondsExplode } from '../../../ExplodeAnimations';
import { coinsSet } from '../../../../actions';

export const LearningCoins = (props) => {
    const { holdCoinsNewValueSet = false, currentUserAchievedCoins = 0, showCoinsCountUpAnimation = false } = props;

    const dispatch = useDispatch();
    const coins = useSelector((state) => state.coins);

    const [coinsStoredInComponentState, setCoinsStoredInComponentState] = useState(coins.coins);
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // To increase the smoothness use two timeouts here for
        // the animation and set the currentUserAchievedCoins
        if (!holdCoinsNewValueSet && coinsStoredInComponentState < currentUserAchievedCoins) {
            const coinAnimationTimer = setTimeout(() => {
                if (showCoinsCountUpAnimation) {
                    setStartAnimation(true);
                }
            }, 1000);

            const storedCoinTimer = setTimeout(() => {
                setCoinsStoredInComponentState(currentUserAchievedCoins);
                dispatch(coinsSet(currentUserAchievedCoins));
            }, 1200);

            return () => {
                clearTimeout(coinAnimationTimer);
                clearTimeout(storedCoinTimer);
            };
        }
    }, [currentUserAchievedCoins]);

    return (
        <>
            <div className="profile-card__coin-count-wrapper">
                <div className="profile-card__coin-count">
                    {startAnimation && <DiamondsExplode />}
                    {startAnimation && coinsStoredInComponentState !== currentUserAchievedCoins ? (
                        <AnimatedNumber animateToNumber={currentUserAchievedCoins} />
                    ) : (
                        coinsStoredInComponentState
                    )}
                </div>
            </div>
            <div className="profile-card__coin-lbl">
                <Trans i18nKey="learning_coins" />
            </div>
        </>
    );
};
