/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { useSelector } from 'react-redux';

function AdminLoader() {
    const loader = useSelector((state) => state.loader);

    if (loader && loader.loading) {
        return (
            <div className="bta-loader-parent admin-react-loader">
                <div className="bta-loader" />
            </div>
        );
    }

    return null;
}

export default AdminLoader;
