/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const WelcomeBadge = () => (
    <svg id="Badge-Hello" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <path
            id="Bg"
            d="M54,64H10A10,10,0,0,1,0,54V10A10,10,0,0,1,10,0H54A10,10,0,0,1,64,10V54A10,10,0,0,1,54,64"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="Bubble"
            d="M15423.073,17432.613v-7.1h-7.443a5.634,5.634,0,0,1-5.631-5.633v-24.25a5.636,5.636,0,0,1,5.631-5.631h36.883a5.634,5.634,0,0,1,5.632,5.631v24.25a5.631,5.631,0,0,1-5.632,5.633h-16.085l-10.056,8.619a1.962,1.962,0,0,1-1.288.484A2,2,0,0,1,15423.073,17432.613Z"
            transform="translate(-15402.072 -17378.961)"
            fill="#fff"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
        />
        <path
            id="H"
            d="M23.6,35.03H21.031V29.792H16.224V35.03H13.651V22.894h2.573v4.757h4.807V22.894H23.6Z"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="E"
            d="M29.554,35.2a4.789,4.789,0,0,1-3.5-1.236,4.685,4.685,0,0,1-1.262-3.5,5.146,5.146,0,0,1,1.166-3.606,4.152,4.152,0,0,1,3.225-1.275A4.088,4.088,0,0,1,32.244,26.7a4.243,4.243,0,0,1,1.1,3.1v1.229H27.355a2.436,2.436,0,0,0,.639,1.685,2.249,2.249,0,0,0,1.677.606,6.977,6.977,0,0,0,1.586-.174,7.909,7.909,0,0,0,1.56-.557V34.54a5.88,5.88,0,0,1-1.42.494,8.864,8.864,0,0,1-1.843.162M29.2,27.377a1.6,1.6,0,0,0-1.262.511,2.368,2.368,0,0,0-.523,1.448h3.553a2.106,2.106,0,0,0-.49-1.448,1.656,1.656,0,0,0-1.278-.511"
            fill="rgb(var(--primaryRGB))"
        />
        <rect id="L" width="2.531" height="12.916" transform="translate(34.633 22.113)" fill="rgb(var(--primaryRGB))" />
        <rect
            id="L_02"
            data-name="L 02"
            width="2.531"
            height="12.916"
            transform="translate(38.716 22.113)"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="O"
            d="M51.573,30.374a4.988,4.988,0,0,1-1.2,3.544A4.336,4.336,0,0,1,47.049,35.2a4.661,4.661,0,0,1-2.358-.585,3.888,3.888,0,0,1-1.568-1.68,5.654,5.654,0,0,1-.548-2.557,4.963,4.963,0,0,1,1.186-3.537A4.363,4.363,0,0,1,47.1,25.575a4.687,4.687,0,0,1,2.358.582,3.883,3.883,0,0,1,1.569,1.668,5.6,5.6,0,0,1,.548,2.549m-6.417,0a3.892,3.892,0,0,0,.453,2.083,1.615,1.615,0,0,0,1.473.706,1.591,1.591,0,0,0,1.457-.7,3.95,3.95,0,0,0,.444-2.087,3.831,3.831,0,0,0-.448-2.067,1.907,1.907,0,0,0-2.931,0,3.825,3.825,0,0,0-.448,2.071"
            fill="rgb(var(--primaryRGB))"
        />
        <rect id="Bound" width="64" height="64" fill="none" />
    </svg>
);
