/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_LOGIN_PATH, AUTH_SSO_URL } from '../../constants/appUrls';
import { baseClient } from '../../helpers/httpClient';
import { BaseRoutes } from '../../constants';
import { tenantSetCoacherAvatar, tenantSetHasSsoIntegration, tenantSetName } from '../../actions/tenant.action';
import { getPublicTenantProperties } from '../../services/tenant-properties.service';
import PreLoginWrapper from '../../components/PreLoginWrapper/PreLoginWrapper';

function Login({ history }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [submitButtonStatus, setSubmitButtonStatus] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tenantName = useSelector((state) => state.tenant.tenantName);
    const sso = useSelector((state) => state.tenant.tenantHasSsoIntegration);
    const coacherAvatar = useSelector((state) => state.tenant.tenantCoacherAvatar);

    useEffect(() => {
        if (!tenantName || !coacherAvatar) {
            getPublicTenantProperties().then((response) => {
                const { data } = response.data;
                dispatch(tenantSetName(data.name));
                dispatch(tenantSetHasSsoIntegration(data.sso));
                dispatch(tenantSetCoacherAvatar(data.coacherAvatar));
            });
        }
    }, []);

    useEffect(() => {
        if (email && password && validator.isEmail(email) && password.length > 4) {
            setSubmitButtonStatus(true);
        } else {
            setSubmitButtonStatus(false);
        }
        setError('');
    }, [email, password]);

    const onSubmit = async (event) => {
        // Don't submit form regularly
        event.preventDefault();

        try {
            // Login successful if 204 NO CONTENT
            const res = await baseClient.post(
                AUTH_LOGIN_PATH,
                {
                    email,
                    password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );

            // 204 = Authentication OK
            if (res.status === 204) {
                history.push(BaseRoutes.HOME);
                // Non 204 Status Code
            } else {
                const { message } = await res.data;
                setError(message);
            }
        } catch (e) {
            const { message } = await e.response.data;
            setError(message);
        }
    };

    return (
        <PreLoginWrapper tenantName={tenantName} coacherAvatar={coacherAvatar}>
            <form onSubmit={onSubmit}>
                <div className="login__form-wrapper">
                    <div className="login__inputs-group">
                        <div className="login__input-container">
                            <input
                                type="text"
                                className={`login__text-field ${error ? 'login__text-field--error' : ''}`}
                                id="email"
                                required
                                value={email}
                                autoComplete="off"
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <label className="place-holder" htmlFor="email">
                                <Trans i18nKey="email" />
                            </label>
                        </div>
                        <div className="login__input-container">
                            <input
                                type="password"
                                className={`login__text-field ${error ? 'login__text-field--error' : ''}`}
                                id="password"
                                required
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            <label htmlFor="password">
                                <Trans i18nKey="password" />
                            </label>
                        </div>
                        {error && <div className="login--error text-wrap">{error}</div>}
                    </div>
                    <button
                        type="submit"
                        disabled={!submitButtonStatus}
                        className="btn btn-primary btn-lg login__button"
                    >
                        <Trans i18nKey="login" />
                    </button>
                    {sso && (
                        <a
                            href={AUTH_SSO_URL}
                            type="button"
                            className="btn btn-outline-primary btn-lg login__button login__secondary-button"
                        >
                            <Trans i18nKey="login_with_sso" />
                        </a>
                    )}
                </div>
                <div className="login__forgot-password-wrapper">
                    <div className="login__forgot-password-group">
                        <div className="login__help-wrapper justify-content-center">
                            <div>
                                <Trans i18nKey="forgot_password" />{' '}
                                <Link to={BaseRoutes.FORGOT_PASSWORD}>
                                    <Trans i18nKey="reset_password" />
                                </Link>
                            </div>
                            <div>
                                <Trans i18nKey="need_an_account" /> <Trans i18nKey="contact_administrator" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </PreLoginWrapper>
    );
}

export default Login;
