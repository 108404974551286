/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Modal } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { GlitteringBox } from '../Icons';

export function DefaultModal(props) {
    const {
        show,
        modalImageNoBottom,
        modalPurchasedDone,
        modalImage,
        modalTitle,
        modalDescription,
        modalPrice,
        modalPriceCurrency,
        closeModalCallBack,
        okButtonClickCallBack,
        buyButtonClickCallBack,
    } = props;

    return (
        <Modal
            show={show}
            onHide={closeModalCallBack}
            backdrop="static"
            className={`purchase-modal ${modalImageNoBottom ? 'modal-top' : ''}`}
            keyboard={false}
        >
            <Modal.Body>
                <div className="item__info">
                    {!modalPurchasedDone && (
                        <div className={`item__image ${modalImageNoBottom ? 'item__image-no-bottom' : ''}`}>
                            <img src={modalImage} alt="" />
                        </div>
                    )}

                    {modalPurchasedDone && (
                        <Fade>
                            <div className="item__image">
                                <GlitteringBox />
                            </div>
                        </Fade>
                    )}

                    <div className="item__purchase-info">
                        <div className="item__description">
                            <span className={`title ${modalPurchasedDone ? 'full-width' : ''}`}>{modalTitle}</span>
                            <p
                                className={`description ${modalPurchasedDone ? 'full-width' : ''}`}
                                dangerouslySetInnerHTML={{ __html: modalDescription }}
                            />
                        </div>
                        {modalPrice && (
                            <div className="item__price">
                                <span className="shop__price">{modalPrice}</span>
                                <span className="shop__currency">{modalPriceCurrency}</span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="item__actions">
                    {modalPurchasedDone ? (
                        <Fade>
                            <button type="button" className="btn btn-primary" onClick={okButtonClickCallBack}>
                                OK
                            </button>
                        </Fade>
                    ) : (
                        <>
                            <button type="button" className="btn btn-outline-primary" onClick={closeModalCallBack}>
                                CANCEL
                            </button>
                            <button type="button" className="btn btn-primary btn-buy" onClick={buyButtonClickCallBack}>
                                BUY
                            </button>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}
