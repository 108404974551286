/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

function Caret({ down = false }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.4" viewBox="0 0 12 7.4">
            {down ? (
                <path d="M10.59,0,6,4.574,1.41,0,0,1.408,6,7.4l6-5.992Z" />
            ) : (
                <path d="M 10.59 7.4 L 6 2.826 L 1.41 7.4 L 0 5.992 L 6 0 L 12 5.992 Z" />
            )}
        </svg>
    );
}

Caret.propTypes = {
    down: PropTypes.bool,
};

Caret.defaultProps = {
    down: false,
};

export default Caret;
