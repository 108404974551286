export const tenantSetName = (tenantName) => ({
  type: 'SET_TENANT_NAME',
  payload: tenantName,
});

export const tenantSetWelcomeText = (tenantWelcomeText) => ({
  type: 'SET_TENANT_WELCOME_TEXT',
  payload: tenantWelcomeText,
});

export const tenantSetHasSsoIntegration = (hasSsoIntegration) => ({
  type: 'SET_TENANT_HAS_SSO_INTEGRATION',
  payload: hasSsoIntegration,
});

export const tenantSetLogo = (logo) => ({
  type: 'SET_TENANT_LOGO',
  payload: logo,
});

export const tenantSetCoacherAvatar = (avatar) => ({
  type: 'SET_COACHER_AVATAR',
  payload: avatar,
});
