import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DynamicImage = ({
  path, alt, classNames, ...rest
}) => {
  const [isFileMissing, setIsFileMissing] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsFileMissing(false);
  }, [path]);

  const onError = () => {
    setIsFileMissing(true);
  };

  return (
    <>
      {isFileMissing ? <p className="media__not-found">{t('media_file_is_missing')}</p>
        : (
          <img
            src={path}
            onError={onError}
            alt={alt}
            className={classNames}
            {...rest}
          />
        )}
    </>
  );
};

export default DynamicImage;

DynamicImage.propTypes = {
  path: PropTypes.string.isRequired,
  alt: PropTypes.string,
  classNames: PropTypes.string,
};

DynamicImage.defaultProps = {
  alt: 'image',
  classNames: '',
};
