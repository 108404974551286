/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import './IconBadge.css';
import { Tooltip } from 'react-tippy';
import { badgeType } from '../../../constants';
import 'react-tippy/dist/tippy.css';
import { WelcomeBadge } from './images/WelcomeBadge';
import { FirstRoomBadge } from './images/FirstRoomBadge';
import { AllCorrectLevelBadge } from './images/AllCorrectLevelBadge';
import { Login3DaysBadge } from './images/Login3DaysBadge';
import { AllCorrectRoomBadge } from './images/AllCorrectRoomBadge';
import { Login5DaysBadge } from './images/Login5DaysBadge';

export const IconBadge = (props) => {
    const { variant = 'learning-badge--hej', additionalClass = '', title = '' } = props;

    return (
        <span className={`learning-badge ${additionalClass}`}>
            {title && (
                <Tooltip title={title} position="top" arrow distance={20}>
                    <span>
                        {variant === badgeType.WLB && <WelcomeBadge />}
                        {variant === badgeType.FRB && <FirstRoomBadge />}
                        {variant === badgeType.LB3 && <Login3DaysBadge />}
                        {variant === badgeType.LB5 && <Login5DaysBadge />}
                        {variant === badgeType.ACL && <AllCorrectLevelBadge />}
                        {variant === badgeType.ACR && <AllCorrectRoomBadge />}
                    </span>
                </Tooltip>
            )}
        </span>
    );
};
