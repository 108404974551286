import { client } from '../../helpers';

export const nextLevelCoinsService = {
  getNextLevelCoins,
};

function getNextLevelCoins() {
  return client.get('next-level-coins')
    .then((response) => response.data)
    .catch(this.error);
}
