import { adminAlertMessage } from '../constants';

export const showSuccess = (message) => ({
  type: adminAlertMessage.SHOW_SUCCESS,
  payload: {
    success: true,
    message,
  },
});

export const showError = (message) => ({
  type: adminAlertMessage.SHOW_ERROR,
  payload: {
    success: false,
    message,
  },
});

export const resetMessage = () => ({
  type: adminAlertMessage.RESET,
  payload: {},
});
