/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const CheckCircle = ({ size = 48, color = 'rgb(var(--primaryRGB))' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 50 50">
        <circle id="Ellipse_577" data-name="Ellipse 577" cx="25" cy="25" r="25" fill={color} opacity="0.1" />
        <path
            id="Check"
            d="M-1.758,10.163,5.53,17.548,22.242,0"
            transform="translate(14.958 17.227)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
    </svg>
);
