/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { client, responseHelper } from '../../helpers';

export const levelService = {
    getLevelsByRoom,
    getLevelPosition,
    restartLevel,
};

function getLevelsByRoom(roomId) {
    return client.get(`/rooms/${roomId}/levels`).then(responseHelper.formatResponse).catch(responseHelper.error);
}

function getLevelPosition(parameters) {
    if (typeof parameters.levelId !== 'undefined' && typeof parameters.attemptId !== 'undefined') {
        return client
            .post(`/levels/${parameters.levelId}/attempts/${parameters.attemptId}/position`, {
                roomId: parameters.roomId,
                userType: parameters.userType,
                attemptId: parameters.attemptId,
            })
            .then(responseHelper.formatResponse)
            .catch(responseHelper.error);
    }
}

function restartLevel(levelId, levelAttemptId) {
    return client
        .post(`/levels/${levelId}/restartLevel`, {
            attemptId: levelAttemptId,
        })
        .then(responseHelper.formatResponse)
        .catch(responseHelper.error);
}
