/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const Login5DaysBadge = () => (
    <svg id="Badge-Login-5" xmlns="http://www.w3.org/2000/svg" width="64" height="64.01" viewBox="0 0 64 64.01">
        <path
            id="Bg"
            d="M54,64.01H10a10,10,0,0,1-10-10v-44C.2,4.418,5.211-.241,10,.01H54a10,10,0,0,1,10,10v44a10,10,0,0,1-10,10"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="Screen"
            d="M53.2,52.337H11.283A3.04,3.04,0,0,1,8.242,49.3V19.879h48V49.3a3.04,3.04,0,0,1-3.04,3.04"
            fill="#fff"
            opacity="0.2"
        />
        <path id="Dust" d="M44.108,24.388a1.091,1.091,0,1,1-1.091-1.106,1.1,1.1,0,0,1,1.091,1.106" fill="#fff" />
        <path
            id="Glimmer_02"
            data-name="Glimmer 02"
            d="M17.306,41.648a9.644,9.644,0,0,0-2.614-3.185,9.63,9.63,0,0,0,2.614-3.184,9.63,9.63,0,0,0,2.614,3.184,9.644,9.644,0,0,0-2.614,3.185"
            fill="#fff"
            opacity="0.5"
        />
        <path
            id="Header"
            d="M56.243,17.667h-48V14.394a3.625,3.625,0,0,1,3.84-3.362H52.4a3.625,3.625,0,0,1,3.84,3.362Z"
            fill="#fff"
            opacity="0.6"
        />
        <path id="Head" d="M38.782,32.312a6.818,6.818,0,1,1-6.818-6.818,6.818,6.818,0,0,1,6.818,6.818" fill="#fff" />
        <path
            id="Body"
            d="M45.487,52.22v-1.6a8.02,8.02,0,0,0-8.041-8H26.482a8.02,8.02,0,0,0-8.041,8v1.6Z"
            fill="#fff"
        />
        <path
            id="Circel"
            d="M25.8,15.593a10.362,10.362,0,1,1-11.275-9.36A10.361,10.361,0,0,1,25.8,15.593"
            fill="#fff"
        />
        <path
            id="Five"
            d="M16.08,14.848a2.882,2.882,0,0,1,1.465.377,2.6,2.6,0,0,1,1.017,1.066,3.351,3.351,0,0,1,.365,1.59,3.069,3.069,0,0,1-.951,2.422,4.219,4.219,0,0,1-2.875.844,6.157,6.157,0,0,1-2.692-.509V18.551a6.754,6.754,0,0,0,1.266.426,5.394,5.394,0,0,0,1.214.167,1.718,1.718,0,0,0,1.087-.3,1.055,1.055,0,0,0,.38-.885,1.012,1.012,0,0,0-.385-.843,1.88,1.88,0,0,0-1.173-.3,4.223,4.223,0,0,0-1.294.225l-.934-.463.354-4.972H18.3v2.118H15.088l-.116,1.243a5.963,5.963,0,0,1,.714-.106c.143-.006.274-.01.394-.01"
            fill="rgb(var(--primaryRGB))"
        />
        <path
            id="Gimmer"
            d="M46.4,42.256a16.841,16.841,0,0,0-4.575-5.573A16.851,16.851,0,0,0,46.4,31.11a16.851,16.851,0,0,0,4.575,5.573A16.841,16.841,0,0,0,46.4,42.256"
            fill="#fff"
            opacity="0.5"
        />
        <rect id="Bound" width="64" height="64.01" fill="none" />
    </svg>
);
