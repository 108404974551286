/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

const initialState = {
    tenantName: '',
    tenantWelcomeText: '',
};

export function tenantReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_TENANT_NAME':
            return { ...state, tenantName: action.payload };

        case 'SET_TENANT_WELCOME_TEXT':
            return { ...state, tenantWelcomeText: action.payload };

        case 'SET_TENANT_HAS_SSO_INTEGRATION':
            return { ...state, tenantHasSsoIntegration: action.payload };

        case 'SET_TENANT_LOGO':
            return { ...state, tenantLogo: action.payload };

        case 'SET_COACHER_AVATAR':
            return { ...state, tenantCoacherAvatar: action.payload };

        default: {
            return state;
        }
    }
}
