/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Menu from '../Header/Menu/Menu';
import DynamicImage from '../DynamicMedia/DynamicImage';

function Breadcrumb({ path }) {
    const { t } = useTranslation();
    const [roomInfo, setRoomInfo] = useState({});
    const roomDetails = useSelector((state) => state.roomDetails);

    useEffect(() => {
        setRoomInfo(roomDetails);
    }, [roomDetails]);

    if (Object.keys(roomInfo).length > 0) {
        return (
            <header className="question-header d-flex align-items-center justify-content-between">
                <div className="question-header__left">
                    {roomInfo.icon ? (
                        <DynamicImage path={roomInfo.icon} alt={t('room_image')} classNames="question-header__img" />
                    ) : (
                        <div className="question-header__color" />
                    )}
                    <span className="question-header__level">{path || 'Loading ...'}</span>
                </div>

                <Menu />
            </header>
        );
    }
    return null;
}

Breadcrumb.prototype = {
    path: PropTypes.string.isRequired,
};

export default Breadcrumb;
