/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { hideDeleteUserModal, showError, showSuccess, startLoading, stopLoading } from '../../../../actions';
import { removeTenantUser } from '../../../../services/user/deleteUser.service';

function TransferContentAndDeleteBody(props) {
    const dispatch = useDispatch();
    const { userToBeDeleted, users, reloadUsers } = props;
    const hideModal = () => {
        dispatch(hideDeleteUserModal());
    };
    const [adminUsersOptions, setAdminUsersOptions] = useState([]);
    const deleteUserModalContent = useSelector((state) => state.deleteUserContent);
    const [newContentOwnerEmail, setNewContentOwnerEmail] = useState(null);

    useEffect(() => {
        const options = [];

        users.forEach((value) => {
            if (deleteUserModalContent.email !== value.email && value.hasRoomLevelPublishAccess) {
                options.push({ value: value.email, label: `${value.firstName} ${value.lastName}` });
            }
        });

        setAdminUsersOptions(options);
    }, [users]);

    const deleteUserAndTransferContent = () => {
        hideModal();
        dispatch(startLoading());

        removeTenantUser(deleteUserModalContent.email, newContentOwnerEmail)
            .then((response) => {
                dispatch(showSuccess(response.data.message));
            })
            .catch((error) => {
                dispatch(showError(error.response.data.message));
            })
            .then(() => {
                reloadUsers();
                dispatch(stopLoading());
            });
    };

    const changeNewContentOwner = (email) => {
        setNewContentOwnerEmail(email);
    };

    return (
        <form className="needs-validation user-edit-form" id="transferContentAndDelete" noValidate>
            <div className="row col-12 d-flex justify-content-center mt-n3">
                <div className="form-group col-8">
                    <p className="text-center h5">{userToBeDeleted}</p>
                    <p className="text-center pt-2">
                        Content created by this user must be transferred to another user.
                        <br />
                        Please select another user with edit rights to assign the ownership.
                    </p>
                    <Select
                        options={adminUsersOptions}
                        classNamePrefix="react-select"
                        placeholder="Select a user"
                        onChange={(e) => changeNewContentOwner(e.value)}
                    />
                    <div className="invalid-feedback">Email is required</div>
                </div>
                <div
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    className="form-group col-12 d-flex justify-content-center"
                >
                    <button
                        type="button"
                        className="btn btn-pop-cancel btn-md mr-4 w-auto"
                        disabled={!newContentOwnerEmail}
                        onClick={deleteUserAndTransferContent}
                    >
                        Transfer Ownership and Delete
                    </button>
                    <button
                        type="button"
                        className="btn btn-md text-uppercase btn-pop-save btn-suggest-edits-pop"
                        onClick={hideModal}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    );
}

TransferContentAndDeleteBody.defaultProps = {
    error: '',
};

export default TransferContentAndDeleteBody;
