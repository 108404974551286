/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import Fade from 'react-reveal/Fade';

export const LineBar = (props) => {
    const { value = 0, ...rest } = props;
    return (
        <>
            <span className="progress-count">{value}%</span>
            <div className="progress">
                <Fade left delay={3000}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${value}%` }}
                        aria-valuenow={`${value}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    />
                </Fade>
            </div>
        </>
    );
};
