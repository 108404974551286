/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

export default {};

const port = window.location.port !== '' ? `:${window.location.port}` : '';

/**
 * Paths
 */

// Auth
export const AUTH_PATH = '/auth';
export const AUTH_LOGIN_PATH = `${AUTH_PATH}/login`;
export const AUTH_SSO_PATH = `${AUTH_PATH}/sso`;

/**
 * URLs
 */

export const BASE_URL = `${window.location.protocol}//${window.location.hostname}${port}`;

export const API_BASE_URL = `${BASE_URL}/api/v1`;

export const AUTH_BASE_URL = `${BASE_URL}${AUTH_PATH}`;
export const AUTH_LOGIN_URL = `${BASE_URL}${AUTH_LOGIN_PATH}`;
export const AUTH_SSO_URL = `${BASE_URL}${AUTH_SSO_PATH}`;

export const LOGIN_URL = `${BASE_URL}/`;
export const LOGOUT_URL = `${BASE_URL}/logout`;

export const EXIT_URL = `${BASE_URL}/logout`;
