export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GET_USER_REQUEST: 'USERS_GET_USER_REQUEST',

  VALIDATE_USER_REQUEST: 'VALIDATE_USER_REQUEST',
  VALIDATE_USER: 'VALIDATE_USER',
  INVALID_USER: 'INVALID_USER',
  GET_USER: 'GET_USER',
  REVOKE_USER: 'REVOKE_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',
};

export const userRoles = {
  DEBROOME_SUPER_ADMIN: 1,
  SUPER_ADMIN: 2,
  ADMIN: 3,
  USER: 4,
};

export const adminRoles = [userRoles.DEBROOME_SUPER_ADMIN, userRoles.SUPER_ADMIN, userRoles.ADMIN];

export const UserPermissions = {
  READ_GAME_ROOMS: 'READ_GAME_ROOMS',
  PLAY_GAME_ROOMS: 'PLAY_GAME_ROOMS',
};
