/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { hideError } from '../../actions/error.action';
import { Button } from '../Form/Button/Button';

function MessageAlert() {
    const isError = useSelector((state) => state.error);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isError.show && isError.type === 'api') {
            let errorData = JSON.parse(isError.data.response);
            errorData = errorData.message === undefined ? errorData.statusText : errorData.message;
            setError(errorData);
        } else if (isError.show && isError.type === 'text') {
            const errorData = isError.data.message;
            setError(errorData);
        }
    }, [isError]);

    const handleClose = () => {
        dispatch(hideError());
        setError(null);
    };

    return (
        <>
            {error && (
                <div className="feedback-message feedback-message--error">
                    <div className="feedback-message__icon" />
                    <div className="feedback-message__text">{error}</div>
                    <div className="message_close_btn">
                        <Button size="xs" variant="btn-primary btn-block" onClick={handleClose}>
                            <Trans i18nKey="close" />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export { MessageAlert };
