import { baseJsonClient } from '../../helpers/httpClient';

export default {};

export function resetPassword(email) {
  return baseJsonClient.get(
    '/reset_password',
    {
      params: {
        email,
      },
    },
  );
}

export function updatePassword(token, password) {
  return baseJsonClient.post(
    '/reset_password',
    {
      token,
      password,
    },
  );
}
