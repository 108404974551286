/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';

function TableRecordSet({ users }) {
    return (
        <>
            {users.map((user) => (
                <TableRow key={user.email} user={user} />
            ))}
        </>
    );
}

TableRecordSet.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TableRecordSet;
