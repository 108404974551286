/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Novice } from '../Icons/Novice';
import { Apprentice } from '../Icons/Apprentice';
import { Explorer } from '../Icons/Explorer';
import { Advocate } from '../Icons/Advocate';
import { Profesional } from '../Icons/Profesional';
import { Ambassador } from '../Icons/Ambassador';
import { Lock } from '../Icons/Lock';

const achievements = {
    novice: { icon: (props) => <Novice {...props} />, name: 'novice' },
    apprentice: { icon: (props) => <Apprentice {...props} />, name: 'apprentice' },
    explorer: { icon: (props) => <Explorer {...props} />, name: 'explorer' },
    advocate: { icon: (props) => <Advocate {...props} />, name: 'advocate' },
    professional: { icon: (props) => <Profesional {...props} />, name: 'professional' },
    ambassador: { icon: (props) => <Ambassador {...props} />, name: 'ambassador' },
};

export function Achievement({ type, percentage, locked }) {
    const { t } = useTranslation();
    const userLanguage = useSelector((state) => state.authentication.user.language);

    const progressAmount = ((100 - percentage) * 2 * Math.PI * 47) / 100;
    return (
        <div className="achievement">
            <div className={!locked ? 'achievement__percentage-badge' : 'achievement__percentage-badge--locked'}>
                <div className="achievement__achievement-type-icon-wrapper">
                    <div
                        className={!locked ? 'achievement__achievement-icon' : 'achievement__achievement-icon--locked'}
                    >
                        {achievements[type].icon()}
                    </div>
                </div>
                <svg
                    id="profile-progress"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 102 102"
                    transform="rotate(270)"
                >
                    <circle
                        id="profile-progress-bg"
                        cx="47"
                        cy="47"
                        r="47"
                        fill="none"
                        stroke="rgba(0, 0, 0,0.3)"
                        strokeLinecap="round"
                        strokeWidth="8"
                        transform="translate(4 4)"
                    />
                    <circle
                        id="profile-progress-indicator"
                        cx="47"
                        cy="47"
                        r="47"
                        fill="none"
                        stroke="rgb(255, 0, 0)"
                        strokeLinecap="round"
                        strokeWidth="8"
                        strokeDasharray="295 295"
                        strokeDashoffset={progressAmount.toString()}
                        transform="translate(4 4)"
                        style={{ transition: 'all 1s' }}
                    />
                </svg>
            </div>
            <div className="achievement__title-wrapper">
                <div>
                    <p className={locked ? 'achievement__title--locked' : 'achievement__title'}>
                        {t(achievements[type].name)}
                    </p>
                    <div className="achievement__percentage">
                        {!locked ? (
                            <p className="achievement__percentage__text">
                                {userLanguage.code === 'sv' ? `${percentage} %` : `${percentage}%`}
                            </p>
                        ) : (
                            <div className="achievement__percentage__lock">
                                <Lock height="15px" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
