/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { common } from '../../../helpers';
import { mediaFiles, mediaTypes, questionTypes } from '../../../constants';
import DynamicImage from '../../../components/DynamicMedia/DynamicImage';
import DynamicAudio from '../../../components/DynamicMedia/DynamicAudio';
import DynamicVideo from '../../../components/DynamicMedia/DynamicVideo';

function QuestionContent({ questionContent, question, questionTypeId, extension, file }) {
    const [mediaType, setMediaType] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (mediaFiles.IMAGE.includes(extension)) {
            return setMediaType('image');
        }
        if (mediaFiles.AUDIO.includes(extension)) {
            return setMediaType('audio');
        }
        if (mediaFiles.VIDEO.includes(extension)) {
            return setMediaType('video');
        }
        return null;
    }, []);

    return (
        <>
            {(questionContent || file) && (
                <div className="question__text">
                    {file && mediaType && (
                        <>
                            {file && mediaType === mediaTypes.IMAGE && (
                                <DynamicImage path={file} classNames="question__main-img" alt={t('question_image')} />
                            )}
                            {file && mediaType === mediaTypes.AUDIO && (
                                <DynamicAudio path={file} width="100%" height="65px" controls />
                            )}
                            {file && mediaType === mediaTypes.VIDEO && (
                                <DynamicVideo className="video-player" path={file} width="100%" controls />
                            )}
                            <br />
                        </>
                    )}
                </div>
            )}

            {questionTypeId !== questionTypes.FILL_IN_THE_BLANKS && (
                <div className="question__text">
                    <div className="question__bold">{common.sanitizeString(question)}</div>
                </div>
            )}
        </>
    );
}

QuestionContent.propTypes = {
    question: PropTypes.string.isRequired,
    extension: PropTypes.string,
    file: PropTypes.string,
    questionTypeId: PropTypes.number.isRequired,
};

QuestionContent.defaultProps = {
    extension: null,
    file: null,
};

export default QuestionContent;
