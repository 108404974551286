/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../Header';
import { BaseRoutes } from '../../constants';
import { Loader } from '../Loader';
import { startLoading, stopLoading } from '../../actions';
import { getPrivateTenantProperties } from '../../services/tenant-properties.service';
import { tenantSetCoacherAvatar, tenantSetLogo, tenantSetWelcomeText } from '../../actions/tenant.action';

function Login(props) {
    const { children, tenantName, coacherAvatar } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languageLoaded = useSelector((state) => state.languageLoaded);
    const [pageVisibility, setPageVisibility] = useState(false);

    useEffect(() => {
        dispatch(startLoading());
        getPrivateTenantProperties().then((response) => {
            const { data } = response;
            dispatch(tenantSetWelcomeText(data.welcome_text));
            dispatch(tenantSetLogo(data.logo));
        });
        setPageVisibility(true);
    }, []);

    useEffect(() => {
        if (languageLoaded && tenantName) {
            dispatch(stopLoading());
        }
    }, [languageLoaded, tenantName]);

    return (
        <>
            <Loader />
            {pageVisibility && (
                <>
                    <Header logoLink={BaseRoutes.LOGIN} showMenu={false} />
                    <section className="body login">
                        <div className="container h-100">
                            <div className="row">
                                <div className="col-md">
                                    <div className="teacher__bubble-container">
                                        <div className="teacher teacher__login-wrapper">
                                            <div className="teacher__avatar-wrapper">
                                                <span className="teacher__avatar">
                                                    <img
                                                        src={coacherAvatar}
                                                        alt="Avatar"
                                                        className="teacher__avatar-image"
                                                    />
                                                </span>
                                            </div>
                                            <div className="teacher__bubble">
                                                <span className="">
                                                    <span className="teacher__bubble-text">
                                                        <span>
                                                            {`${t('welcome_login_first')} ${tenantName} ${t('welcome_brand_learning')}`}
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">{children}</div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div className="login__footer">
                                        <Trans i18nKey="powered_by_only" />{' '}
                                        <a about="_blank" rel="noopener noreferrer" href="https://debroome.com">
                                            deBroome
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

Login.propTypes = {
    children: PropTypes.element.isRequired,
    tenantName: PropTypes.string.isRequired,
};

export default Login;
