/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { statistics } from '../constants';

const initialState = {
    classes: '',
};

export function statisticsClasses(state = initialState, action) {
    switch (action.type) {
        case statistics.BODY_CLASSES:
            return action.payload;

        default: {
            return state;
        }
    }
}
