/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetMessage } from '../../../actions';

function AdminAlert() {
    const alert = useSelector((state) => state.adminMessage);
    const dispatch = useDispatch();

    if (Object.keys(alert).length > 0) {
        // Reset the message alert for the next message after completing the hide animation for the current message
        setTimeout(() => {
            dispatch(resetMessage());
        }, 5000);

        if (alert.success) {
            return (
                <div className="bta-msg-box success">
                    <span>
                        <i className="badad-icon-check_circle" />
                        {alert.message}
                    </span>
                </div>
            );
        }

        return (
            <div className="bta-msg-box error">
                <span>
                    <i className="badad-icon-error" />
                    {alert.message}
                </span>
            </div>
        );
    }

    return null;
}

export default AdminAlert;
