/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PreLoginWrapper from '../../components/PreLoginWrapper/PreLoginWrapper';
import { resetPassword, updatePassword } from '../../services/users/password.service';
import { useQuery } from '../../helpers/query';
import { BaseRoutes } from '../../constants';
import { getPublicTenantProperties } from '../../services/tenant-properties.service';
import { tenantSetCoacherAvatar, tenantSetName } from '../../actions/tenant.action';

function ForgotPassword({ history }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordWasReset, setPasswordWasReset] = useState(false);

    const [error, setError] = useState('');

    const [canUpdate, setCanUpdate] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const tenantName = useSelector((state) => state.tenant.tenantName);
    const coacherAvatar = useSelector((state) => state.tenant.tenantCoacherAvatar);

    const onSubmitReset = (event) => {
        event.preventDefault();

        resetPassword(email)
            .then((response) => {
                if (response.status !== 204) {
                    setError(response.data.message);
                }

                setPasswordWasReset(true);
            })
            .catch((apiError) => {
                setError(apiError.response.data.message);
            });
    };

    const onSubmitUpdate = (event) => {
        event.preventDefault();

        updatePassword(query.get('token'), password)
            .then((response) => {
                if (response.status !== 204) {
                    setError(response.data.message);
                }

                history.push(BaseRoutes.LOGIN);
            })
            .catch((apiError) => {
                setError(apiError.response.data.message);
            });
    };

    const query = useQuery();

    useEffect(() => {
        setCanUpdate(query.get('token') !== null);

        if (!tenantName) {
            getPublicTenantProperties().then((response) => {
                const { data } = response.data;
                const { name } = data;
                dispatch(tenantSetName(name));
                dispatch(tenantSetCoacherAvatar(data.coacherAvatar));
            });
        }
    }, []);

    if (passwordWasReset) {
        return (
            <PreLoginWrapper tenantName={tenantName} coacherAvatar={coacherAvatar}>
                <div className="login__form-wrapper">
                    <div>
                        If a user account with the given email exists, we have sent you a password reset email. You may
                        close this tab now.
                    </div>
                </div>
            </PreLoginWrapper>
        );
    }

    return (
        <PreLoginWrapper tenantName={tenantName} coacherAvatar={coacherAvatar}>
            {canUpdate ? (
                <form onSubmit={onSubmitUpdate}>
                    <div className="login__form-wrapper">
                        <div className="login__input-container">
                            <input
                                type="password"
                                className="login__text-field"
                                id="password"
                                required
                                value={password}
                                autoComplete={false}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            <label className="place-holder" htmlFor="password">
                                <Trans i18nKey="password" />
                            </label>
                        </div>
                        <div className="login__input-container">
                            <input
                                type="password"
                                className="login__text-field"
                                id="confirm_password"
                                required
                                value={confirmPassword}
                                autoComplete={false}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                            />
                            <label className="place-holder" htmlFor="confirm_password">
                                <Trans i18nKey="password_confirmation" />
                            </label>
                        </div>
                        {error !== '' && <div style={{ marginBottom: 16 }}>{error}</div>}
                        <button type="submit" className="btn btn-primary btn-lg login__button">
                            <Trans i18nKey="reset_password_button" />
                        </button>
                    </div>
                </form>
            ) : (
                <form onSubmit={onSubmitReset}>
                    <div className="login__form-wrapper">
                        <div className="login__inputs-group">
                            <div className="login__input-container">
                                <input
                                    type="text"
                                    className="login__text-field"
                                    id="email"
                                    required
                                    value={email}
                                    autoComplete={false}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                                <label className="place-holder" htmlFor="email">
                                    <Trans i18nKey="email" />
                                </label>
                            </div>
                            {error !== '' && <div style={{ marginBottom: 16 }}>{error}</div>}
                        </div>
                        <button type="submit" className="btn btn-primary btn-lg login__button">
                            <Trans i18nKey="reset_password_button" />
                        </button>
                    </div>
                </form>
            )}
        </PreLoginWrapper>
    );
}

export default ForgotPassword;
