/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const AdvancedFace = (props) => {
    const { baseClass = 'level-select__level-ico' } = props;

    return (
        <svg
            className={baseClass}
            xmlns="http://www.w3.org/2000/svg"
            width="38.681"
            height="38.681"
            viewBox="0 0 38.681 38.681"
        >
            <g fill="#fff" stroke="rgb(var(--primaryRGB))" strokeWidth="2">
                <rect width="38.681" height="38.681" rx="7" stroke="none" />
                <rect x="1" y="1" width="36.681" height="36.681" rx="6" fill="none" />
            </g>
            <g transform="translate(7.395 17.32)" fill="#fff" stroke="rgb(var(--primaryRGB))" strokeWidth="2">
                <ellipse cx="2.844" cy="2.844" rx="2.844" ry="2.844" stroke="none" />
                <ellipse cx="2.844" cy="2.844" rx="1.844" ry="1.844" fill="none" />
            </g>
            <g transform="translate(24.46 17.32)" fill="#fff" stroke="rgb(var(--primaryRGB))" strokeWidth="2">
                <ellipse cx="2.844" cy="2.844" rx="2.844" ry="2.844" stroke="none" />
                <ellipse cx="2.844" cy="2.844" rx="1.844" ry="1.844" fill="none" />
            </g>
            <g transform="translate(14.221 15.352)" fill="rgb(var(--primaryRGB))">
                <path
                    d="M 5.119512557983398 14.61950397491455 C 3.192805051803589 14.61950397491455 1.570756673812866 13.28980922698975 1.122726202011108 11.50000476837158 L 9.116298675537109 11.50000476837158 C 8.668268203735352 13.28980922698975 7.046219825744629 14.61950397491455 5.119512557983398 14.61950397491455 Z"
                    stroke="none"
                />
                <path
                    d="M 2.727250576019287 12.50000476837158 C 3.299945831298828 13.18387126922607 4.159830093383789 13.61950397491455 5.119512557983398 13.61950397491455 C 6.079195022583008 13.61950397491455 6.939079284667969 13.18387126922607 7.51177453994751 12.50000476837158 L 2.727250576019287 12.50000476837158 M 2.86102294921875e-06 10.50000476837158 C 3.591562747955322 10.50000476837158 7.626302719116211 10.50000476837158 10.23902225494385 10.50000476837158 C 10.23902225494385 13.32742404937744 7.946942329406738 15.61950397491455 5.119512557983398 15.61950397491455 C 2.292082786560059 15.61950397491455 2.86102294921875e-06 13.32742404937744 2.86102294921875e-06 10.50000476837158 Z"
                    stroke="none"
                    fill="rgb(var(--primaryRGB))"
                />
            </g>
            <g
                transform="translate(2.844 8.219)"
                fill="rgb(var(--primaryRGB))"
                stroke="rgb(var(--primaryRGB))"
                strokeWidth="2"
            >
                <rect width="32.992" height="3.413" stroke="none" />
                <rect x="-1" y="-1" width="34.992" height="5.413" fill="none" />
            </g>
        </svg>
    );
};
