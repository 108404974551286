import { deleteUserModal } from '../constants';

export const showDeleteUserModal = (firstName, lastName, email) => ({
  type: deleteUserModal.SHOW_MODAL,
  payload: {
    show: true,
    firstName,
    lastName,
    email,
  },
});

export const hideDeleteUserModal = () => ({
  type: deleteUserModal.HIDE_MODAL,
  payload: {
    show: false,
    firstName: null,
    lastName: null,
    email: null,
  },
});
