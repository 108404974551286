/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { error } from '../constants';

const initialState = {
    show: false,
};

export function errorMessage(state = initialState, action) {
    switch (action.type) {
        case error.SHOW_ERROR:
            return action.payload;

        case error.HIDE_ERROR:
            return action.payload;

        default: {
            return state;
        }
    }
}
