/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Header, BackToDashboard } from '../../components/Header';
import { TeacherMessage } from '../../components/Room/Teacher/Message/Message';
import { PlayerStatus } from './PlayerStatus/PlayerStatus';
import { GamePlayers } from './GamePlayers/GamePlayers';
import { AchievementStatus } from './AchievementStatus/AchievementStatus';
import { getCommunityStatData } from '../../services/communityStat.service';
import { setCommunityStat } from '../../actions/communityStat.action';
import { startLoading, stopLoading } from '../../actions';

export function TenantsStatsOverview() {
    const dispatch = useDispatch();
    const authentication = useSelector((state) => state.authentication);
    const communityStat = useSelector((state) => state.communityStat);
    const [tenantAchievements, setTenantAchievements] = useState([]);
    const [tenantPlayerStatistics, setTenantPlayerStatistics] = useState({});
    const [avatar, setAvatar] = useState([]);

    useEffect(() => {
        dispatch(startLoading());
        getCommunityStatData().then((response) => {
            dispatch(setCommunityStat(response));
        });
    }, []);

    useEffect(() => {
        if (Object.keys(communityStat).length) {
            const userCount = communityStat.tenantPlayerStatistics.tenantUserCount;
            const tempTenantUserAchievements = [];
            Object.keys(communityStat.tenantPlayerAchievementStatistics).map((achievement) => {
                tempTenantUserAchievements.push({
                    name: achievement,
                    percentage: +(
                        (communityStat.tenantPlayerAchievementStatistics[achievement].count / userCount) *
                        100
                    ).toFixed(1),
                    locked: communityStat.tenantPlayerAchievementStatistics[achievement].locked,
                });
            });
            setTenantAchievements(tempTenantUserAchievements);
            setTenantPlayerStatistics(communityStat.tenantPlayerStatistics);
            setAvatar(communityStat.tenantPlayerStatistics.tenantUserAvatars);
            dispatch(stopLoading());
        }
    }, [communityStat]);

    return (
        <>
            <Header />
            <section className="body pt-3">
                <div className="container">
                    <BackToDashboard />

                    <div className="text-center">
                        <div className="col-md">
                            <TeacherMessage
                                message=<Trans i18nKey="this_is_the_current_status_of" />
                                addClass="teacher__center-content"
                                bottom
                                top={false}
                                delay={0}
                                bubbleTextClasses="text-left"
                                messageClasses="teacher__display-linebreak"
                            />

                            <Fade bottom>
                                <div className="stat-overview-container">
                                    <div>
                                        <div className="col-md">
                                            <div className="row">
                                                <div className="col-md stat-overview-container__upper-wrapper">
                                                    <PlayerStatus
                                                        firstName={authentication.user.first_name}
                                                        lastName={authentication.user.last_name}
                                                        achievedCoins={authentication.user.achieved_coins}
                                                        levelReadTitleTranslationKey={
                                                            authentication.user.level_read_title_translation_key
                                                        }
                                                        avatar={authentication.user.avatar}
                                                    />
                                                    <GamePlayers
                                                        avatars={[authentication.user.avatar, ...avatar]}
                                                        userCount={tenantPlayerStatistics.tenantUserCount}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md mt-2 room-achievement-status-wrapper">
                                            <AchievementStatus tenantAchievements={tenantAchievements} />
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className="mb-5" />
            </section>
        </>
    );
}
