/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { DoneIcon } from '../../Icons';
import { roomStatus } from '../../../constants';
import { UpcomingIcon } from '../../Icons/UpcomingIcon';
import RoomActions from './Actions';

const RoomStatus = ({ room, completedRate }) => (
    <>
        {room.status === roomStatus.ROOM_PUBLISHED ? (
            <>
                {room.completedCount === room.levelCount ? (
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="badge bg-secondary">
                            <Trans i18nKey="done" />
                        </span>
                        <DoneIcon style="card__learning-status" />
                    </div>
                ) : (
                    <RoomActions completedRate={completedRate} expectedTime={room.expectedTime} />
                )}
            </>
        ) : (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <span className="badge bg-secondary">
                        <Trans i18nKey="upcoming" />
                    </span>
                    <UpcomingIcon className="card__learning-status" />
                </div>
            </>
        )}
    </>
);

export default RoomStatus;

RoomStatus.propTypes = {
    room: PropTypes.object.isRequired,
    completedRate: PropTypes.number.isRequired,
};
