/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import * as Sentry from '@sentry/browser';
import React from 'react';

Sentry.init({
    dsn: 'https://73c259eb8fed40f492d6c593add4d1e2@o575293.ingest.us.sentry.io/4504078479785984',
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
});

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/*
 * Root component
 * */
require('./index');
