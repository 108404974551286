/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Trans } from 'react-i18next';
import { AvatarStack } from '../../../components/AvatarStack';
import { common } from '../../../helpers';
import ProfileAvatar1 from '../../../../images/profilePlaceholders/profile-1.png';
import ProfileAvatar2 from '../../../../images/profilePlaceholders/profile-2.png';
import ProfileAvatar3 from '../../../../images/profilePlaceholders/profile-3.png';
import ProfileAvatar4 from '../../../../images/profilePlaceholders/profile-4.png';

export function GamePlayers({ avatars = [], userCount = 0 }) {
    return (
        <div className="card game-players-count-card stat-card">
            <div className="stat-card__title">
                <Trans i18nKey="number_of_players" />
            </div>
            <div className="stat-card__content">
                <div className="game-players-count-card__avatar-stack">
                    <AvatarStack avatars={avatars} height="50" />
                </div>
                <div className="game-players-count-card__user-count">{common.separateNumberByThousands(userCount)}</div>
            </div>
        </div>
    );
}
