/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Header, BackToDashboard } from '../../components/Header';
import { TeacherMessage } from '../../components/Room/Teacher/Message/Message';
import { Trans } from 'react-i18next';
import { AllTimeCoins } from '../../components/Shop/AllTimeCoins';
import { Item } from '../../components/Shop/Item';
import { useDispatch, useSelector } from 'react-redux';
import { setItemToBuy, spendableCoinsSet } from '../../actions';
import { SpendableCoins } from '../../components/Shop/SpendableCoins';
import { SpendableCoinsFooter } from '../../components/Shop/SpendableCoinsFooter';
import { DefaultModal } from '../../components/Shop/DefaultModal';
import { MobileModal } from '../../components/Shop/MobileModal';

export function Shop() {
    const dispatch = useDispatch();
    const [showPurchaseConfirmModal, setShowPurchaseConfirmModal] = useState(false);
    const [showPurchaseConfirmMobileModal, setShowPurchaseConfirmMobileModal] = useState(false);
    const [mobileModal, setMobileModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [modalImageNoBottom, setModalImageNoBottom] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState('');
    const [modalPrice, setModalPrice] = useState(null);
    const [modalPriceCurrency, setModalPriceCurrency] = useState('');
    const [modalPurchasedDone, setModalPurchasedDone] = useState(false);
    const [totalCoins, setTotalCoins] = useState(400);
    const selectedItemIdToBuy = useSelector((state) => state.purchaseItems.itemIdToBuy);
    const spendableCoins = useSelector((state) => state.spendableCoins.coins);
    const [showMobileFooterWidget, setShowMobileFooterWidget] = useState(false);
    const [items, setItems] = useState([
        {
            id: 1,
            image: '/frontend/images/ticket-sample-image.png',
            imageLarge: '/frontend/images/ticket-full-sample-image.png',
            imageNoBottom: false,
            title: 'Cinema Ticket - 20 USD Voucher',
            description: 'Voucher at Union Cinema Theatre online store value 20 USD',
            price: '35',
            currency: 'LC',
            isPurchased: false,
        },
        {
            id: 2,
            image: '/frontend/images/tshirt-sample-image.png',
            imageLarge: '/frontend/images/tshirt-full-sample-image.png',
            imageNoBottom: true,
            title: 'Brand T-shirt - Size Small',
            description: 'Brand T-shirt with company logo over chest and on left sleeve. Size S',
            price: '150',
            currency: 'LC',
            isPurchased: false,
        },
    ]);
    const spendableCoinsRef = useRef(null);
    const windowWidth = useRef(window.innerWidth);

    useEffect(() => {
        if (selectedItemIdToBuy !== 0) {
            const item = getItem(selectedItemIdToBuy);

            setModalImage(item.imageLarge);
            setModalTitle(item.title);
            setModalDescription(item.description.replace(/<(?!br\s*\/?>)[^>]+>/g, ''));
            setModalPrice(item.price);
            setModalPriceCurrency(item.currency);
            setModalImageNoBottom(item.imageNoBottom);

            showModal();
        } else {
            //set timeout for smooth animation
            const timer = setTimeout(() => {
                setModalPurchasedDone(false);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [selectedItemIdToBuy]);

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const handleScroll = () => {
            if (spendableCoinsRef.current) {
                const spendableCoinsRect = spendableCoinsRef.current.getBoundingClientRect();

                if (spendableCoinsRect.top <= 0) {
                    setShowMobileFooterWidget(true);
                } else if (spendableCoinsRect.top > 0) {
                    setShowMobileFooterWidget(false);
                }

                lastScrollY = window.scrollY;
            }
        };

        const handleResizeWidth = () => {
            setMobileModal(window.innerWidth < 768);
            handleScroll();
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResizeWidth);

        // Remove the event listeners when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResizeWidth);
        };
    }, []);

    const getItem = (itemId) => {
        return items.find((item) => item.id === itemId);
    };

    const closeModal = (withReset = true) => {
        setShowPurchaseConfirmModal(false);
        setShowPurchaseConfirmMobileModal(false);
        dispatch(setItemToBuy(0));

        if (withReset) {
            const timerReset = setTimeout(() => {
                resetPurchasedInfo();
            }, 5000);
            return () => clearTimeout(timerReset);
        }
    };

    const showModal = () => {
        mobileModal ? setShowPurchaseConfirmMobileModal(true) : setShowPurchaseConfirmModal(true);
    };

    const handleBuyClick = () => {
        setModalImage('/frontend/images/purchase_succeeded.png');
        setModalTitle('Congratulations on your purchase!');
        setModalDescription(
            'You will shortly receive an email with a confirmation and more information about your order and delivery.',
        );
        setModalPrice(null);
        setModalImageNoBottom(false);
        setModalPurchasedDone(true);
    };

    const setPurchasedInfo = (itemId) => {
        const itemIndex = items.findIndex((item) => item.id === selectedItemIdToBuy);

        if (itemIndex !== -1) {
            const updatedItems = [...items];
            updatedItems[itemIndex] = { ...items[itemIndex], isPurchased: true };
            setItems(updatedItems);
        }
    };

    const resetPurchasedInfo = (itemId) => {
        const updatedItems = items.map((item) => ({ ...item, isPurchased: false }));
        setItems(updatedItems);
    };

    const handleOkButtonClick = () => {
        const purchasedItemId = selectedItemIdToBuy;
        const purchasedItem = getItem(purchasedItemId);

        closeModal();

        calculateSpendableCoins(purchasedItem.price);

        const timerPurchased = setTimeout(() => {
            setPurchasedInfo(purchasedItemId);
        }, 1500);
        return () => clearTimeout(timerPurchased);
    };

    const calculateSpendableCoins = (purchasedCoins) => {
        const timer = setTimeout(() => {
            dispatch(spendableCoinsSet(spendableCoins - purchasedCoins));
        }, 700);
        return () => clearTimeout(timer);
    };

    return (
        <>
            <Header />
            <section className="body pt-3">
                <div className="container">
                    <BackToDashboard />

                    <div className="text-center">
                        <div className="col-md">
                            <TeacherMessage
                                message=<Trans i18nKey="welcome_to_bl_shop" />
                                addClass="teacher__center-content"
                                bottom
                                top={false}
                                delay={0}
                                bubbleTextClasses="text-left"
                                messageClasses="teacher__display-linebreak"
                            />

                            <Fade bottom={true} duration={1000}>
                                <div className="col-md shop">
                                    <div className="row shop__multiple-card-row">
                                        <div className="spendable-coins-container" ref={spendableCoinsRef}>
                                            <SpendableCoins currency="LC" />
                                        </div>

                                        <div className="all-time-coins-container">
                                            <AllTimeCoins total={totalCoins} currency="LC" />
                                        </div>
                                    </div>

                                    <div className="row shop__single-card-row">
                                        {items.map((item) => {
                                            return (
                                                <Item
                                                    key={item.id}
                                                    itemId={item.id}
                                                    image={item.imageLarge}
                                                    imageNoBottom={item.imageNoBottom}
                                                    title={item.title}
                                                    description={item.description}
                                                    price={item.price}
                                                    currency={item.currency}
                                                    isPurchased={item.isPurchased}
                                                />
                                            );
                                        })}
                                    </div>

                                    <div className="empty-space"></div>
                                </div>
                            </Fade>

                            {showMobileFooterWidget && <SpendableCoinsFooter currency="LC" />}
                        </div>
                    </div>
                </div>
            </section>

            <DefaultModal
                key="default-modal"
                show={showPurchaseConfirmModal}
                modalImageNoBottom={modalImageNoBottom}
                modalPurchasedDone={modalPurchasedDone}
                modalImage={modalImage}
                modalTitle={modalTitle}
                modalDescription={modalDescription}
                modalPrice={modalPrice}
                modalPriceCurrency={modalPriceCurrency}
                closeModalCallBack={() => closeModal(false)}
                okButtonClickCallBack={() => handleOkButtonClick()}
                buyButtonClickCallBack={() => handleBuyClick()}
            />

            <MobileModal
                key="mobile-modal"
                show={showPurchaseConfirmMobileModal}
                modalImageNoBottom={modalImageNoBottom}
                modalPurchasedDone={modalPurchasedDone}
                modalImage={modalImage}
                modalTitle={modalTitle}
                modalDescription={modalDescription}
                modalPrice={modalPrice}
                modalPriceCurrency={modalPriceCurrency}
                closeModalCallBack={() => closeModal(false)}
                okButtonClickCallBack={() => handleOkButtonClick()}
                buyButtonClickCallBack={() => handleBuyClick()}
            />
        </>
    );
}
