/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_URL } from '../../constants/appUrls';
import { previewSet, userAction } from '../../actions';
import { adminRoles } from '../../constants/user';
import { levelService } from '../../services/level';
import { setRoomDetails } from '../../actions/roomDetails.action';
import { getPublicTenantProperties } from '../../services/tenant-properties.service';
import { tenantSetCoacherAvatar } from '../../actions/tenant.action';
import { BaseRoutes } from '../../constants';
import { useHasPermission } from '../../hooks/useHasPermission';

function PreviewRoute({
  component: Component, roles, permission, ...rest
}) {
  const dispatch = useDispatch();
  const loginUrl = LOGIN_URL || '/';
  const routeParams = rest.computedMatch.params;
  const user = useSelector((state) => state.authentication.user);
  const hasPermission = useHasPermission(permission);

    useEffect(() => {
        dispatch(previewSet(1, routeParams));
        dispatch(userAction.validateUser(true));

        getPublicTenantProperties().then((response) => {
            const { data } = response.data;
            dispatch(tenantSetCoacherAvatar(data.coacherAvatar));
        });

    if (typeof routeParams.roomId !== 'undefined') {
      levelService.getLevelsByRoom(routeParams.roomId)
        .then((response) => {
          dispatch(setRoomDetails(response.roomInfo));
        }).catch(() => { });
    }
  }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (typeof user !== 'undefined') {
                    if (adminRoles.includes(user.role_id)) {
                        return <Component isPreview {...props} />;
                    }

                    window.location.href = loginUrl;
                    return false;
                }
            }}
        />
    );
}

export { PreviewRoute };
