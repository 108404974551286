/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ModalWindow from '../../../ModalWindow/ModalWindow';
import TransferContentAndDeleteBody from './TransferContentAndDeleteBody';
import AnonymizeUserBody from './AnonymizeUserBody';
import { getPublicTenantProperties } from '../../../../services/tenant-properties.service';

function DeleteUserModal(props) {
    const { users, reloadUsers } = props;
    const deleteUserModalContent = useSelector((state) => state.deleteUserContent);
    const [hasOwnGameContent, setHasOwnGameContent] = useState(true);
    const [tenantName, setTenantName] = useState(null);

    useEffect(() => {
        getPublicTenantProperties().then((response) => {
            const { data } = response.data;
            setTenantName(data.name);
        });

        if (users && deleteUserModalContent.email) {
            const selectedUser = users.filter((user) => user.email === deleteUserModalContent.email)[0];
            setHasOwnGameContent(selectedUser.hasOwnGameContent);
        }
    }, [deleteUserModalContent]);

    return (
        <ModalWindow
            visibility={deleteUserModalContent.show}
            title="Are you sure you want to delete this user?"
            body={
                hasOwnGameContent ? (
                    <TransferContentAndDeleteBody
                        userToBeDeleted={`${deleteUserModalContent.firstName} ${deleteUserModalContent.lastName}`}
                        users={users}
                        reloadUsers={reloadUsers}
                    />
                ) : (
                    <AnonymizeUserBody
                        tenantName={tenantName}
                        userToBeDeleted={`${deleteUserModalContent.firstName} ${deleteUserModalContent.lastName}`}
                        reloadUsers={reloadUsers}
                    />
                )
            }
        />
    );
}

DeleteUserModal.defaultProps = {
    error: '',
};

export default DeleteUserModal;
