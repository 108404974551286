/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { profileCard } from '../constants';

const initialState = {
    canAnimate: false,
    isProfileUpdated: false,
};

export function profileReducer(state = initialState, action) {
    switch (action.type) {
        case profileCard.DEFAULT_LOAD:
            return { ...state, ...action.payload };

        case profileCard.SHOW_ALL:
            return { ...state, ...action.payload };

        case profileCard.SHOW_FEWER:
            return { ...state, ...action.payload };

        case profileCard.ANIMATE_COIN:
            return { ...state, ...action.payload };

        case profileCard.IS_UPDATED:
            return { ...state, ...action.payload };

        default: {
            return state;
        }
    }
}
