/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { previewInfo } from '../constants';

const initialState = {
    isPreview: 0,
    metadata: {
        roomId: null,
        levelId: null,
        questionId: null,
        questionType: null,
    },
};

export function previewReducer(state = initialState, action) {
    switch (action.type) {
        case previewInfo.SET_PREVIEW:
            return action.payload;

        default: {
            return state;
        }
    }
}
