import { loader } from '../constants';

export const startLoading = () => ({
  type: loader.SHOW_LOADER,
  payload: {
    loading: true,
  },
});

export const stopLoading = () => ({
  type: loader.HIDE_LOADER,
  payload: {
    loading: false,
  },
});
