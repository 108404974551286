/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import Fade from 'react-reveal/Fade';
import { Trans } from 'react-i18next';
import { questionTypes } from '../../../constants';
import QuestionContent from '../QuestionContent/QuestionContent';

function TrueAndFalse(props) {
    const { question, changeEvent, selectedAnswers } = props;

    return (
        <>
            <Fade bottom duration={500}>
                {question && <QuestionContent key={question.questionId} {...question} />}

                <div className="row multiple-answer">
                    {question.answers.map((qAnswer, idx) => (
                        <div key={idx} className="col">
                            <div className="multiple-answer__item">
                                <input
                                    type="radio"
                                    className="multiple-answer__choose"
                                    defaultValue={idx}
                                    defaultChecked={selectedAnswers && Boolean(selectedAnswers[qAnswer.answerId])}
                                    onChange={(evt) => changeEvent(evt, qAnswer)}
                                    name={questionTypes.TRUE_FALSE}
                                />
                                <label className="card multiple-answer__item-inner" htmlFor="multiple-ans-1">
                                    <span className="multiple-answer__text">
                                        <Trans i18nKey={qAnswer.answer} />
                                    </span>
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </Fade>
        </>
    );
}

export { TrueAndFalse };
