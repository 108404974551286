/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const BeginnerFace = (props) => {
    const { baseClass = 'level-select__level-ico' } = props;

    return (
        <svg
            className={baseClass}
            xmlns="http://www.w3.org/2000/svg"
            width="38.681"
            height="39.104"
            viewBox="0 0 38.681 39.104"
        >
            <g transform="translate(0 0.424)">
                <g fill="#fff" stroke="rgb(var(--primaryRGB))" strokeWidth="2">
                    <rect width="38.681" height="38.681" rx="7" stroke="none" />
                    <rect x="1" y="1" width="36.681" height="36.681" rx="6" fill="none" />
                </g>
                <g transform="translate(5.395 16.319)" fill="#fff" stroke="rgb(var(--primaryRGB))" strokeWidth="2">
                    <ellipse cx="2.844" cy="2.844" rx="2.844" ry="2.844" stroke="none" />
                    <ellipse cx="2.844" cy="2.844" rx="1.844" ry="1.844" fill="none" />
                </g>
                <g transform="translate(26.46 16.319)" fill="#fff" stroke="rgb(var(--primaryRGB))" strokeWidth="2">
                    <ellipse cx="2.844" cy="2.844" rx="2.844" ry="2.844" stroke="none" />
                    <ellipse cx="2.844" cy="2.844" rx="1.844" ry="1.844" fill="none" />
                </g>
                <g transform="translate(16.51 14.352)" fill="rgb(var(--primaryRGB))">
                    <path
                        d="M 2.830080270767212 12.33007907867432 C 2.189716815948486 12.33007907867432 1.625120520591736 11.99948501586914 1.297998428344727 11.49999904632568 L 4.362162113189697 11.49999904632568 C 4.035039901733398 11.99948501586914 3.470443725585938 12.33007907867432 2.830080270767212 12.33007907867432 Z"
                        stroke="none"
                    />
                    <path
                        d="M 4.76837158203125e-07 10.49999904632568 C 1.985430240631104 10.49999904632568 4.215840339660645 10.49999904632568 5.660160064697266 10.49999904632568 C 5.660160064697266 12.06300926208496 4.39309024810791 13.33007907867432 2.830080270767212 13.33007907867432 C 1.267070293426514 13.33007907867432 4.76837158203125e-07 12.06300926208496 4.76837158203125e-07 10.49999904632568 Z"
                        stroke="none"
                        fill="rgb(var(--primaryRGB))"
                    />
                </g>
                <path
                    d="M1062.371,499.068c1.089,2.066-1.855,5.107-4.488,2.448s.839-7.18.839-7.18"
                    transform="translate(-1039.866 -493.357)"
                    fill="none"
                    stroke="rgb(var(--primaryRGB))"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};
