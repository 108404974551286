/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { userConstants } from '../constants/user';

const initialState = {};

export function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.VALIDATE_USER_REQUEST:
            return {
                validation_requested: true,
            };
        case userConstants.VALIDATE_USER:
            return {
                valid_user: true,
                user: action.user,
            };
        case userConstants.INVALID_USER:
            return {};
        case userConstants.REVOKE_USER:
            return {};
        case userConstants.UPDATE_USER:
            return {
                ...state,
                user: action.user,
            };
        case userConstants.UPDATE_USER_FAILED:
            return {
                state,
            };
        default: {
            return state;
        }
    }
}
