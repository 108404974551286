/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export function Avatar({ avatar, width = 50 }) {
    return (
        <div className="avatar">
            <img src={avatar} alt="profile-pic" width={width} />
        </div>
    );
}
