/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Modal } from 'react-bootstrap';
import './css/style.css';

function ModalWindow({ visibility, title, body, footer }, props) {
    return (
        <Modal
            {...props}
            show={visibility}
            centered
            dialogClassName="modal--width"
            onHide=""
            backdrop="static"
            keyboard={false}
        >
            {title ? (
                <Modal.Header>
                    <Modal.Title className="w-100">
                        <h1 style={{ fontSize: 18 }} className="second-h3 mb-0 text-center">
                            {title}
                        </h1>
                    </Modal.Title>
                </Modal.Header>
            ) : (
                ''
            )}
            <Modal.Body>{body}</Modal.Body>
            {footer ? <Modal.Footer>{footer}</Modal.Footer> : ''}
        </Modal>
    );
}

export default ModalWindow;
