/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { question } from '../constants';

const initialState = {
    effect: false,
};

export function questionLoadEffect(state = initialState, action) {
    switch (action.type) {
        case question.SET_EFFECT:
            return action.payload;

        default: {
            return state;
        }
    }
}
