import { baseJsonClient } from '../../helpers/httpClient';

export function completeTenantUserSetup(
  tenantUserAcceptToken,
  userEmailVerificationToken,
  firstName,
  lastName,
  password,
) {
  return baseJsonClient.post(
    '/complete_user',
    {
      accept_token: tenantUserAcceptToken,
      email_verification_token: userEmailVerificationToken,
      first_name: firstName,
      last_name: lastName,
      password,
    },
  );
}
