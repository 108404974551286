/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { BaseRoutes } from '../../constants';

function BackToDashboard() {
    return (
        <header className="top-nav">
            <Link to={BaseRoutes.OVERVIEW}>
                <svg
                    style={{ verticalAlign: '-6px' }}
                    id="icon_navigation_chevron_left_24px"
                    data-name="icon/navigation/chevron_left_24px"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <rect id="Boundary" width="24" height="24" fill="none" />
                    <path
                        id="_Color"
                        data-name=" ↳Color"
                        d="M7.4,1.41,5.992,0,0,6l5.992,6L7.4,10.59,2.826,6Z"
                        transform="translate(8 6)"
                    />
                </svg>
                &nbsp;
                <Trans i18nKey="go_to_overview" />
            </Link>
        </header>
    );
}

export { BackToDashboard };
