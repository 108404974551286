/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';
import { Button } from '../Form/Button/Button';
import { setItemToBuy } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle } from '../Icons';
import Fade from 'react-reveal/Fade';

export function Item(props) {
    const { itemId, image, title, description, price, currency, imageNoBottom = false, isPurchased = false } = props;
    const dispatch = useDispatch();
    const spendableCoins = useSelector((state) => state.spendableCoins.coins);
    const cleanDescription = description.replace(/<(?!br\s*\/?>)[^>]+>/g, '');

    const handleButtonClick = () => {
        dispatch(setItemToBuy(itemId));
    };

    return (
        <>
            <div className="card shop__card card__purchasable-item">
                <div className="card__content">
                    <div className="item-info">
                        <div className={`item__image ${imageNoBottom ? 'item__image-no-bottom' : ''}`}>
                            <img src={image} alt="Example image" />
                        </div>
                        <div className="item__description">
                            <span className="card__title title">{title}</span>
                            <p className="description" dangerouslySetInnerHTML={{ __html: cleanDescription }} />
                        </div>
                    </div>
                    <div className={`purchase-info ${isPurchased ? 'purchased_done' : ''} `}>
                        {isPurchased ? (
                            <Fade bottom>
                                <CheckCircle />
                            </Fade>
                        ) : (
                            <Fade top>
                                <div className="price-info">
                                    <span className="shop__price">{price}</span>
                                    <span className="shop__currency">{currency}</span>
                                </div>
                                <Button
                                    size="md"
                                    variant="btn-primary btn-block"
                                    handleClick={handleButtonClick}
                                    disabled={spendableCoins < price}
                                >
                                    BUY
                                </Button>
                            </Fade>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
