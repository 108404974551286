/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React from 'react';
import { BaseRoutes, roomStatus } from '../../../constants';
import { LinkWrapper } from '../../../wrappers/LinkWrapper';
import RoomStatus from './Status';

const ColorCard = ({ room, idx, completedRate, color, link }) => (
    <div className="col-md-6 col-lg-4 col-xl-4 col--card-col" key={idx}>
        <LinkWrapper
            condition={room.status !== roomStatus.ROOM_AWAITING_APPROVAL}
            wrapper={(children) => (
                <Link className="card__link" key={`link-${idx}`} to={link}>
                    {children}
                </Link>
            )}
        >
            <div
                className={`card card--learning ${room.status === roomStatus.ROOM_AWAITING_APPROVAL && 'card--upcoming'}`}
            >
                <div className="card__img-wrp wh-image" style={{ backgroundColor: color || color }}>
                    <div className="card__name">{room.title}</div>
                </div>
                <div className="card__body">
                    <RoomStatus room={room} completedRate={completedRate} />
                </div>
            </div>
        </LinkWrapper>
    </div>
);

export default ColorCard;

ColorCard.propTypes = {
    room: PropTypes.object.isRequired,
    idx: PropTypes.number.isRequired,
    completedRate: PropTypes.number.isRequired,
    color: PropTypes.string,
};

ColorCard.defaultProps = {
    color: null,
};
