/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React from 'react';

export const DoneIcon = (props) => {
    const { style = 'level-select__level-status level-select__level-status--done' } = props;
    return (
        <span className={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26.828 19.961">
                <path
                    d="M-1.758,10.163,5.53,17.548,22.242,0"
                    transform="translate(3.172 1.414)"
                    fill="none"
                    stroke="rgb(var(--primaryRGB))"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </svg>
        </span>
    );
};
