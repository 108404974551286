import { useSelector } from 'react-redux';

export function useHasPermission(permission) {
  const auth = useSelector((state) => state.authentication);

  if (auth.user) {
    const { permissions } = auth.user;
    return permissions.includes(permission);
  }

  return false;
}
