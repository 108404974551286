/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Trans, useTranslation } from 'react-i18next';
import { Header } from '../../components/Header';
import { TeacherMessage } from '../../components/Room/Teacher/Message/Message';
import { roomService } from '../../services/room';
import { ProfileCard } from '../../components/ProfileCard';
import { startLoading, stopLoading } from '../../actions';
import ImageCard from '../../components/Room/ImageCard/ImageCard';
import ColorCard from '../../components/Room/ColorCard/ColorCard';
import CookieNotice from '../CookieNotice/CookieNotice';
import { useHasPermission } from '../../hooks/useHasPermission';
import { UserPermissions } from '../../constants/user';
import { BaseRoutes } from '../../constants';

function Overview() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [rooms, setRooms] = useState([]);
    const [holdCoinsNewValueSet, setHoldCoinsNewValueSet] = useState(true);
    const [loading, setLoading] = useState(true);
    const [roomsLoaded, setRoomsLoaded] = useState(false);
    const hasPermissionToPlay = useHasPermission(UserPermissions.PLAY_GAME_ROOMS);

    useEffect(() => {
        dispatch(startLoading());

        async function fetchData() {
            await roomService
                .getRooms()
                .then((rooms) => {
                    setRooms(rooms);
                    setHoldCoinsNewValueSet(false);
                })
                .catch(() => {
                    setRooms([]);
                });
            await setRoomsLoaded(true);
        }

        return fetchData();
    }, []);

    useEffect(() => {
        if (roomsLoaded) {
            dispatch(stopLoading());
            setLoading(false);
        }
    }, [roomsLoaded]);

    return (
        <>
            <Header />
            <section className="body overview-body">
                <div className="container h-100">
                    <div className="row overview__wrapper">
                        <div className="col-9 col-lg-9 col-md-12 overview__rooms">
                            <TeacherMessage message=<Trans i18nKey="welcome" /> top />

                            <Fade bottom when={!loading} duration={800} distance="30px">
                                <div className="row row--card-row">
                                    {rooms &&
                                        rooms.map((room, idx) => {
                                            const completedRate = Math.round(
                                                (room.completedCount / room.levelCount) * 100,
                                            );

                                            let roomLink = '';

                                            if (hasPermissionToPlay) {
                                                roomLink = `${BaseRoutes.ROOMS}/${room.roomId}${BaseRoutes.LEVELS}`;
                                            } else {
                                                roomLink = `${BaseRoutes.PREVIEW}${BaseRoutes.ROOMS}/${room.roomId}${BaseRoutes.LEVELS}`;
                                            }

                                            return room.room_icon ? (
                                                <ImageCard
                                                    link={roomLink}
                                                    room={room}
                                                    completedRate={completedRate}
                                                    idx={idx}
                                                    key={idx}
                                                    alt={t('room_image')}
                                                />
                                            ) : (
                                                <ColorCard
                                                    link={roomLink}
                                                    room={room}
                                                    completedRate={completedRate}
                                                    idx={idx}
                                                    key={idx}
                                                />
                                            );
                                        })}
                                </div>
                            </Fade>
                        </div>
                        <div className="col-3 col-lg-3 col-md-12 overview__profile">
                            {!loading && <ProfileCard holdCoinsNewValueSet={holdCoinsNewValueSet} />}
                        </div>
                    </div>
                </div>
                <CookieNotice />
            </section>
        </>
    );
}

export { Overview };
